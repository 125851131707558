import React from 'react'
import { HashRouter as Router,Route,Routes } from 'react-router-dom'
import CBM from '../components/cbm'
import Operations from '../components/operations'
import Sidebar from '../components/sidebar'
import PartMaster from '../components/partmaster'
// import SignInSide from '../components/Login'
import Login from '../components/Login'
import PathrediOpe from '../components/pathrediope'
import GhaziabadOpe from '../components/ghaziabadope'
import UATRawData from '../components/UATRawData'
import UATReport from '../components/UATReport'
import UATTesting from '../components/UATTesting'
import FacilityManagement from '../components/facilitymanagement'
import Rawghaziabad from '../components/Rawghaziabad'
import Rawpathrediope from '../components/Rawpathrediope copy'
import MachineMaster from '../components/machinemaster'
import LineMaster from '../components/linemaster'
import PlantMaster from '../components/plantmaster'
import FrdcMaster from '../components/frdcmaster'
import Temp from '../components/temp'
import MachineDownTime from '../components/machinedowntime'
import QualityRejection from '../components/qualityrejection'
import ShiftMaster from '../components/shiftmaster'
import GhaziabadOpe2 from '../components/ghaziabadope2'
import PathrediOpe2 from '../components/pathrediope2'
import MachineInput from '../components/machineinput'
import ChangeOverMaster from '../components/changeovermaster'
import FacilitySustainability from '../components/facilitysustainability'
import HourlyBucketM1 from '../components/hourlybucket_m1'
import HourlyBucketM2 from '../components/hourlybucket_m2'
import IndiaMap from '../components/map'
import EnergyManagement from '../components/energymanagement'
import BasicGauges from '../components/chartssize'
import AirQualityManagement from '../components/airqualitymanagement'
import DieselGenset from '../components/dieselgenset'
import STPManagement from '../components/stpmanagement'
import EnlargedChartPage from '../components/fullviewchart'
import WaterManagement from '../components/watermanagement'
import JSPLEManagement from '../components/jsplenergymanagement'
import JSPLVibration from '../components/jsplvibrations'
import SmartDashboard from '../components/smartdashboard'
export default function MyRoutes() {
  return (
        <Routes>
            <Route path='/cbm' element={<CBM/>}/>
            <Route path='/operations' element={<Operations/>}/>
            <Route path='/sidebar' element={<Sidebar/>}/>
            {/* <Route path='/partmaster' element={<PartMaster/>}/> */}
            <Route path='/login' element={<Login/>}/>
            <Route path='' element={<Login/>}/>
            <Route path='/pathredi_ope' element={<PathrediOpe/>}/>
            <Route path='/smartdashboard' element={<SmartDashboard/>}/>

            <Route path='/uat/uat_rawdata' element={<UATRawData/>}/>
            <Route path='/uat/uat_reports' element={<UATReport/>}/>
            <Route path='/uat/uat_testing' element={<UATTesting/>}/>
            <Route path='/raw_data' element={<Rawpathrediope/>}/>
            <Route path='/hour_bucket_m1' element={<HourlyBucketM1/>}/>
            <Route path='/hour_bucket_m2' element={<HourlyBucketM2/>}/>
            <Route path='/raw_data/uat_ghaziabad' element={<Rawghaziabad/>}/>
            <Route path='/ghaziabad_ope' element={<GhaziabadOpe/>}/>
            <Route path='/facilitymanagement' element={<FacilityManagement/>}/>
            <Route path='/plant_architecture/line_master' element={<LineMaster/>}/>
            <Route path="/plant_architecture/plant_master" element={<PlantMaster/>}/>
            {/* <Route path="/plant_architecture/frdc_master" element={<FrdcMaster/>}/> */}
            <Route path="/plant_architecture/part_master" element={<PartMaster/>}/>  
            <Route path="/production_plan/machine_downtime" element={<MachineDownTime/>}/>  
            <Route path="/production_plan/quality_rejection" element={<QualityRejection/>}/>  
            <Route path="/production_plan/shift_master" element={<ShiftMaster/>}/>  
            <Route path="/method2/machine_input" element={<MachineInput/>}/>  
            <Route path="/method2/ghaziabad_ope_2" element={<GhaziabadOpe2/>}/>  
            <Route path="/method2/pathredi_ope_2" element={<PathrediOpe2/>}/>  
            <Route path="/method1/pathredi_ope_1" element={<PathrediOpe/>}/>  
            <Route path="/method1/ghaziabad_ope_1" element={<GhaziabadOpe/>}/>  
            {/* <Route path="/plant_architecture/frdc_master" element={<ChangeOverMaster/>}/>   */}
            <Route path="/plant_architecture/frdc_master" element={<ChangeOverMaster/>}/>  
          
            <Route path="/facilitysustainability" element={<FacilitySustainability/>}/>
            <Route path="/energymanagement" element={<EnergyManagement/>}/>
            <Route path="/airquality" element={<AirQualityManagement/>}/>
            <Route path="/dieselgenset" element={<DieselGenset/>}/>
            <Route path="/temp" element={<Temp/>}/>
            <Route path="/basic" element={<BasicGauges/>}/>
            <Route path="/map" element={<IndiaMap/>}/>

            <Route path="/stpmanagement" element={<STPManagement/>}/>
            <Route path="/fullviewchart" element={<EnlargedChartPage/>}/>
            <Route path="/watermanagement" element={<WaterManagement/>}/>
            <Route path="/jsplemanagement" element={<JSPLEManagement/>}/>
            <Route path="/jsplvibration" element={<JSPLVibration/>}/>
           <Route path='/plant_architecture/machine_master' element={<MachineMaster/>}/>
        </Routes>
  )
}