import axios from "axios";
import { baseUrl } from "./baseUrl";

export const apiAddPart = async (data) => {
  console.log(data);
  const url = baseUrl + "/part/addPart";
  try {
    const token = localStorage.getItem("token");

    const datas = await axios.post(
      url,
      {
        plantNo: data.plantNo,
        partNo: data.partNo,
        lineNo: data.lineNo,
        machineNo: data.machineNo,
        partName: data.partName,
        cycleTime: data.cycleTime,
        multipleFactor: data.multipleFactor,
        ctReduction: data.ctReduction,
        lowerBound: data.lowerBound,
        upperBound: data.upperBound,
        plantProduction: 0,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return datas;
  } catch (error) {
    console.error("Error during adding part:", error);
    throw error;
  }
};
