import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  TextField,
  IconButton,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  tableCellClasses,
  styled,
  TablePagination,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import "../assets/css/table.css";
import "../assets/css/style.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { apiAddPart } from "../api/api.addpart";
import { apiGetPart } from "../api/api.getpart";
import { apigetLines } from "../api/api.getline";
import { apiGetPlant } from "../api/api.getplant";
import { apigetMachine } from "../api/apigetmachine";
import { apiUpdatePart } from "../api/api.updatepart";
import { apiDeletePart } from "../api/api.deletepart";
import DeleteConfirmationModal from "./deletemodal";
import { Skeleton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const PartMaster = () => {
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [addOpen, setAddOpen] = useState(false);
  const [newRowData, setNewRowData] = useState({});
  const [lineName, setLineName] = useState("");
  const [partName, setPartName] = useState("");
  const [plantName, setPlantName] = useState("");
  const [partNo, setPartNo] = useState("");
  const [plantProduction, setPlantProduction] = useState("");
  const [cycleTime, setcycleTime] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [multipleFactor, setMultipleFactor] = useState("");

  const [machineName, setMachineName] = useState("");
  const [ctReduction, setCtReduction] = useState("");
  const [upperBound, setUpperBound] = useState("");
  const [lowerBound, setLowerBound] = useState("");
  const [severity, setSeverity] = useState("success");
  const [refreshData, setRefreshData] = useState(false);
  const [lineData, setLineData] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [plantData, setPlantData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [error, setError] = useState(null);
  const [partData, setPartData] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletePartId, setDeleteParttId] = useState(null);
  const [selectedPlant, setSelectedPlant] = useState("");
  const [selectedLine, setSelectedLine] = useState("");

  const [updatedPartData, setUpdatedPartData] = useState({
    partNo: "",
    plantNo: "",
    lineNo: "",
    machineNo: "",
    partName: "",
    cycleTime: "",
    plantProduction: "",
    multipleFactor: "",
    ctReduction: "",
    lowerBound: "",
    upperBound: "",
  });

  useEffect(() => {
    const getParts = async () => {
      try {
        const result = await apiGetPart();
        console.log(result.data.data);
        setPartData(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getParts();
  }, [refreshData]);

  useEffect(() => {
    const getPlant = async () => {
      try {
        const result = await apiGetPlant();
        console.log("Result data plant:", result.data.data);
        setPlantData(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getPlant();
  }, [refreshData]);

  useEffect(() => {
    const getline = async () => {
      try {
        const result = await apigetLines();
        console.log("Result data line:", result.data.data); // Log the data property
        setLineData(result.data.data); // Set plantData to result.data
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getline();
  }, [refreshData]);

  useEffect(() => {
    const getmachine = async () => {
      try {
        const result = await apigetMachine();
        console.log("Result data machine:", result.data.data); // Log the data property
        setMachineData(result.data.data); // Set plantData to result.data
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getmachine();
  }, [refreshData]);

  const getParts = async () => {
    try {
      const result = await apiGetPart();
      console.log(result?.data.data);
      setPartData(result?.data.data);
    } catch (error) {
      setError(error.message);
      handleSnackbarOpen(error.message, "error");
    }
  };


  const handleEditSubmit = (row) => {
    console.log("editt data", row);
    setUpdatedPartData(row);
    setOpen(true);
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const parsedValue =
      name === "cycleTime" ||
      name === "multipleFactor" ||
      name === "ctReduction"
        ? parseFloat(value) || 0 // Parse value to float and default to 0 if NaN
        : value;

    setUpdatedPartData((prevData) => ({
      ...prevData,
      [name]: parsedValue,
    }));

    // Recalculate and update computed fields (upperBound, lowerBound)
    if (
      name === "cycleTime" ||
      name === "multipleFactor" ||
      name === "ctReduction"
    ) {
      const { cycleTime, multipleFactor, ctReduction } = {
        ...updatedPartData,
        [name]: parsedValue,
      };

      const parsedCycleTime = parseFloat(cycleTime) || 0;
      const parsedMultipleFactor = parseFloat(multipleFactor) || 0;
      const parsedCtReduction = parseFloat(ctReduction) || 0;

      if (
        !isNaN(parsedCycleTime) &&
        !isNaN(parsedMultipleFactor) &&
        !isNaN(parsedCtReduction)
      ) {
        const upperBound = (parsedCycleTime * parsedMultipleFactor).toFixed(2);
        const lowerBound = (
          parsedCycleTime -
          (parsedCycleTime * parsedCtReduction) / 100
        ).toFixed(2);

        setUpdatedPartData((prevData) => ({
          ...prevData,
          upperBound,
          lowerBound,
        }));
      }
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const { upperBound, lowerBound, cycleTime, ...data } = updatedPartData; // Include cycleTime here
      const payload = {
        ...data,
        cycleTime: cycleTime.toString(), // Convert cycleTime to a string
        upperBound: Math.round(parseFloat(upperBound)),
        lowerBound: Math.round(parseFloat(lowerBound)),
      };

      // Perform update part API call with payload
      const result = await apiUpdatePart(payload);
      await getParts(); // Assuming getParts() is a function to fetch updated parts data
      console.log("Part updated successfully:", result.data);
      handleSnackbarOpen("Part updated successfully!", "success");
      setRefreshData((prev) => !prev);
      setOpen(false);
    } catch (error) {
      setOpen(false);
      handleSnackbarOpen("Error updating part. Please try again.", "error");
      console.error("Error updating part:", error);
    }
  };

  const handleAddSubmit = async () => {
    try {
      const { cycleTime, multipleFactor, ctReduction, ...data } =
        updatedPartData;
      const parsedCycleTime = parseFloat(cycleTime);
      const parsedMultipleFactor = parseFloat(multipleFactor);
      const parsedCtReduction = parseFloat(ctReduction);

      const upperBound = Math.round(parsedCycleTime * parsedMultipleFactor);
      const lowerBound = Math.round(
        parsedCycleTime - (parsedCycleTime * parsedCtReduction) / 100
      );

      const payload = {
        ...data,
        cycleTime: cycleTime.toString(),
        multipleFactor: parsedMultipleFactor,
        ctReduction: parsedCtReduction,
        upperBound: upperBound,
        lowerBound: lowerBound,
      };

      // Perform add part API call with payload
      const result = await apiAddPart(payload);
      console.log("Part added successfully:", result.data);
      setAddOpen(false);
      console.log("response", result.data);
      setRefreshData((prev) => !prev);
    } catch (error) {
      console.error("Error adding part:", error);
    }
  };
  const filteredLines = lineData.filter(
    (line) => line.plantNo === selectedPlant
  );

  // Filter machineData based on selected line
  const filteredMachines = machineData.filter(
    (machine) => machine.lineNo === selectedLine
  );

  const handleDeleteClick = (row) => {
    // console.log(row,"check handlew deleter")
    setDeleteParttId(row.partId); // Store the id of the plant being deleted
    setDeleteModalOpen(true); // Open the delete confirmation modal
  };
  const handleConfirmDelete = async () => {
    try {
      await apiDeletePart(deletePartId); // Perform delete operation
      handleSnackbarOpen("Part Deleted successfully!", "success");
      setRefreshData((prev) => !prev);
    } catch (error) {
      console.error("Error deleting Part:", error);
      handleSnackbarOpen("Error deleting Part. Please try again.", "error");
    } finally {
      setDeleteModalOpen(false); // Close the delete confirmation modal
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleModalClose = () => {
    // Reset the form data
    setUpdatedPartData({
      partNo: "",
      plantNo: "",
      lineNo: "",
      machineNo: "",
      partName: "",
      cycleTime: "",
      plantProduction: "",
      multipleFactor: "",
      ctReduction: "",
      lowerBound: "",
      upperBound: "",
    });
    
    
    setAddOpen(false); 
    setOpen(false)
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, partData.length - page * rowsPerPage);

  return (
    <div style={{ padding: "0px 20px" }}>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
      >
        <h2>Part Master</h2>
        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          <Button
            onClick={() => setAddOpen(true)}
            style={{
              fontWeight: "500",
              borderRadius: "4px",
              color: "gray",
              border: "2px solid gray",
              padding: "5px",
              marginBottom: "5px",
            }}
          >
            {" "}
            Add New &nbsp;{" "}
            <FontAwesomeIcon
              style={{ fontSize: "18px", color: "gray" }}
              icon={faPlus}
            />
          </Button>
        </div>
      </div>
      <Box>
        <TableContainer component={Paper}>
          <Table
            size="small"
            style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell className="table-cell">
                  Plant Name
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Line Name
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Machine Name
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Plant Production
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Part Name
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Cycle Time
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  CT Reduction
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Multiple Factor
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Lower Bound
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Upper Bound
                </StyledTableCell>
                <StyledTableCell className="table-cell">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {partData.length === 0
                ? // Render skeleton loaders when data is still loading
                  Array.from(Array(5).keys()).map((index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        <Skeleton animation="wave" />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                : partData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell className="table-cell">
                          {row.plantName}
                        </StyledTableCell>
                        <StyledTableCell className="table-cell">
                          {row.lineName}
                        </StyledTableCell>
                        <StyledTableCell className="table-cell">
                          {row.machineName}
                        </StyledTableCell>
                        <StyledTableCell className="table-cell">
                          {row.plantProduction}
                        </StyledTableCell>
                        <StyledTableCell className="table-cell">
                          {row.partName}
                        </StyledTableCell>
                        <StyledTableCell className="table-cell">
                          {row.cycleTime}
                        </StyledTableCell>
                        <StyledTableCell className="table-cell">
                          {row.ctReduction}
                        </StyledTableCell>
                        <StyledTableCell className="table-cell">
                          {row.multipleFactor}
                        </StyledTableCell>
                        <StyledTableCell className="table-cell">
                          {row.lowerBound}
                        </StyledTableCell>
                        <StyledTableCell className="table-cell">
                          {row.upperBound}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                          className="table-cell"
                        >
                          <IconButton onClick={() => handleEditSubmit(row)}>
                            <EditIcon />
                          </IconButton>
                          <div
                            className="divider"
                            style={{
                              height: "20px",
                              width: "2px",
                              backgroundColor: "#0003",
                            }}
                          ></div>

                          <IconButton
                            style={{ color: "#FF3131" }}
                            onClick={() => handleDeleteClick(row)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 53 }}>
                  <StyledTableCell colSpan={8} style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "-400px",
                        transform: "translateY(-50%)",
                      }}
                    >
                      {`No further data available`}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={partData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {/* Modal for editing */}
        <DeleteConfirmationModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleConfirmDelete}
        />
        <Modal open={open} onClose={handleModalClose}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              minWidth: "500px",
              borderRadius: "10px",
            }}
          >
            <button
              onClick={handleModalClose}
              style={{
                borderRadius: "10px",

                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "30px",
              }}
            >
              &times;
            </button>
            <h2>Update Part</h2>
            <hr />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Organization</InputLabel>
                <Select name="organization" defaultValue={1}>
                  <MenuItem value={1}>Digineous</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Plant Name</InputLabel>
                <Select
                  name="plantNo"
                  value={updatedPartData?.plantNo}
                  onChange={(e) => {
                    setSelectedPlant(e.target.value);
                    handleInputChange(e); // Update updatedPartData
                  }}
                >
                  {plantData.map((plant, index) => (
                    <MenuItem key={plant.plantNo} value={plant?.plantNo}>
                      {plant?.plantName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Line Name</InputLabel>
                <Select
                  name="lineNo"
                  value={updatedPartData?.lineNo}
                  onChange={(e) => {
                    setSelectedLine(e.target.value);
                    handleInputChange(e); // Update updatedPartData
                  }}
                >
                  {filteredLines.map((line) => (
                    <MenuItem key={line.id} value={line.lineNo}>
                      {line.lineName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Machine Name</InputLabel>
                <Select
                  name="machineNo"
                  value={updatedPartData?.machineNo}
                  onChange={handleInputChange}
                >
                  {filteredMachines.map((id) => (
                    <MenuItem key={id.id} value={id.machineNo}>
                      {id.displayMachineName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <TextField
                name="partName"
                label="Part Name"
                value={updatedPartData?.partName}
                onChange={handleInputChange}
                style={{ marginRight: "10px" }}
              />
              <TextField
                name="cycleTime"
                label="Standard Cycle Time"
                value={updatedPartData?.cycleTime}
                onChange={handleInputChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <TextField
                name="partNo"
                label="Part No"
                value={updatedPartData?.partNo}
                onChange={handleInputChange}
                style={{ marginRight: "10px" }}
              />
              <TextField
                name={"multipleFactor"}
                label="Multiple Factor"
                value={updatedPartData?.multipleFactor}
                onChange={handleInputChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <TextField
                name={"ctReduction"}
                label="Reduction (%)"
                value={updatedPartData?.ctReduction}
                style={{ marginRight: "10px" }}
                onChange={handleInputChange}
              />
              {/* <TextField
                name={"plantProduction"}
                label="Plant Production"
                value={updatedPartData?.plantProduction}
                onChange={handleInputChange}
              /> */}
            </div>

            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <TextField
              disabled
                label="Lower Bound"
                name="lowerBound"
                value={updatedPartData?.lowerBound}
                style={{ marginRight: "10px" }}
                onChange={handleInputChange}
              />
              <TextField
              disabled
                name="upperBound"
                label="Upper Bound"
                value={updatedPartData?.upperBound}
                onChange={handleInputChange}
              />
            </div> */}
            <Button
              onClick={handleUpdateSubmit}
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              Update
            </Button>
          </div>
        </Modal>
        <Modal open={addOpen} onClose={handleModalClose}>
          <div
            style={{
              borderRadius: "10px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              minWidth: "500px",
            }}
          >
            <button
              onClick={handleModalClose}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "30px",
              }}
            >
              &times;
            </button>
            <h2>Add New Part </h2>
            <hr />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Organization</InputLabel>
                <Select name="organization" defaultValue={1}>
                  <MenuItem value={1}>Digineous</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Plant Name</InputLabel>
                <Select
                  name="plantNo"
                  value={updatedPartData?.plantNo}
                  onChange={(e) => {
                    setSelectedPlant(e.target.value);
                    handleInputChange(e); // Update updatedPartData
                  }}
                >
                  {plantData.map((plant, index) => (
                    <MenuItem key={plant.plantNo} value={plant?.plantNo}>
                      {plant?.plantName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Line Name</InputLabel>
                <Select
                  name="lineNo"
                  value={updatedPartData?.lineNo}
                  onChange={(e) => {
                    setSelectedLine(e.target.value);
                    handleInputChange(e); // Update updatedPartData
                  }}
                >
                  {filteredLines.map((line) => (
                    <MenuItem key={line.id} value={line.lineNo}>
                      {line.lineName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Machine Name</InputLabel>
                <Select
                  name="machineNo"
                  value={updatedPartData?.machineNo}
                  onChange={handleInputChange}
                >
                  {filteredMachines.map((id) => (
                    <MenuItem key={id.id} value={id.machineNo}>
                      {id.displayMachineName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <TextField
                name="partName"
                label="Part Name"
                value={updatedPartData?.partName}
                onChange={handleInputChange}
                style={{ marginRight: "10px" }}
              />
              <TextField
                name="cycleTime"
                label="Standard Cycle Time"
                value={updatedPartData?.cycleTime}
                onChange={handleInputChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <TextField
                name="partNo"
                label="Part No"
                value={updatedPartData?.partNo}
                onChange={handleInputChange}
                style={{ marginRight: "10px" }}
              />
              <TextField
                name={"multipleFactor"}
                label="Multiple Factor"
                value={updatedPartData?.multipleFactor}
                onChange={handleInputChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <TextField
                name={"ctReduction"}
                label="Reduction (%)"
                value={updatedPartData?.ctReduction}
                style={{ marginRight: "10px" }}
                onChange={handleInputChange}
              />
              {/* <TextField
                name={"plantProduction"}
                label="Plant Production"
                value={updatedPartData?.plantProduction}
                onChange={handleInputChange}
              /> */}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              {/* <TextField
                label="Lower Bound"
                name="lowerBound"
                value={updatedPartData?.lowerBound}
                style={{ marginRight: "10px" }}
                onChange={handleInputChange}
              />
              <TextField
                name="upperBound"
                label="Upper Bound"
                value={updatedPartData?.upperBound}
                onChange={handleInputChange}
              /> */}
            </div>

            <Button
              onClick={handleAddSubmit}
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              Add
            </Button>
          </div>
        </Modal>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default PartMaster;

// import React, { useEffect, useState } from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Button,
//   Modal,
//   TextField,
//   IconButton,
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   tableCellClasses,
//   styled,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
// import "../assets/css/table.css";
// import "../assets/css/style.css";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
// import { apiAddPart } from "../api/api.addpart";
// import { apiGetPart } from "../api/api.getpart";
// import { apigetLines } from "../api/api.getline";
// import { apiGetPlant } from "../api/api.getplant";
// import { apigetMachine } from "../api/apigetmachine";
// import { apiUpdatePart } from "../api/api.updatepart";
// import { apiDeletePart } from "../api/api.deletepart";
// import DeleteConfirmationModal from "./deletemodal";
// import { Skeleton } from "@mui/material";
// import CircularProgress from "@mui/material/CircularProgress";

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: "#1FAEC5",
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));

// const PartMaster = () => {
//   const [open, setOpen] = useState(false);
//   const [rowData, setRowData] = useState({});
//   const [searchTerm, setSearchTerm] = useState("");
//   const [addOpen, setAddOpen] = useState(false);
//   const [newRowData, setNewRowData] = useState({});
//   const [lineName, setLineName] = useState("");
//   const [partName, setPartName] = useState("");
//   const [plantName, setPlantName] = useState("");
//   const [partNo, setPartNo] = useState("");
//   const [plantProduction, setPlantProduction] = useState("");
//   const [cycleTime, setcycleTime] = useState("");
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");

//   const [multipleFactor, setMultipleFactor] = useState("");

//   const [machineName, setMachineName] = useState("");
//   const [ctReduction, setCtReduction] = useState("");
//   const [upperBound, setUpperBound] = useState("");
//   const [lowerBound, setLowerBound] = useState("");
//   const [severity, setSeverity] = useState("success");
//   const [refreshData, setRefreshData] = useState(false);
//   const [lineData, setLineData] = useState([]);
//   const [machineData, setMachineData] = useState([]);
//   const [plantData, setPlantData] = useState([]);

//   const [error, setError] = useState(null);
//   const [partData, setPartData] = useState([]);
//   const [deleteModalOpen, setDeleteModalOpen] = useState(false);
//   const [deletePartId, setDeleteParttId] = useState(null);
//   const [updatedPartData, setUpdatedPartData] = useState({
//     partNo: "",
//     plantNo: "",
//     lineNo: "",
//     machineNo: "",
//     partName: "",
//     cycleTime: "",
//     plantProduction: "",
//     multipleFactor: "",
//     ctReduction: "",
//     lowerBound: "",
//     upperBound: "",
//   });

//   useEffect(() => {
//     const getParts = async () => {
//       try {
//         const result = await apiGetPart();
//         console.log(result.data.data);
//         setPartData(result.data.data);
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getParts();
//   }, [refreshData]);

//   useEffect(() => {
//     const getPlant = async () => {
//       try {
//         const result = await apiGetPlant();
//         console.log("Result data plant:", result.data.data);
//         setPlantData(result.data.data);
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getPlant();
//   }, [refreshData]);

//   useEffect(() => {
//     const getline = async () => {
//       try {
//         const result = await apigetLines();
//         console.log("Result data line:", result.data.data); // Log the data property
//         setLineData(result.data.data); // Set plantData to result.data
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getline();
//   }, [refreshData]);

//   useEffect(() => {
//     const getmachine = async () => {
//       try {
//         const result = await apigetMachine();
//         console.log("Result data machine:", result.data.data); // Log the data property
//         setMachineData(result.data.data); // Set plantData to result.data
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getmachine();
//   }, [refreshData]);

//   const getParts = async () => {
//     try {
//       const result = await apiGetPart();
//       console.log(result?.data.data);
//       setPartData(result?.data.data);
//     } catch (error) {
//       setError(error.message);
//       handleSnackbarOpen(error.message, "error");
//     }
//   };

//   const handleEditClick = (row) => {
//     setRowData(row);
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleEditSubmit = (row) => {

//     console.log("editt data", row);
//     setUpdatedPartData(row);
//     setOpen(true);
//   };

//   const handleSnackbarOpen = (message, severity) => {
//     setSnackbarMessage(message);
//     setSeverity(severity);
//     setOpenSnackbar(true);
//   };

//   const handleInputChange = (e) => {
//   const { name, value } = e.target;

//   // Parse value to float if it's a numeric field
//   const parsedValue = name === 'cycleTime' || name === 'multipleFactor' || name === 'ctReduction'
//     ? parseFloat(value) || 0 // Parse value to float and default to 0 if NaN
//     : value;

//   setUpdatedPartData((prevData) => ({
//     ...prevData,
//     [name]: parsedValue,
//   }));

//   // Recalculate and update computed fields (upperBound, lowerBound)
//   if (name === "cycleTime" || name === "multipleFactor" || name === "ctReduction") {
//     const { cycleTime, multipleFactor, ctReduction } = {
//       ...updatedPartData,
//       [name]: parsedValue,
//     };

//     const parsedCycleTime = parseFloat(cycleTime) || 0;
//     const parsedMultipleFactor = parseFloat(multipleFactor) || 0;
//     const parsedCtReduction = parseFloat(ctReduction) || 0;

//     if (!isNaN(parsedCycleTime) && !isNaN(parsedMultipleFactor) && !isNaN(parsedCtReduction)) {
//       const upperBound = (parsedCycleTime * parsedMultipleFactor).toFixed(2);
//       const lowerBound = (parsedCycleTime - (parsedCycleTime * parsedCtReduction) / 100).toFixed(2);

//       setUpdatedPartData((prevData) => ({
//         ...prevData,
//         upperBound,
//         lowerBound,
//       }));
//     }
//   }
// };

// const handleUpdateSubmit = async () => {
//   try {
//     const { upperBound, lowerBound, cycleTime, ...data } = updatedPartData; // Include cycleTime here
//     const payload = {
//       ...data,
//       cycleTime: cycleTime.toString(), // Convert cycleTime to a string
//       upperBound: Math.round(parseFloat(upperBound)),
//       lowerBound: Math.round(parseFloat(lowerBound)),
//     };

//     // Perform update part API call with payload
//     const result = await apiUpdatePart(payload);
//     await getParts(); // Assuming getParts() is a function to fetch updated parts data
//     console.log("Part updated successfully:", result.data);
//     handleSnackbarOpen("Part updated successfully!", "success");
//     setRefreshData((prev) => !prev);
//     setOpen(false);
//   } catch (error) {
//     setOpen(false);
//     handleSnackbarOpen("Error updating part. Please try again.", "error");
//     console.error("Error updating part:", error);
//   }
// };

//   const handleAddSubmit = async () => {
//     try {
//       const { cycleTime, multipleFactor, ctReduction, ...data } = updatedPartData;
//       const parsedCycleTime = parseFloat(cycleTime);
//       const parsedMultipleFactor = parseFloat(multipleFactor);
//       const parsedCtReduction = parseFloat(ctReduction);

//       const upperBound = Math.round(parsedCycleTime * parsedMultipleFactor);
//       const lowerBound = Math.round(parsedCycleTime - (parsedCycleTime * parsedCtReduction) / 100);

//       const payload = {
//         ...data,
//         cycleTime: cycleTime.toString(),
//         multipleFactor: parsedMultipleFactor,
//         ctReduction: parsedCtReduction,
//         upperBound: upperBound,
//         lowerBound: lowerBound,
//       };

//       // Perform add part API call with payload
//       const result = await apiAddPart(payload);
//       console.log("Part added successfully:", result.data);
//       setAddOpen(false);
//           console.log("response", result.data);
//           setRefreshData((prev) => !prev);
//     } catch (error) {
//       console.error("Error adding part:", error);
//     }
//   };

//   const handleDeleteClick = (row) => {
//     // console.log(row,"check handlew deleter")
//     setDeleteParttId(row.partId); // Store the id of the plant being deleted
//     setDeleteModalOpen(true); // Open the delete confirmation modal
//   };
//   const handleConfirmDelete = async () => {
//     try {
//       await apiDeletePart(deletePartId); // Perform delete operation
//       handleSnackbarOpen("Part Deleted successfully!", "success");
//       setRefreshData((prev) => !prev);
//     } catch (error) {
//       console.error("Error deleting Part:", error);
//       handleSnackbarOpen("Error deleting Part. Please try again.", "error");
//     } finally {
//       setDeleteModalOpen(false); // Close the delete confirmation modal
//     }
//   };

//   return (
//     <div style={{ padding: "0px 20px" }}>
//       <div
//         style={{
//           display: "flex",
//           width: "100%",
//           alignItems: "center",
//           justifyContent: "space-between",
//           paddingTop: "5px",
//           paddingBottom: "5px",
//         }}
//       >
//         <h2>Part Master</h2>
//         <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
//           <Button
//             onClick={() => setAddOpen(true)}
//             style={{
//               fontWeight: "500",
//               borderRadius: "4px",
//               color: "gray",
//               border: "2px solid gray",
//               padding: "5px",
//               marginBottom: "5px",
//             }}
//           >
//             {" "}
//             Add New &nbsp;{" "}
//             <FontAwesomeIcon
//               style={{ fontSize: "18px", color: "gray" }}
//               icon={faPlus}
//             />
//           </Button>
//         </div>
//       </div>
//       <Box>
//         <TableContainer component={Paper}>
//           <Table
//             size="small"
//             style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
//           >
//             <TableHead>
//               <TableRow>
//                 <StyledTableCell className="table-cell">
//                   Plant Name
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   Line Name
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   Machine Name
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   Plant Production
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   Part Name
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   Cycle Time
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   CT Reduction
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   Multiple Factor
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   Lower Bound
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   Upper Bound
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">Action</StyledTableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {partData.length === 0
//                 ? // Render skeleton loaders when data is still loading
//                   Array.from(Array(5).keys()).map((index) => (
//                     <StyledTableRow key={index}>
//                       <StyledTableCell>
//                         <Skeleton animation="wave" />
//                       </StyledTableCell>
//                       <StyledTableCell>
//                         <Skeleton animation="wave" />
//                       </StyledTableCell>
//                       <StyledTableCell>
//                         <Skeleton animation="wave" />
//                       </StyledTableCell>
//                       <StyledTableCell>
//                         <Skeleton animation="wave" />
//                       </StyledTableCell>
//                       <StyledTableCell>
//                         <Skeleton animation="wave" />
//                       </StyledTableCell>
//                       <StyledTableCell>
//                         <Skeleton animation="wave" />
//                       </StyledTableCell>
//                       <StyledTableCell>
//                         <Skeleton animation="wave" />
//                       </StyledTableCell>
//                       <StyledTableCell>
//                         <Skeleton animation="wave" />
//                       </StyledTableCell>
//                       <StyledTableCell>
//                         <Skeleton animation="wave" />
//                       </StyledTableCell>
//                       <StyledTableCell>
//                         <Skeleton animation="wave" />
//                       </StyledTableCell>
//                       <StyledTableCell>
//                         <Skeleton animation="wave" />
//                       </StyledTableCell>
//                     </StyledTableRow>
//                   ))
//                 : partData.map((row) => (
//                     <StyledTableRow key={row.id}>
//                       <StyledTableCell className="table-cell">
//                         {row.plantName}
//                       </StyledTableCell>
//                       <StyledTableCell className="table-cell">
//                         {row.lineName}
//                       </StyledTableCell>
//                       <StyledTableCell className="table-cell">
//                         {row.machineName}
//                       </StyledTableCell>
//                       <StyledTableCell className="table-cell">
//                         {row.plantProduction}
//                       </StyledTableCell>
//                       <StyledTableCell className="table-cell">
//                         {row.partName}
//                       </StyledTableCell>
//                       <StyledTableCell className="table-cell">
//                         {row.cycleTime}
//                       </StyledTableCell>
//                       <StyledTableCell className="table-cell">
//                         {row.ctReduction}
//                       </StyledTableCell>
//                       <StyledTableCell className="table-cell">
//                         {row.multipleFactor}
//                       </StyledTableCell>
//                       <StyledTableCell className="table-cell">
//                         {row.lowerBound}
//                       </StyledTableCell>
//                       <StyledTableCell className="table-cell">
//                         {row.upperBound}
//                       </StyledTableCell>
//                       <StyledTableCell
//                         style={{
//                           display: "flex",
//                           gap: "10px",
//                           alignItems: "center",
//                           justifyContent: "space-evenly",
//                         }}
//                         className="table-cell"
//                       >
//                         <IconButton onClick={() => handleEditSubmit(row)}>
//                           <EditIcon />
//                         </IconButton>
//                         <div
//                           className="divider"
//                           style={{
//                             height: "20px",
//                             width: "2px",
//                             backgroundColor: "#0003",
//                           }}
//                         ></div>

//                         <IconButton
//                           style={{ color: "#FF3131" }}
//                           onClick={() => handleDeleteClick(row)}
//                         >
//                           <DeleteIcon />
//                         </IconButton>
//                       </StyledTableCell>
//                     </StyledTableRow>
//                   ))}
//             </TableBody>
//           </Table>
//         </TableContainer>

//         {/* Modal for editing */}
//         <DeleteConfirmationModal
//           open={deleteModalOpen}
//           onClose={() => setDeleteModalOpen(false)}
//           onConfirm={handleConfirmDelete}
//         />
//         <Modal open={open} onClose={handleClose}>
//           <div
//             style={{
//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               backgroundColor: "white",
//               padding: "20px",
//               minWidth: "500px",
//               borderRadius:'10px',

//             }}
//           >
//             <button
//               onClick={handleClose}
//               style={{
//               borderRadius:'10px',

//                 position: "absolute",
//                 top: "10px",
//                 right: "10px",
//                 cursor: "pointer",
//                 backgroundColor: "transparent",
//                 border: "none",
//                 fontSize: "30px",
//               }}
//             >
//               &times;
//             </button>
//             <h2>Update Part</h2>
//             <hr />
//             <br />
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <FormControl sx={{ width: "26ch" }}>
//                 <InputLabel>Plant Name</InputLabel>
//                 <Select
//                   name="plantNo"
//                   value={updatedPartData?.plantNo}
//                   onChange={handleInputChange}
//                 >
//                   {plantData.map((plant, index) => (
//                     <MenuItem key={plant.plantNo} value={plant?.plantNo}>
//                       {plant?.plantName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>

//               <FormControl sx={{ width: "26ch" }}>
//                 <InputLabel>Line Name</InputLabel>
//                 <Select
//                   name="lineNo"
//                   value={updatedPartData?.lineNo}
//                   onChange={handleInputChange}
//                 >
//                   {lineData.map((line) => (
//                     <MenuItem key={line.id} value={line.lineNo}>
//                       {line.lineName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <FormControl sx={{ width: "26ch" }}>
//                 <InputLabel>Machine Name</InputLabel>
//                 <Select
//                   name="machineNo"
//                   value={updatedPartData?.machineNo}
//                   onChange={handleInputChange}
//                 >
//                   {machineData.map((id) => (
//                     <MenuItem key={id.id} value={id.machineNo}>
//                       {id.displayMachineName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//               <TextField
//                 name={"plantProduction"}
//                 label="Plant Production"
//                 value={updatedPartData?.plantProduction}
//                 onChange={handleInputChange}
//               />
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <TextField
//                 name="partName"
//                 label="Part Name"
//                 value={updatedPartData?.partName}
//                 onChange={handleInputChange}
//                 style={{ marginRight: "10px" }}
//               />
//               <TextField
//                 name="cycleTime"
//                 label="Standard Cycle Time"
//                 value={updatedPartData?.cycleTime}
//                 onChange={handleInputChange}
//               />
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <TextField
//                 name={"ctReduction"}
//                 label="Reduction (%)"
//                 value={updatedPartData?.ctReduction}
//                 style={{ marginRight: "10px" }}
//                 onChange={handleInputChange}
//               />
//               <TextField
//                 name={"multipleFactor"}
//                 label="Multiple Factor"
//                 value={updatedPartData?.multipleFactor}
//                 onChange={handleInputChange}
//               />
//             </div>
//             {/* <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <TextField
//               disabled
//                 label="Lower Bound"
//                 name="lowerBound"
//                 value={updatedPartData?.lowerBound}
//                 style={{ marginRight: "10px" }}
//                 onChange={handleInputChange}
//               />
//               <TextField
//               disabled
//                 name="upperBound"
//                 label="Upper Bound"
//                 value={updatedPartData?.upperBound}
//                 onChange={handleInputChange}
//               />
//             </div> */}
//             <Button
//               onClick={handleUpdateSubmit}
//               variant="contained"
//               color="primary"
//               style={{ marginTop: "20px" }}
//             >
//               Save
//             </Button>
//           </div>
//         </Modal>
//         <Modal open={addOpen} onClose={() => setAddOpen(false)}>
//           <div
//             style={{
//               borderRadius:'10px',
//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               backgroundColor: "white",
//               padding: "20px",
//               minWidth: "500px",
//             }}
//           >
//             <button
//               onClick={() => setAddOpen(false)}
//               style={{
//                 position: "absolute",
//                 top: "10px",
//                 right: "10px",
//                 cursor: "pointer",
//                 backgroundColor: "transparent",
//                 border: "none",
//                 fontSize: "30px",
//               }}
//             >
//               &times;
//             </button>
//             <h2>Add New Part </h2>
//             <hr />
//             <br />
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <FormControl sx={{ width: "26ch" }}>
//                 <InputLabel>Organization</InputLabel>
//                 <Select name="organization" defaultValue="">
//                   <MenuItem value={1}>Digineous</MenuItem>
//                 </Select>
//               </FormControl>
//               <FormControl sx={{ width: "26ch" }}>
//                 <InputLabel>Plant Name</InputLabel>
//                 <Select
//                   name="plantNo"
//                   value={updatedPartData?.plantNo}
//                   onChange={handleInputChange}
//                 >
//                   {plantData.map((plant, index) => (
//                     <MenuItem key={plant.plantNo} value={plant?.plantNo}>
//                       {plant?.plantName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <FormControl sx={{ width: "26ch" }}>
//                 <InputLabel>Line Name</InputLabel>
//                 <Select
//                   name="lineNo"
//                   value={updatedPartData?.lineNo}
//                   onChange={handleInputChange}
//                 >
//                   {lineData.map((line) => (
//                     <MenuItem key={line.id} value={line.lineNo}>
//                       {line.lineName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//               <FormControl sx={{ width: "26ch" }}>
//                 <InputLabel>Machine Name</InputLabel>
//                 <Select
//                   name="machineNo"
//                   value={updatedPartData?.machineNo}
//                   onChange={handleInputChange}
//                 >
//                   {machineData.map((id) => (
//                     <MenuItem key={id.id} value={id.machineNo}>
//                       {id.displayMachineName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <TextField
//                 name="partName"
//                 label="Part Name"
//                 value={updatedPartData?.partName}
//                 onChange={handleInputChange}
//                 style={{ marginRight: "10px" }}
//               />
//               <TextField
//                 name="cycleTime"
//                 label="Standard Cycle Time"
//                 value={updatedPartData?.cycleTime}
//                 onChange={handleInputChange}
//               />
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <TextField
//                 name="partNo"
//                 label="Part No"
//                 value={updatedPartData?.partNo}
//                 onChange={handleInputChange}
//                 style={{ marginRight: "10px" }}
//               />
//               <TextField
//                 name={"multipleFactor"}
//                 label="Multiple Factor"
//                 value={updatedPartData?.multipleFactor}
//                 onChange={handleInputChange}
//               />
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <TextField
//                 name={"ctReduction"}
//                 label="Reduction (%)"
//                 value={updatedPartData?.ctReduction}
//                 style={{ marginRight: "10px" }}
//                 onChange={handleInputChange}
//               />
//               <TextField
//                 name={"plantProduction"}
//                 label="Plant Production"
//                 value={updatedPartData?.plantProduction}
//                 onChange={handleInputChange}
//               />
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               {/* <TextField
//                 label="Lower Bound"
//                 name="lowerBound"
//                 value={updatedPartData?.lowerBound}
//                 style={{ marginRight: "10px" }}
//                 onChange={handleInputChange}
//               />
//               <TextField
//                 name="upperBound"
//                 label="Upper Bound"
//                 value={updatedPartData?.upperBound}
//                 onChange={handleInputChange}
//               /> */}
//             </div>

//             <Button
//               onClick={handleAddSubmit}
//               variant="contained"
//               color="primary"
//               style={{ marginTop: "20px" }}
//             >
//               Add
//             </Button>
//           </div>
//         </Modal>
//       </Box>
//       <Snackbar
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={() => setOpenSnackbar(false)}
//       >
//         <MuiAlert
//           onClose={() => setOpenSnackbar(false)}
//           severity={severity}
//           sx={{ width: "100%" }}
//         >
//           {snackbarMessage}
//         </MuiAlert>
//       </Snackbar>
//     </div>
//   );
// };

// export default PartMaster;

// import React, { useEffect, useState } from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Button,
//   Modal,
//   TextField,
//   IconButton,
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   tableCellClasses,
//   styled,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
// import "../assets/css/table.css";
// import "../assets/css/style.css";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
// import { apiAddPart } from "../api/api.addpart";
// import { apiGetPart } from "../api/api.getpart";
// import { apigetLines } from "../api/api.getline";
// import { apiGetPlant } from "../api/api.getplant";
// import { apigetMachine } from "../api/apigetmachine";
// import { apiUpdatePart } from "../api/api.updatepart";
// import { apiDeletePart } from "../api/api.deletepart";

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: "#1FAEC5",
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));

// const PartMaster = () => {
//   const [open, setOpen] = useState(false);
//   const [rowData, setRowData] = useState({});
//   const [searchTerm, setSearchTerm] = useState("");
//   const [addOpen, setAddOpen] = useState(false);
//   const [newRowData, setNewRowData] = useState({});
//   const [lineName, setLineName] = useState("");
//   const [partName, setPartName] = useState("");
//   const [plantName, setPlantName] = useState("");
//   const [partNo, setPartNo] = useState("");
//   const [plantProduction, setPlantProduction] = useState("");
//   const [cycleTime, setcycleTime] = useState("");
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");

//   const [multipleFactor, setMultipleFactor] = useState("");

//   const [machineName, setMachineName] = useState("");
//   const [ctReduction, setCtReduction] = useState("");
//   const [upperBound, setUpperBound] = useState("");
//   const [lowerBound, setLowerBound] = useState("");
//   const [severity, setSeverity] = useState("success");
//   const [refreshData, setRefreshData] = useState(false);
//   const [lineData, setLineData] = useState([]);
//   const [machineData, setMachineData] = useState([]);
//   const [plantData, setPlantData] = useState([]);

//   const [error, setError] = useState(null);
//   const [partData, setPartData] = useState([]);
//   const [deleteModalOpen, setDeleteModalOpen] = useState(false);
//   const [deletePlantId, setDeletePlantId] = useState(null);
//   const [updatedPartData, setUpdatedPartData] = useState({
//     partNo: "",
//     plantNo: "",
//     lineNo: "",
//     machineNo: "",
//     partName: "",
//     cycleTime: "",
//     plantProduction: "",
//     multipleFactor: "",
//     ctReduction: "",
//     lowerBound: "",
//     upperBound: "",
//   });
//   // "partNo":"part12234",
//   // "plantNo":1,
//   // "lineNo":1,
//   // "machineNo":1,
//   // "partName":"Part 1",
//   // "cycleTime":"5.30",
//   // "plantProduction":100,
//   // "multipleFactor":2,
//   // "ctReduction":5,
//   // "lowerBound":5,
//   // "upperBound":10

//   useEffect(() => {
//     const getParts = async () => {
//       try {
//         const result = await apiGetPart();
//         console.log(result.data.data);
//         setPartData(result.data.data);
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getParts();
//   }, [refreshData]);

//   useEffect(() => {
//     const getPlant = async () => {
//       try {
//         const result = await apiGetPlant();
//         console.log("Result data plant:", result.data.data); // Log the data property
//         setPlantData(result.data.data); // Set plantData to result.data
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getPlant();
//   }, [refreshData]);

//   useEffect(() => {
//     const getline = async () => {
//       try {
//         const result = await apigetLines();
//         console.log("Result data line:", result.data.data); // Log the data property
//         setLineData(result.data.data); // Set plantData to result.data
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getline();
//   }, [refreshData]);

//   useEffect(() => {
//     const getmachine = async () => {
//       try {
//         const result = await apigetMachine();
//         console.log("Result data machine:", result.data.data); // Log the data property
//         setMachineData(result.data.data); // Set plantData to result.data
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getmachine();
//   }, [refreshData]);

//   const getParts = async () => {
//     try {
//       const result = await apiGetPart();
//       console.log(result?.data.data);
//       setPartData(result?.data.data);
//     } catch (error) {
//       setError(error.message);
//       handleSnackbarOpen(error.message, "error");
//     }
//   };
//   const handleInputChange = (e) => {
//     console.log(e.target.name, e.target.value);
//     const { name, value } = e.target;
//     setUpdatedPartData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleEditClick = (row) => {
//     setRowData(row);
//     setOpen(true);
//   };

//   const handleUpdateSubmit = async (event) => {
//     event.preventDefault();

//     try {
//       const result = await apiUpdatePart(updatedPartData);

//       setOpen(false);
//       await getParts();
//       handleSnackbarOpen("Part updated successfully!", "success"); // Pass severity as "success"
//       console.log("response", result.data);
//     } catch (error) {
//       console.error("Error updating Part:", error);
//       handleSnackbarOpen("Error updating Part. Please try again.", "error"); // Pass severity as "error"
//     }
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleDeleteClick = async(id) => {
//     try {
//       const result = await apiDeletePart(id.partNo);
//       setRefreshData((prev) => !prev);
//       handleSnackbarOpen("Part deleted successfully!", "success"); // Pass severity as "success"
//       console.log("response", result.data);
//     } catch (error) {
//       console.error("Error deleting Part:", error);
//       handleSnackbarOpen("Error deleting Part. Please try again.", "error"); // Pass severity as "error"
//     }
//   };
//   // const handleEditSubmit = () => {
//   //   // Implement edit functionality
//   //   console.log("Edited row data:", rowData);
//   //   setOpen(false);
//   // };
//   const handleEditSubmit = (row) => {
//     // alert("ehllo")
//     console.log("editt data",row)
//     setUpdatedPartData(row);
//     setOpen(true);
//   };

//   const handlePlantNameChange = (event) => {
//     setPlantName(event.target.value);
//   };

//   const handleSnackbarOpen = (message, severity) => {
//     setSnackbarMessage(message);
//     setSeverity(severity);
//     setOpenSnackbar(true);
//   };
//   const handleAddSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       const result = await apiAddPart(updatedPartData);
//       // console.log("response", result.data);
//       handleSnackbarOpen("Part added successfully!", "success");
//       setAddOpen(false);
//       console.log("response", result.data);
//       setRefreshData((prev) => !prev);
//     } catch (error) {
//       console.error("Error adding Part:", error);
//       handleSnackbarOpen("Error adding Part. Please try again.", "error"); // Pass severity as "error"
//     }
//   };

//   return (
//     <div style={{ padding: "100px 20px" }}>
//       <div
//         style={{
//           display: "flex",
//           width: "100%",
//           alignItems: "center",
//           justifyContent: "space-between",
//           paddingTop: "5px",
//           paddingBottom: "5px",
//         }}
//       >
//         <h2>Part Master</h2>
//          <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
//           <Button
//             onClick={() => setAddOpen(true)}
//             style={{
//               fontWeight: "500",
//               borderRadius: "4px",
//               color: "gray",
//               border: "2px solid gray",
//               padding: "5px",
//               marginBottom: "5px",
//             }}
//           >
//             {" "}
//             Add New &nbsp;{" "}
//             <FontAwesomeIcon
//               style={{ fontSize: "18px", color: "gray" }}
//               icon={faPlus}
//             />
//           </Button>
//         </div>
//       </div>
//       <Box>

//         {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
//           <div style={{ justifyContent: "center", alignItems: "center" }}>
//             <TextField
//               label="Search"
//               variant="outlined"
//               style={{ marginBottom: "20px"  }}
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//             />
//             {"   "}
//             <FontAwesomeIcon
//               style={{ fontSize: "30px", padding: "15px" }}
//               icon={faSearch}
//             />
//           </div>
//           <div style={{ justifyContent: "space-between" }}>
//             <Button
//               style={{
//                 fontWeight: "400",
//                 borderRadius: "4px",
//                 color: "gray",
//                 border: "3px solid gray",
//                 padding: "5px",
//                 marginBottom: "5px",
//               }}
//             >
//               View Multiple Parts
//             </Button>
//           </div>
//         </div> */}
//         <TableContainer component={Paper}>
//           <Table
//             size="small"
//             style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
//           >
//             <TableHead>
//               <TableRow>
//                 <StyledTableCell className="table-cell">
//                   Plant Name
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   Line Name
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   Machine Name
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   Plant Production
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   Part Name
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   Cycle Time
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   CT Reduction
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   Multiple Factor
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   Lower Bound
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   Upper Bound
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">Action</StyledTableCell>

//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {partData.map((row) => (
//                 <StyledTableRow key={row.id}>
//                   <StyledTableCell className="table-cell">
//                     {row.plantName}
//                   </StyledTableCell>
//                   <StyledTableCell className="table-cell">
//                     {row.lineName}
//                   </StyledTableCell>
//                   <StyledTableCell className="table-cell">
//                     {row.machineName}
//                   </StyledTableCell>
//                   <StyledTableCell className="table-cell">
//                     {row.plantProduction}
//                   </StyledTableCell>
//                   <StyledTableCell className="table-cell">
//                     {row.partName}
//                   </StyledTableCell>
//                   <StyledTableCell className="table-cell">
//                     {row.cycleTime}
//                   </StyledTableCell>
//                   <StyledTableCell className="table-cell">
//                     {row.ctReduction}
//                   </StyledTableCell>
//                   <StyledTableCell className="table-cell">
//                     {row.multipleFactor}
//                   </StyledTableCell>
//                   <StyledTableCell className="table-cell">
//                     {row.lowerBound}
//                   </StyledTableCell>
//                   <StyledTableCell className="table-cell">
//                     {row.upperBound}
//                   </StyledTableCell>
//                   <StyledTableCell  style={{ display: "flex", gap: "10px",alignItems:"center" , justifyContent:"space-evenly" }}  className="table-cell">
//                    <IconButton onClick={() => handleEditSubmit(row)}>
//                       <EditIcon />
//                     </IconButton>
//                     <div className="divider" style={{ height: "20px", width: "2px", backgroundColor: "#0003" }} ></div>

//                     <IconButton     style={{ color: "#FF3131" }} onClick={() => handleDeleteClick(row)}>
//                       <DeleteIcon />
//                     </IconButton>
//                   </StyledTableCell>
//                 </StyledTableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>

//         {/* Modal for editing */}
//         <Modal open={open} onClose={handleClose}>
//           <div
//             style={{
//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               backgroundColor: "white",
//               padding: "20px",
//               minWidth: "500px",
//             }}
//           >
//              <button
//       onClick={handleClose}
//       style={{
//         position: "absolute",
//         top: "10px",
//         right: "10px",
//         cursor: "pointer",
//         backgroundColor: "transparent",
//         border: "none",
//         fontSize: "30px",
//       }}
//     >
//       &times;
//     </button>
//             <h2>Edit Part</h2>
//             <hr />
//             <br />
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//            <FormControl sx={{ width: "26ch" }}>
//                 <InputLabel>Plant Name</InputLabel>
//                 <Select
//                   name="plantNo"
//                   value={updatedPartData?.plantNo}
//                   onChange={handleInputChange}
//                 >
//                   {plantData.map((plant, index) => (
//                     <MenuItem key={plant.plantNo} value={plant?.plantNo}>
//                       {plant?.plantName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>

//               <FormControl sx={{ width: "26ch" }}>
//                 <InputLabel>Line Name</InputLabel>
//                 <Select
//                   name="lineNo"
//                   value={updatedPartData?.lineNo}
//                   onChange={handleInputChange}
//                 >
//                   {lineData.map((line) => (
//                     <MenuItem key={line.id} value={line.lineNo}>
//                       {line.lineName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//           </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <FormControl sx={{ width: "26ch" }}>
//                 <InputLabel>Machine Name</InputLabel>
//                 <Select
//                   name="machineNo"
//                   value={updatedPartData?.machineNo}
//                   onChange={handleInputChange}
//                 >
//                   {machineData.map((id) => (
//                     <MenuItem key={id.id} value={id.machineNo}>
//                       {id.displayMachineName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//                 </FormControl>
//                 <TextField
//                 name={"plantProduction"}
//                 label="Plant Production"
//                 value={updatedPartData?.plantProduction}
//                 onChange={handleInputChange}
//               />
//               </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//            <TextField
//                 name="partName"
//                 label="Part Name"
//                 value={updatedPartData?.partName}
//                 onChange={handleInputChange}
//                 style={{ marginRight: "10px" }}
//               />
//               <TextField
//                 name="cycleTime"
//                 label="Standard Cycle Time"
//                 value={updatedPartData?.cycleTime}
//                 onChange={handleInputChange}
//               />
//                 </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//              <TextField
//                 name={"ctReduction"}
//                 label="Reduction (%)"
//                 value={updatedPartData?.ctReduction}
//                 style={{ marginRight: "10px" }}
//                 onChange={handleInputChange}
//               />
//            <TextField
//                 name={"multipleFactor"}
//                 label="Multiple Factor"
//                 value={updatedPartData?.multipleFactor}
//                 onChange={handleInputChange}
//               />
//                </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//                 <TextField
//                 label="Lower Bound"
//                 name="lowerBound"
//                 value={updatedPartData?.lowerBound}
//                 style={{ marginRight: "10px" }}
//                 onChange={handleInputChange}
//               />
//               <TextField
//                 name="upperBound"
//                 label="Upper Bound"
//                 value={updatedPartData?.upperBound}
//                 onChange={handleInputChange}
//               />
//             </div>
//             <Button
//               onClick={handleUpdateSubmit}
//               variant="contained"
//               color="primary"
//               style={{ marginTop: "20px" }}
//             >
//               Save
//             </Button>
//           </div>
//         </Modal>
//         <Modal open={addOpen} onClose={() => setAddOpen(false)}>
//           <div
//             style={{
//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               backgroundColor: "white",
//               padding: "20px",
//               minWidth: "500px",
//             }}
//           >
//              <button
//       onClick={() => setAddOpen(false)}
//       style={{
//         position: "absolute",
//         top: "10px",
//         right: "10px",
//         cursor: "pointer",
//         backgroundColor: "transparent",
//         border: "none",
//         fontSize: "30px",
//       }}
//     >
//       &times;
//     </button>
//             <h2>Add New Part </h2>
//             <hr />
//             <br />
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <FormControl sx={{ width: "26ch" }}>
//                 <InputLabel>Organization</InputLabel>
//                 <Select name="organization" defaultValue="">
//                   <MenuItem value={1}>Digineous</MenuItem>
//                 </Select>
//               </FormControl>
//               <FormControl sx={{ width: "26ch" }}>
//                 <InputLabel>Plant Name</InputLabel>
//                 <Select
//                   name="plantNo"
//                   value={updatedPartData?.plantNo}
//                   onChange={handleInputChange}
//                 >
//                   {plantData.map((plant, index) => (
//                     <MenuItem key={plant.plantNo} value={plant?.plantNo}>
//                       {plant?.plantName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <FormControl sx={{ width: "26ch" }}>
//                 <InputLabel>Line Name</InputLabel>
//                 <Select
//                   name="lineNo"
//                   value={updatedPartData?.lineNo}
//                   onChange={handleInputChange}
//                 >
//                   {lineData.map((line) => (
//                     <MenuItem key={line.id} value={line.lineNo}>
//                       {line.lineName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//               <FormControl sx={{ width: "26ch" }}>
//                 <InputLabel>Machine Name</InputLabel>
//                 <Select
//                   name="machineNo"
//                   value={updatedPartData?.machineNo}
//                   onChange={handleInputChange}
//                 >
//                   {machineData.map((id) => (
//                     <MenuItem key={id.id} value={id.machineNo}>
//                       {id.displayMachineName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <TextField
//                 name="partName"
//                 label="Part Name"
//                 value={updatedPartData?.partName}
//                 onChange={handleInputChange}
//                 style={{ marginRight: "10px" }}
//               />
//               <TextField
//                 name="cycleTime"
//                 label="Standard Cycle Time"
//                 value={updatedPartData?.cycleTime}
//                 onChange={handleInputChange}
//               />
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <TextField
//                 name="partNo"
//                 label="Part No"
//                 value={updatedPartData?.partNo}
//                 onChange={handleInputChange}
//                 style={{ marginRight: "10px" }}
//               />
//               <TextField
//                 name={"multipleFactor"}
//                 label="Multiple Factor"
//                 value={updatedPartData?.multipleFactor}
//                 onChange={handleInputChange}
//               />
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <TextField
//                 name={"ctReduction"}
//                 label="Reduction (%)"
//                 value={updatedPartData?.ctReduction}
//                 style={{ marginRight: "10px" }}
//                 onChange={handleInputChange}
//               />
//               <TextField
//                 name={"plantProduction"}
//                 label="Plant Production"
//                 value={updatedPartData?.plantProduction}
//                 onChange={handleInputChange}
//               />
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <TextField
//                 label="Lower Bound"
//                 name="lowerBound"
//                 value={updatedPartData?.lowerBound}
//                 style={{ marginRight: "10px" }}
//                 onChange={handleInputChange}
//               />
//               <TextField
//                 name="upperBound"
//                 label="Upper Bound"
//                 value={updatedPartData?.upperBound}
//                 onChange={handleInputChange}
//               />
//             </div>

//             <Button
//               onClick={handleAddSubmit}
//               variant="contained"
//               color="primary"
//               style={{ marginTop: "20px" }}
//             >
//               Add
//             </Button>
//           </div>
//         </Modal>
//       </Box>
//       <Snackbar
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={() => setOpenSnackbar(false)}
//       >
//         <MuiAlert
//           onClose={() => setOpenSnackbar(false)}
//           severity={severity}
//           sx={{ width: "100%" }}
//         >
//           {snackbarMessage}
//         </MuiAlert>
//       </Snackbar>
//     </div>
//   );
// };

// export default PartMaster;

//above wokring//
