// import React, { useEffect, useState } from 'react';
// import { Button, Grid, Paper, Typography, CircularProgress } from '@mui/material';
// import { LineChart } from '@mui/x-charts/LineChart';
// import { BarChart } from '@mui/x-charts/BarChart';
// import '../assets/css/emanagement.css';
// import BackButton from './backbutton';
// import { useLocation } from 'react-router-dom';
// import { apiGetAirQuality } from '../api/api.getaquality';

// export default function AirQualityManagement() {
//   const [airQualityData, setAirQualityData] = useState([]);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [error, setError] = useState(null);
//   const [severity, setSeverity] = useState("success");
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const location = useLocation();
//   const [chartData, setChartData] = useState([]);
//   const [dataType, setDataType] = useState('shift');
//   const [isLoading, setIsLoading] = useState(false);
//   const [loadingMessage, setLoadingMessage] = useState('');
//   const { locationId } = location.state || {};
//   const [initialLoad, setInitialLoad] = useState(true);

//   const fetchData = async (locationId, dataType,deviceNo,showLoader = false) => {
//     try {
//       if (showLoader) {
//         setIsLoading(true);
//         setLoadingMessage(`Loading ${dataType.charAt(0).toUpperCase() + dataType.slice(1)}`);
//       }
//       const result = await apiGetAirQuality(locationId, dataType,deviceNo);
//       const chunkedData = result.data.data.slice(0, 20);
//       setAirQualityData(chunkedData);
//       const xAxisDates = chunkedData.map((item) => {
//         const dateParts = item.dateTimeRecvd.split(' ');
//         const datePart = dateParts[0];
//         const timePart = dateParts[1];
//         const [day, month, year] = datePart.split('-');
//         const [hours, minutes, seconds] = timePart.split(':');
//         const date = new Date(year, month - 1, day, hours, minutes, seconds);
//         return date;
//       });
//       const sortedData = chunkedData.sort((a, b) => {
//         const dateA = new Date(a.dateTimeRecvd.split(' ')[0].split('-').reverse().join('-') + 'T' + a.dateTimeRecvd.split(' ')[1]);
//         const dateB = new Date(b.dateTimeRecvd.split(' ')[0].split('-').reverse().join('-') + 'T' + b.dateTimeRecvd.split(' ')[1]);
//         return dateA - dateB;
//       });
//       const updatedChartData = [
//         {
//           type: "line",
//           xAxis: xAxisDates,
//           data: sortedData.map((item) => parseFloat(item.temperature)),
//         },
//         {
//           type: "line",
//           xAxis: xAxisDates,
//           data: sortedData.map((item) => parseFloat(item.humidity)),
//         },
//         {
//           type: "line",
//           xAxis: xAxisDates,
//           data: sortedData.map((item) => parseFloat(item.co2)),
//         },
//       ];
//       setChartData(updatedChartData);
//       console.log("updated chart data:",updatedChartData)
//       if (showLoader) {
//         setIsLoading(false);
//       }
//     } catch (error) {
//       console.error("Error fetching air quality data:", error);
//       setError("Failed to fetch data");
//       if (showLoader) {
//         setIsLoading(false);
//       }
//     }
//   };

//   useEffect(() => {
//     if (locationId) {
//       fetchData(locationId, dataType, initialLoad);
//       const intervalId = setInterval(() => fetchData(locationId, dataType), 10000);
//       return () => clearInterval(intervalId);
//     }
//   }, [locationId, dataType, initialLoad]);

//   const handleButtonClick = (type) => {
//     setDataType(type);
//     setInitialLoad(true);
//     fetchData(locationId, type, true);
//     setInitialLoad(false);
//   };

//   return (
//     <div style={{ padding: '20px' }}>
//       <Grid container spacing={2} alignItems="center">
//         <Grid item xs={5} display={'flex'}>
//           <BackButton style={{ marginTop: '5px', backgroundColor: 'red' }} />
//           <Typography variant="h5" gutterBottom>
//             AirQuality Management
//           </Typography>
//         </Grid>
//         <Grid item xs={7} container justifyContent="flex-end">
//           {renderButtons(handleButtonClick, dataType)}
//         </Grid>
//         {isLoading ? (
//           <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
//             <CircularProgress />
//             <Typography variant="h6" style={{ marginLeft: '10px' }}>{loadingMessage}</Typography>
//           </Grid>
//         ) : (
//           chartData.map((chart, index) => (
//             <Grid item xs={4} key={index}>
//               <Paper elevation={3} style={{ padding: 20, backgroundColor: 'rgba(3, 3, 62, 0.9)' }}>
//                 <Typography variant="h6" gutterBottom style={getChartTitleStyle(chart, index)}>
//                   {getChartTitle(chart, index)}
//                 </Typography>
//                 {renderChart(chart)}
//               </Paper>
//             </Grid>
//           ))
//         )}
//       </Grid>
//     </div>
//   );
// }

// function getChartTitle(chart, index) {
//   if (chart.type === 'line') {
//     switch (index) {
//       case 0:
//         return 'Temperature (°C)';
//       case 1:
//         return 'Humidity (%)';
//       case 2:
//         return 'CO2 (PPM)';
//       default:
//         return `Line Chart ${index + 1}`;
//     }
//   } else if (chart.type === 'bar') {
//     return `Bar Chart ${index + 1}`;
//   } else {
//     return `Chart ${index + 1}`;
//   }
// }

// function getChartTitleStyle(chart, index) {
//   const defaultStyle = { color: 'white', fontFamily: 'Arial, sans-serif' };
//   return { ...defaultStyle, fontWeight: 'bold' };
// }

// function renderChart(chart) {
//   if (chart.type === 'bar') {
//     return (
//       <BarChart
//         xAxis={[{ scaleType: 'time', data: chart.xAxis }]}
//         series={[{ data: chart.data }]}
//         width={450}
//         height={200}
//       />
//     );
//   } else {
//     return (
//       <LineChart
//         xAxis={[{ scaleType: 'time', data: chart.xAxis }]}
//         series={[{ data: chart.data }]}
//         width={450}
//         height={200}
//       />
//     );
//   }
// }

// function renderButtons(handleButtonClick, currentDataType) {
//   const buttonLabels = ['Shift', 'Day', 'Week', 'Month', 'Quarter', 'Year'];
//   return buttonLabels.map((label, index) => (
//     <Button
//       key={index}
//       variant="contained"
//       style={{ margin: '0px 2px', backgroundColor: currentDataType === label.toLowerCase() ? 'rgba(3, 3, 62, 0.9)' : 'gray' }}
//       onClick={() => handleButtonClick(label.toLowerCase())}
//     >
//       {label}
//     </Button>
//   ));
// }

import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { apiGetAirQuality } from "../api/api.getaquality";
import "chartjs-plugin-zoom";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";
import BackButton from "./backbutton";
import { apiGetDevice } from "../api/api.getdevice";
import { styled } from "@mui/material/styles";

// Register necessary chart components and plugins
ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor:
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.23)"
        : "rgba(255, 255, 255, 0.23)",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor:
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.87)"
        : "rgba(255, 255, 255, 0.87)",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.main,
  },
  "& .MuiSelect-select": {
    color: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff",
    padding: "8px 14px", // Reduce padding to make it more compact
  },
  "& .MuiInputBase-root": {
    height: 40, // Set a specific height
  },
}));
const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  transform: "translate(14px, 9px) scale(1)",
  "&.MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(0.75)",
  },
}));

export default function AirQualityManagement() {
  const [airQualityData, setAirQualityData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [temperatureData, setTemperatureData] = useState({});
  const [humidityData, setHumidityData] = useState({});
  const [co2Data, setCo2Data] = useState({});
  const [temperatureData2, setTemperatureData2] = useState({});
  const [humidityData2, setHumidityData2] = useState({});
  const [co2Data2, setCo2Data2] = useState({});
  const [dataType, setDataType] = useState("shift");
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [deviceNos, setDeviceNos] = useState([]);
  const [airQualityDevice1, setAirQualityDevice1] = useState("");
  const [airQualityDevice2, setAirQualityDevice2] = useState("");

  const [selectedDevice, setSelectedDevice] = useState("");
  const location = useLocation();
  const { locationId, locationName } = location.state || {};
  const [deviceNames, setDeviceNames] = useState([]);
  const [deviceNo, setDeviceNo] = useState("");
  const [locationData, setLocationData] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [refreshData, setRefreshData] = useState(false);

  const [severity, setSeverity] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  console.log("location id em:", locationId, "location name em:", locationName);

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };
  useEffect(() => {
    const getDeivce = async () => {
      try {
        const result = await apiGetDevice();
        console.log("location data:", result.data.data); // Log the data property
        setLocationData(result.data.data); // Set plantData to result.data
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getDeivce();
  }, [refreshData]);
  useEffect(() => {
    if (locationName && locationData.length > 0) {
      const filtered = locationData.filter(
        (device) => device.lineName === locationName
      );
      setFilteredDevices(filtered);
      if (filtered.length > 0) {
        const deviceNosList = filtered.map((device) => device.deviceNo);
        const deviceNamesList = filtered.map((device) => device.deviceName);

        setDeviceNos(deviceNosList); // Set all matching deviceNos for the initial fetch
        setDeviceNames(deviceNamesList); // Set all matching deviceNames for the initial fetch

        // Separate filtering logic to find specific energy devices
        const airQuality1Device = filtered.find(
          (device) => device.deviceName === "Air Quality-1"
        );
        const airQuality2Device = filtered.find(
          (device) => device.deviceName === "Air Quality-2"
        );

        setAirQualityDevice1(airQuality1Device?.deviceNo || null);
        setAirQualityDevice2(airQuality2Device?.deviceNo || null);
      }

      console.log("device no and name ,:", deviceNos, deviceNames);
      console.log(
        "energy device 1 ,energy device 2:",
        airQualityDevice1,
        airQualityDevice2
      );
    }
  }, [locationName, locationData]);

  const handleDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
    setDeviceNo(event.target.value);
    console.log("setdeviceno:",deviceNo)
  };

  const fetchData = async (
    locationId,
    dataType,
    deviceNo,
    showLoader = false
  ) => {
    try {
      if (showLoader) {
        setIsLoading(true);
        setLoadingMessage(
          `Loading ${dataType.charAt(0).toUpperCase() + dataType.slice(1)}`
        );
      }
      console.log("from aqmanagement:",locationId,dataType,deviceNo)
      const result = await apiGetAirQuality(locationId, dataType, deviceNo);
      const chunkedData = result.data.data.slice(0, 15);
      console.log("air quality chunked data:",chunkedData)

      // Prepare the data for the chart
      const xAxisDates = chunkedData.map((item) => {
        const dateParts = item.dateTimeRecvd.split(" ");
        const datePart = dateParts[0];
        const timePart = dateParts[1];
        const [day, month, year] = datePart.split("-");
        const [hours, minutes, seconds] = timePart.split(":");
        return new Date(year, month - 1, day, hours, minutes, seconds);
      });

      const sortedData = chunkedData.sort((a, b) => {
        const dateA = new Date(
          a.dateTimeRecvd.split(" ")[0].split("-").reverse().join("-") +
            "T" +
            a.dateTimeRecvd.split(" ")[1]
        );
        const dateB = new Date(
          b.dateTimeRecvd.split(" ")[0].split("-").reverse().join("-") +
            "T" +
            b.dateTimeRecvd.split(" ")[1]
        );
        return dateA - dateB;
      });

      setTemperatureData({
        labels: xAxisDates,
        datasets: [
          {
            label: "Temperature (°C) -1",
            data: sortedData.map((item) => parseFloat(item.temperature)),
            fill: false,
            borderColor: "rgba(75,192,192,1)",
            tension: 0.1,
          },
        ],
      });

      setHumidityData({
        labels: xAxisDates,
        datasets: [
          {
            label: "Humidity (%) -1",
            data: sortedData.map((item) => parseFloat(item.humidity)),
            fill: false,
            borderColor: "rgba(153,102,255,1)",
            tension: 0.1,
          },
        ],
      });

      setCo2Data({
        labels: xAxisDates,
        datasets: [
          {
            label: "CO2 (PPM) -1",
            data: sortedData.map((item) => parseFloat(item.co2)),
            fill: false,
            borderColor: "rgba(255,99,132,1)",
            tension: 0.1,
          },
        ],
      });

      setTemperatureData2({
        labels: xAxisDates,
        datasets: [
          {
            label: "Temperature (°C) -2",
            data: sortedData.map((item) => parseFloat(item.temperature)),
            fill: false,
            borderColor: "rgba(75,192,192,1)",
            tension: 0.1,
          },
        ],
      });

      setHumidityData2({
        labels: xAxisDates,
        datasets: [
          {
            label: "Humidity (%) -2",
            data: sortedData.map((item) => parseFloat(item.humidity)),
            fill: false,
            borderColor: "rgba(153,102,255,1)",
            tension: 0.1,
          },
        ],
      });

      setCo2Data2({
        labels: xAxisDates,
        datasets: [
          {
            label: "CO2 (PPM) -2",
            data: sortedData.map((item) => parseFloat(item.co2)),
            fill: false,
            borderColor: "rgba(255,99,132,1)",
            tension: 0.1,
          },
        ],
      });

      if (showLoader) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching air quality data:", error);
      setError("Failed to fetch data");
      if (showLoader) {
        setIsLoading(false);
      }
    }
  };

  const options = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "minute",
        },
        title: {
          display: true,
          text: "Time",
          color: "white",
        },
        ticks: {
          color: "white",
        },
        grid: {
          color: "black",
        },
      },
      y: {
        title: {
          display: true,
          text: "Value",
          color: "white",
        },
        ticks: {
          color: "white",
        },
        grid: {
          color: "black",
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: "white", // Set the legend labels color to white
          font: {
            size: 16, // Adjust the font size for the legend labels
          },
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "xy",
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "xy",
        },
      },
    },
  };

  useEffect(() => {
    if (locationId) {
      fetchData(locationId, dataType, deviceNo, true);
      const intervalId = setInterval(
        () => fetchData(locationId, dataType,deviceNo),
        10000
      );
      return () => clearInterval(intervalId);
    }
  }, [locationId, dataType, deviceNo]);

  const handleButtonClick = (type) => {
    setDataType(type);
    fetchData(locationId, type, true);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3} display={"flex"}>
          <BackButton style={{ marginTop: "5px", backgroundColor: "red" }} />
          <Typography variant="h5" gutterBottom>
            AirQuality Management
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth variant="outlined" size="small">
            <StyledInputLabel id="device-select-label">
              Select Device
            </StyledInputLabel>
            <StyledSelect
              labelId="device-select-label"
              id="device-select"
              value={selectedDevice}
              onChange={handleDeviceChange}
              label="Select Device"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {airQualityDevice1 && (
                <MenuItem value={airQualityDevice1}>
                  Air quality-1: {airQualityDevice1}
                </MenuItem>
              )}
              {airQualityDevice2 && (
                <MenuItem value={airQualityDevice2}>
                  Air quality-2: {airQualityDevice2}
                </MenuItem>
              )}
            </StyledSelect>
          </FormControl>
        </Grid>

        <Grid item xs={6} container justifyContent="flex-end">
          {renderButtons(handleButtonClick, dataType)}
        </Grid>
        {isLoading ? (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <CircularProgress />
            <Typography variant="h6" style={{ marginLeft: "10px" }}>
              {loadingMessage}
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid item xs={4}>
              <Paper
                elevation={3}
                style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
              >
                {temperatureData.datasets ? (
                  <Line data={temperatureData} options={options} />
                ) : (
                  <Typography variant="body1" align="center">
                    No temperature data available
                  </Typography>
                )}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                elevation={3}
                style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
              >
                {humidityData.datasets ? (
                  <Line data={humidityData} options={options} />
                ) : (
                  <Typography variant="body1" align="center">
                    No humidity data available
                  </Typography>
                )}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                elevation={3}
                style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
              >
                {co2Data.datasets ? (
                  <Line data={co2Data} options={options} />
                ) : (
                  <Typography variant="body1" align="center">
                    No CO2 data available
                  </Typography>
                )}
              </Paper>
            </Grid>
            {/* <Grid item xs={4}>
              <Paper
                elevation={3}
                style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
              >
                {temperatureData2.datasets ? (
                  <Line data={temperatureData2} options={options} />
                ) : (
                  <Typography variant="body1" align="center">
                    No temperature data available
                  </Typography>
                )}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                elevation={3}
                style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
              >
                {humidityData2.datasets ? (
                  <Line data={humidityData2} options={options} />
                ) : (
                  <Typography variant="body1" align="center">
                    No humidity data available
                  </Typography>
                )}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                elevation={3}
                style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
              >
                {co2Data2.datasets ? (
                  <Line data={co2Data2} options={options} />
                ) : (
                  <Typography variant="body1" align="center">
                    No CO2 data available
                  </Typography>
                )}
              </Paper>
            </Grid> */}
          </>
        )}
      </Grid>
    </div>
  );
}

function renderButtons(handleButtonClick, currentDataType) {
  const buttonLabels = ["Shift", "Day", "Week", "Month", "Quarter", "Year"];
  return buttonLabels.map((label, index) => (
    <Button
      key={index}
      variant="contained"
      style={{
        margin: "0px 2px",
        backgroundColor:
          currentDataType === label.toLowerCase()
            ? "rgba(3, 3, 62, 0.9)"
            : "gray",
      }}
      onClick={() => handleButtonClick(label.toLowerCase())}
    >
      {label}
    </Button>
  ));
}
