import React, { useRef, useEffect, useState } from "react";

import { Box, Button, Grid, Typography } from "@mui/material";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import Chart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { XGrid } from "@mui/x-data-grid";
import {
  faBullseye,
  faGears,
  faScrewdriver,
  faGaugeHigh,
  faFileContract,
  faPlay,
  faFileExcel,
  faGear,
  faExpand,
  faCompress,
} from "@fortawesome/free-solid-svg-icons";
import "../assets/css/operations.css";

const radChart1 = {
  width: 300,
  height: 230,

  value: 60,
  text: "Availability 60%",
};
const radChart2 = {
  width: 300,
    height: 230,


  value: 80,
  text: "Performance 80%",
};
const radChart3 = {
  width: 300,
    height: 230,


  value: 90,
  text: "Quality 90%",
};
const radChart4 = {
  width: 300,
    height: 230,


  value: 85,
  text: "OEE 85%",
};
const Operations = () => {
  // Sample data for charts
  const [isFullScreen, setIsFullScreen] = useState(false);

  const barChartData2 = {
    series: [
      {
        name: "Capacity Utilization",
        data: [86.56], // Sample utilization percentages for each machine
      },
    ],
    options: {
      chart: {
        type: "bar",
          height: 250


      },
      xaxis: {
        categories: [""],
        max: 100,
      },
      plotOptions: {
        bar: {
          horizontal: true, // Display the bars horizontally
        },
      },
      dataLabels: {
        enabled: false, // Disable data labels for better readability
      },
      colors: ["#006400"],
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#006400"],
      // Add annotations for the threshold line
      annotations: {
        // Create a line at the threshold value
        xaxis: [
          {
            x: 70, // Adjust the threshold value as needed
            borderColor: "#FF0000",
            label: {
              borderColor: "#FF0000",
              style: {
                color: "#fff",
                background: "#FF0000",
              },
              text: "Threshold: 70%", // Annotation label
            },
          },
        ],
      },
    },
  };
  const radialChartData = [
    {
      chart: {
        height: 280,
        type: "radialBar",
      },
      series: [67],
      options: {
        colors: ["#DE3163"],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "70%",
              background: "#293450",
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              name: {
                offsetY: -10,
                color: "#fff",
                fontSize: "12px",
              },
              value: {
                color: "#fff",
                fontSize: "30px",
                show: true,
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Availability %"],
        annotations: {
          points: [
            {
              x: 0,
              y: 67,
              y2: 50, // Adjust threshold position as needed
              borderColor: "#FF0000",
              label: {
                borderColor: "#FF0000",
                style: {
                  color: "#fff",
                  background: "#FF0000",
                },
                text: "Threshold: 50%", // Annotation label
              },
            },
          ],
        },
      },
    },
    {
      chart: {
        height: 280,
        type: "radialBar",
      },
      series: [50],
      options: {
        colors: ["#E2B82F"],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "70%",
              background: "#293450",
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              name: {
                offsetY: -10,
                color: "#fff",
                fontSize: "12px",
              },
              value: {
                color: "#fff",
                fontSize: "30px",
                show: true,
              },
            },
          },
        },
        // fill: {
        //   type: "gradient",
        //   gradient: {
        //     shade: "dark",
        //     type: "vertical",
        //     // gradientToColors: ["#87D4F9"],
        //     stops: [0, 100]
        //   }
        // },
        stroke: {
          lineCap: "round",
        },
        labels: ["Performance %"],
      },
    },
    {
      chart: {
        height: 280,
        type: "radialBar",
      },
      series: [80],
      options: {
        colors: ["#20E647"],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "70%",
              background: "#293450",
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              name: {
                offsetY: -10,
                color: "#fff",
                fontSize: "12px",
              },
              value: {
                color: "#fff",
                fontSize: "30px",
                show: true,
              },
            },
          },
        },

        stroke: {
          lineCap: "round",
        },
        labels: ["Quality %"],
        annotations: {
          points: [
            {
              x: 0,
              y: 80,
              y2: 50,
              marker: {
                size: 0,
              },
              strokeDashArray: 0,
              label: {
                borderColor: "#ff0000",
                style: {
                  color: "#ff0000",
                  background: "#FFFFFF",
                },
                text: "Threshold: 50%",
              },
            },
          ],
        },
      },
    },
    {
      chart: {
        height: 280,
        type: "radialBar",
      },
      series: [50],
      options: {
        colors: ["#710874"],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "70%",
              background: "#293450",
            },
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              name: {
                offsetY: -10,
                color: "#fff",
                fontSize: "12px",
              },
              value: {
                color: "#fff",
                fontSize: "30px",
                show: true,
              },
            },
          },
        },

        stroke: {
          lineCap: "round",
        },
        labels: ["OEE %"],
        annotations: {
          points: [
            {
              x: 0,
              y: 70,
              y2: 50,
              marker: {
                size: 0,
              },
              strokeDashArray: 0,
              label: {
                borderColor: "#ff0000",
                style: {
                  color: "#ff0000",
                  background: "#FFFFFF",
                },
                text: "Threshold: 50%",
              },
            },
          ],
        },
      },
    },
  ];

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullScreen(false);
      }
    }
  };
  return (
    <div
      style={{
        padding: "10px 20px",
        backgroundColor: "#E8E8E8",
      
      }}
      className="mainOperations"
    >
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={toggleFullScreen}
          style={{ cursor: "pointer", color: "black", fontWeight: "600" }}
        >
          {isFullScreen ? "Exit Full Screen" : "View Full Screen"}&nbsp;&nbsp;
          <FontAwesomeIcon icon={isFullScreen ? faCompress : faExpand} />
        </Button>
      </div>
      {/* <div style={{ padding: "20px", backgroundColor: '#E8E8E8' }} className="operationschart">
  <Box boxShadow={3} p={3} height="350px" width="97.5%" overflow="hidden"> */}
      {/* <Grid container display="flex" justifyContent={"space-between"} alignItems="center">
        {radialChartData.map((data, index) => (
          <Grid  item key={index}>
            <Box  p={1} boxShadow={3}>
              <Chart
                options={data.options}
                series={data.series}
                type="radialBar"
                
               
                // width={330}
              />
              <Typography variant="h6" align="center" gutterBottom>
                {data.heading}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid> */}

      {/*       
      <Grid item md={4} sm={4} >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={"space-between"}
        alignItems="center"
        className="radialChartOuter"
       
      >
        {radialChartData.map((data, index) => (
          <Box p={1} boxShadow={3}>
            <Chart
              options={data.options}
              series={data.series}
              type="radialBar"
              className="radialChart"
              // width={330}
            />
        
          </Box>
        ))}
      </Box>
      </Grid>
     */}

      <Grid item md={4} sm={4}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={"space-between"}
          alignItems="center"
          className="radialChartOuter"
        >
          <Box p={1} boxShadow={3} ml={1}>
            <Gauge
              {...radChart1}
              cornerRadius="50%"
              sx={(theme) => ({
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 18,
                  fontweight: "bold",
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: "#DE3163 !important",
                },
                // [`& .${gaugeClasses.referenceArc}`]: {
                //   fill: theme.palette.text.disabled,
                // },
              })}
            />
          </Box>
          <Box p={1} boxShadow={3}ml={1}>
            <Gauge
              {...radChart2}
              cornerRadius="50%"
              sx={(theme) => ({
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 18,
                  fontweight: "bold",
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: "#E2B82F !important",
                },
                [`& .${gaugeClasses.referenceArc}`]: {
                  fill: theme.palette.text.disabled,
                },
              })}
            />
          </Box>
          <Box p={1} boxShadow={3}ml={1}>
            <Gauge
              {...radChart3}
              cornerRadius="50%"
              sx={(theme) => ({
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 18,
                  fontweight: "bold",
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: "#20E647!important",
                },
                [`& .${gaugeClasses.referenceArc}`]: {
                  fill: theme.palette.text.disabled,
                },
              })}
            />
          </Box>
          <Box p={1} boxShadow={3} ml={1}>
            <Gauge
              {...radChart4}
              cornerRadius="50%"
              sx={(theme) => ({
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 18,
                  fontweight: "bold",
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: "#710874 !important",
                },
                [`& .${gaugeClasses.referenceArc}`]: {
                  fill: theme.palette.text.disabled,
                },
              })}
            />
          </Box>
        </Box>
      </Grid>

      <Grid container spacing={3} marginTop={"1px"}>
        <Grid item md={5} xs={12} p={1}>
          <Box boxShadow={3} p={3}>
            <Typography
              variant="h5"
              align="center"
              gutterBottom
              style={{
                fontFamily: "Arial",
                fontWeight: "bold",
                fontSize: "16px",
                color: "black",
              }}
            >
              Capacity Utilization %
            </Typography>
            <Typography
              variant="h5"
              align="center"
              gutterBottom
              style={{
                fontFamily: "Arial",
                fontWeight: "bold",
                fontSize: "16px",
                color: "black",
              }}
            >
              86.91
            </Typography>
            <Chart
              options={barChartData2.options}
              series={barChartData2.series}
              type="bar"
              height="176px"
            />
          </Box>
        </Grid>

        <Grid item md={7} xs={12}  justifyContent="center">
          <Box
            boxShadow={3}
            p={3}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            // height="300px"
            pt={6}
            pb={6}
          >
            <Grid container spacing={3}>
              <Grid item md={3} xs={6}>
                <Box
                  boxShadow={3}
                  p={2}
                  style={{
                    backgroundColor: "#3baea0",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                >
                  <Grid item xs={2}>
                    <FontAwesomeIcon
                      icon={faPlay}
                      style={{ fontSize: "26px" }}
                    ></FontAwesomeIcon>
                  </Grid>
                  <Grid textAlign="center">
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      5.1k
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      Run Time
                      <br />
                      (in mins)
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={3} xs={6}>
                <Box
                  boxShadow={3}
                  p={2}
                  style={{
                    backgroundColor: "#FF7F50",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                >
                  <Grid item xs={2} style={{ paddingLeft: "5px" }}>
                    <FontAwesomeIcon
                      icon={faGear}
                      style={{ fontSize: "26px" }}
                    ></FontAwesomeIcon>
                  </Grid>
                  <Grid paddingLeft="0px" textAlign="center">
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      408.77
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      Planned DT
                      <br /> (in mins)
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={3} xs={6}>
                <Box
                  boxShadow={3}
                  p={2}
                  style={{
                    backgroundColor: "	#DAA06D",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                >
                  <Grid item xs={2} style={{ paddingLeft: "5px" }}>
                    <FontAwesomeIcon
                      icon={faScrewdriver}
                      style={{ fontSize: "26px" }}
                    ></FontAwesomeIcon>
                  </Grid>
                  <Grid paddingLeft="0px" textAlign="center">
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      1.1k
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      Unplanned DT <br />
                      (in mins)
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={3} xs={6}>
                <Box
                  boxShadow={3}
                  p={2}
                  style={{
                    backgroundColor: "lightblue",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                >
                  <Grid item xs={2} style={{ paddingLeft: "5px" }}>
                    <FontAwesomeIcon
                      icon={faGaugeHigh}
                      style={{ fontSize: "26px" }}
                    ></FontAwesomeIcon>
                  </Grid>
                  <Grid paddingLeft="5px" textAlign="center">
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      103.77
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      AVG Cycle <br /> (in secs)
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              {/* Second Section: 4 Boxes Below */}

              <Grid item md={3} xs={6}>
                <Box
                  boxShadow={3}
                  p={2}
                  style={{
                    backgroundColor: "	#AA98A9",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                >
                  <Grid item xs={2} style={{ paddingLeft: "5px" }}>
                    <FontAwesomeIcon
                      icon={faBullseye}
                      style={{ fontSize: "26px" }}
                    ></FontAwesomeIcon>
                  </Grid>
                  <Grid paddingLeft="0px" textAlign="center">
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      3.65k
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      Target <br />
                      (in Nos)
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={3} xs={6}>
                <Box
                  boxShadow={3}
                  p={2}
                  style={{
                    backgroundColor: "#4682B4",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                >
                  <Grid item xs={2} style={{ paddingLeft: "5px" }}>
                    <FontAwesomeIcon
                      icon={faFileContract}
                      style={{ fontSize: "26px" }}
                    ></FontAwesomeIcon>
                  </Grid>
                  <Grid paddingLeft="0px" textAlign="center">
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      2.85k
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      Expected <br /> (in Nos)
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={3} xs={6}>
                <Box
                  boxShadow={3}
                  p={2}
                  style={{
                    backgroundColor: "#838996",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                >
                  <Grid item xs={2} style={{ paddingLeft: "5px" }}>
                    <FontAwesomeIcon
                      icon={faGear}
                      style={{ fontSize: "26px" }}
                    ></FontAwesomeIcon>
                  </Grid>
                  <Grid paddingLeft="4px" textAlign="center">
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      2.37k
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      Produced <br /> (in Nos)
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={3} xs={6}>
                <Box
                  boxShadow={3}
                  p={2}
                  style={{
                    backgroundColor: "pink",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    borderRadius: "5px",
                  }}
                >
                  <Grid item xs={2} style={{ paddingLeft: "5px" }}>
                    <FontAwesomeIcon
                      icon={faFileExcel}
                      style={{ fontSize: "26px" }}
                    ></FontAwesomeIcon>
                  </Grid>
                  <Grid paddingLeft="0px" textAlign="center">
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      21.00
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      Rejected <br /> (in NOS)
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Operations;
