import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function Temp(){
    const [email,setEmail]=useState("")
    const [password,setPassword]=useState("")
    const navigate=useNavigate()
    const handleEmail=(e)=>{
        const inputValue=e.target.value
        setEmail(inputValue)

    }
    
    const handlePassword=(e)=>{
        const inputValue=e.target.value
        setPassword(inputValue)
    }

    const handleSubmit=async(e)=>{
        e.preventDefault();
        try {
            const result= await axios.post("http://65.1.1.136:3000/api/auth/signin",{
                userId:email,
                password:password,
                // fcmtoken:'asfasfasfasf',
                // loginfrom:'W'
            })
            console.log(result.data)
            // const {statuscode}=result.data
            // if(statuscode===1){
            //     navigate("/afterlogin")
            // }

        } catch (error) {
            console.log("error:",error)
        }
      
    }
   return(
    <div>
    <form onSubmit={handleSubmit}>
    <div>
       email {" "}<input type="text" onChange={handleEmail}/>
    </div>
    <div>
       password <input type="password"onChange={handlePassword}/>
    </div>
    <div>
      <button style={{cursor:'pointer'}} type="submit">Submit</button>
    </div>
    </form>
    </div>
   )

}

export default Temp