import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import { BarChart } from "@mui/x-charts/BarChart";
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from "@mui/x-charts/Gauge";
import "../assets/css/emanagement.css";

import InfoBox from "./infobox";
import { apiGetGensetData } from "../api/api.getgensetdata";
import BackButton from "./backbutton";
import { useLocation, useNavigate } from "react-router-dom";
import { apiGetDevice } from "../api/api.getdevice";
import { styled } from "@mui/material/styles";

function GaugePointer() {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();

  if (valueAngle === null) {
    // No value to display
    return null;
  }

  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  };
  return (
    <g>
      <circle cx={cx} cy={cy} r={5} fill="red" />
      <path
        d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
        stroke="red"
        strokeWidth={3}
      />
    </g>
  );
}

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor:
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.23)"
        : "rgba(255, 255, 255, 0.23)",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor:
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.87)"
        : "rgba(255, 255, 255, 0.87)",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.main,
  },
  "& .MuiSelect-select": {
    color: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff",
    padding: "8px 14px", // Reduce padding to make it more compact
  },
  "& .MuiInputBase-root": {
    height: 40, // Set a specific height
  },
}));
const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  transform: "translate(14px, 9px) scale(1)",
  "&.MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(0.75)",
  },
}));
export default function DieselGenset() {
  const [gensetData, setGensetData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [chartData, setChartData] = useState([]);
  const location = useLocation();
  const [latestKva, setLatestKva] = useState(null);
  const [latestFuelConsumed, setLatestFuelConsumed] = useState(null);
  const [latestActivePower, setLatestActivePower] = useState(null);
  const [latestOilPressure, setLatestOilPressure] = useState(null);
  const [engineSpeed, setEngineSpeed] = useState(null); // Added state for engine speed
  const [dataType, setDataType] = useState("shift");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");

  const [initialLoad, setInitialLoad] = useState(true);
  const [genset, setGenset] = React.useState("");
  const [selectedGenset, setSelectedGenset] = useState(null);
  const [locationData, setLocationData] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [deviceNos, setDeviceNos] = useState([]);
  const [dieselGenset1, setDieselGenset1] = useState("");
  const [dieselGenset2, setDieselGenset2] = useState("");
  const [deviceNames, setDeviceNames] = useState([]);
  const [deviceNo, setDeviceNo] = useState("");

  const [energyDevice2, setEnergyDevice2] = useState("");
  const [selectedDevice, setSelectedDevice] = useState("");

  const navigate = useNavigate();
  const { locationId, locationName } = location.state || {};
  console.log("location id em:", locationId, "location name em:", locationName);

  useEffect(() => {
    const getDeivce = async () => {
      try {
        const result = await apiGetDevice();
        console.log("location data:", result.data.data); // Log the data property
        setLocationData(result.data.data); // Set plantData to result.data
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getDeivce();
  }, [refreshData]);
  useEffect(() => {
    if (locationName && locationData.length > 0) {
      const filtered = locationData.filter(
        (device) => device.lineName === locationName
      );
      setFilteredDevices(filtered);
      if (filtered.length > 0) {
        const deviceNosList = filtered.map((device) => device.deviceNo);
        const deviceNamesList = filtered.map((device) => device.deviceName);

        setDeviceNos(deviceNosList); // Set all matching deviceNos for the initial fetch
        setDeviceNames(deviceNamesList); // Set all matching deviceNames for the initial fetch

        // Separate filtering logic to find specific energy devices
        const diesel1Device = filtered.find(
          (device) => device.deviceName === "Genset-1"
        );
        const diesel2Device = filtered.find(
          (device) => device.deviceName === "Genset-2"
        );

        setDieselGenset1(diesel1Device?.deviceNo || null);
        setDieselGenset2(diesel2Device?.deviceNo || null);
      }

      console.log("device no and name ,:", deviceNos, deviceNames);
      console.log(
        "dgenset device 1 ,dgenset device 2:",
        dieselGenset1,
        dieselGenset2
      );
    }
  }, [locationName, locationData]);

  const handleDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
    setDeviceNo(event.target.value);
  };

  const handleChartClick = (chartType) => {
    navigate("/enlarged-chart", {
      state: { locationId, chartType, dataType },
    });
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  console.log("location id em:", locationId);

  const fetchData = async (
    locationId,
    dataType,
    deviceNo,
    showLoader = false
  ) => {
    try {
      if (showLoader) {
        setIsLoading(true);
        setLoadingMessage(
          `Loading ${dataType.charAt(0).toUpperCase() + dataType.slice(1)}`
        );
      }
      const result = await apiGetGensetData(locationId, dataType, deviceNo);
      console.log("data sent diesel genset:", locationId, dataType, deviceNo);
      const chunkedData = result.data.data.slice(0, 10); // Get first 10 items
      setGensetData(chunkedData);
      const latestData = chunkedData[0];
      const latestKva = latestData.kva;
      const latestActivePower = latestData.activePower;
      const latestOilPressure = latestData.oilPressure;
      const latestFuelConsumed = latestData.powerGenerated;
      const engineSpeed = latestData.engineSpeed; // Capture engine speed
      setLatestKva(latestKva);
      setLatestActivePower(latestActivePower);
      setLatestOilPressure(latestOilPressure);
      setEngineSpeed(engineSpeed);
      setLatestFuelConsumed(latestFuelConsumed);
      console.log("chunkeddata d genset:", chunkedData);

      const xAxisDates = chunkedData.map((item) => {
        // Parse the dateTimeRecvd field to create a Date object
        const dateParts = item.dateTimeRecvd.split(" "); // Split date and time
        const datePart = dateParts[0];
        const timePart = dateParts[1];
        const [day, month, year] = datePart.split("-");
        const [hours, minutes, seconds] = timePart.split(":");

        // Create a new Date object with the extracted date and time parts
        const date = new Date(year, month - 1, day, hours, minutes, seconds);

        // Return the Date object
        return date;
      });
      console.log("newdate:", xAxisDates);
      const sortedData = chunkedData.sort((a, b) => {
        const dateA = new Date(
          a.dateTimeRecvd.split(" ")[0].split("-").reverse().join("-") +
            "T" +
            a.dateTimeRecvd.split(" ")[1]
        );
        const dateB = new Date(
          b.dateTimeRecvd.split(" ")[0].split("-").reverse().join("-") +
            "T" +
            b.dateTimeRecvd.split(" ")[1]
        );
        return dateA - dateB;
      });

      const xAxisLabels = sortedData.map((item) => {
        const dateParts = item.dateTimeRecvd.split(" ");
        const datePart = dateParts[0];
        const timePart = dateParts[1];
        const [day, month, year] = datePart.split("-");
        const [hours, minutes, seconds] = timePart.split(":");
        const date = new Date(year, month - 1, day, hours, minutes, seconds);
        return date.toLocaleString();
      });

      const updatedChartData = [
        {
          type: "line",
          xAxis: xAxisDates,
          data: chunkedData.map((item) => parseFloat(item.batteryVoltage)),
        },
        {
          type: "line",
          xAxis: xAxisDates,
          data: chunkedData.map((item) => parseFloat(item.current)),
        },
        {
          type: "bar",
          xAxis: xAxisLabels,
          data: chunkedData.map((item) => parseFloat(item.kwh)),
        },
        {
          type: "gauge",
          xAxis: xAxisDates,
          data: chunkedData.map((item) => parseFloat(item.engineSpeed)),
        },
        {
          type: "line",
          xAxis: xAxisDates,
          data: chunkedData.map((item) => parseFloat(item.voltage)),
        },
        {
          type: "line",
          xAxis: xAxisDates,
          data: chunkedData.map((item) => parseFloat(item.fuelLevel)),
        },
      ];
      console.log("updatedchartdata:", updatedChartData);
      setChartData(updatedChartData);
      if (showLoader) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching energy data:", error);
      if (showLoader) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (locationId) {
      fetchData(locationId, dataType, deviceNo, initialLoad);
      const intervalId = setInterval(
        () => fetchData(locationId, dataType, deviceNo),
        10000
      );
      return () => clearInterval(intervalId);
    }
  }, [locationId, dataType, deviceNo, initialLoad]);

  const handleButtonClick = (type) => {
    setDataType(type);
    setInitialLoad(true);
    fetchData(locationId, type, true);
    setInitialLoad(false);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3} display={"flex"}>
          <BackButton />
          <Typography variant="h5" gutterBottom>
            Diesel Genset
          </Typography>
        </Grid>
        {/* <Grid item xs={3} display={"flex"} flexDirection="column">
      
      <FormControl variant="outlined" fullWidth >
        <InputLabel id="genset-label">Select Genset</InputLabel>
        <Select
          labelId="genset-label"
          id="genset-select"
          value={genset}
          onChange={handleChange}
          label="Select Genset"
          
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Genset 1</MenuItem>
          <MenuItem  value={20}>Genset 2</MenuItem>
         
        </Select>
      </FormControl>
    </Grid> */}
        {/* <Grid item xs={7} container justifyContent="flex-end">
          {renderButtons(handleButtonClick, dataType)}
        </Grid> */}
        <Grid item xs={3}>
          <FormControl fullWidth variant="outlined" size="small">
            <StyledInputLabel id="device-select-label">
              Select Device
            </StyledInputLabel>
            <StyledSelect
              labelId="device-select-label"
              id="device-select"
              value={selectedDevice}
              onChange={handleDeviceChange}
              label="Select Device"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {dieselGenset1 && (
                <MenuItem value={dieselGenset1}>
                  Diesel Genset 1: {dieselGenset1}
                </MenuItem>
              )}
              {dieselGenset2 && (
                <MenuItem value={dieselGenset2}>
                  Diesel Genset 2: {dieselGenset2}
                </MenuItem>
              )}
            </StyledSelect>
          </FormControl>
        </Grid>

        <Grid item xs={6} container justifyContent="flex-end">
          {renderButtons(handleButtonClick, dataType)}
        </Grid>
        <Grid item xs={3}>
          <InfoBox label="kVAR" value={latestKva} />
        </Grid>
        <Grid item xs={3}>
          <InfoBox label="Active Power (kWH)" value={latestActivePower} />
        </Grid>
        <Grid item xs={3}>
          <InfoBox label="Fuel Consumed" value={latestFuelConsumed} />
        </Grid>
        <Grid item xs={3}>
          <InfoBox label="Oil Pressure (PSI)" value={latestOilPressure} />
        </Grid>
        {isLoading ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: "300px" }}
          >
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
              <Typography variant="h6" style={{ marginTop: "20px" }}>
                {loadingMessage}
              </Typography>
            </div>
          </Grid>
        ) : (
          chartData.map((chart, index) => (
            <Grid item xs={4} key={index}>
              <Paper
                elevation={3}
                style={{ padding: 20, backgroundColor: "rgba(3, 3, 62, 0.9)" }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  style={getChartTitleStyle(chart, index)}
                >
                  {getChartTitle(chart, index)}
                </Typography>
                {renderChart(chart, engineSpeed)}
              </Paper>
            </Grid>
          ))
        )}
      </Grid>
    </div>
  );
}

function getChartTitle(chart, index) {
  if (chart.type === "line") {
    switch (index) {
      case 0:
        return "Battery Voltage (V)";
      case 1:
        return "Current (AMP)";
      case 4:
        return "Voltage";
      case 5:
        return "Fuel Level (%)";
      default:
        return `Line Chart ${index + 1}`;
    }
  } else if (chart.type === "bar") {
    switch (index) {
      case 2:
        return "KWH";
      default:
        return `Bar Chart ${index + 1}`;
    }
  } else if (chart.type === "gauge") {
    switch (index) {
      case 3:
        return "Engine Speed (RPM)";
      default:
        return "Engine Speed (RPM)";
    }
  } else {
    return `Chart ${index + 1}`;
  }
}

function getChartTitleStyle(chart, index) {
  const defaultStyle = {
    color: "white",
    fontFamily: "Arial, sans-serif",
    marginBottom: "0px",
  };

  if (chart.type === "line") {
    switch (index) {
      case 0:
        return { ...defaultStyle, fontWeight: "bold" };
      case 1:
        return { ...defaultStyle, fontWeight: "bold" };
      case 4:
        return { ...defaultStyle, fontWeight: "bold" };
      case 5:
        return { ...defaultStyle, fontWeight: "bold" };

      default:
        return defaultStyle;
    }
  } else if (chart.type === "bar") {
    switch (index) {
      case 2:
        return { ...defaultStyle, fontWeight: "bold" };
      default:
        return defaultStyle;
    }
  } else if (chart.type === "gauge") {
    switch (index) {
      case 3:
        return { ...defaultStyle, fontWeight: "bold" };
      default:
        return defaultStyle;
    }
  } else {
    return defaultStyle;
  }
}

function renderChart(chart, engineSpeed) {
  console.log("chart data:", chart.data);
  if (chart.type === "bar") {
    return (
      <BarChart
        xAxis={[{ scaleType: "band", data: chart.xAxis }]}
        series={[{ data: chart.data }]}
        width={450}
        height={160}
      />
    );
  } else if (chart.type === "gauge") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <GaugeContainer
          width={200}
          height={128}
          startAngle={-110}
          endAngle={110}
          innerRadius={55}
        >
          <GaugeReferenceArc />
          <GaugeValueArc />
          <GaugePointer />
        </GaugeContainer>
        <Typography
          variant="body1"
          style={{ marginTop: "10px", textAlign: "center", color: "white" }}
        >
          {engineSpeed} RPM
        </Typography>
      </div>
    );
  } else {
    return (
      <LineChart
        xAxis={[{ scaleType: "time", data: chart.xAxis }]}
        series={[{ data: chart.data }]}
        width={450}
        height={160}
      />
    );
  }
}

function renderButtons(handleButtonClick, currentDataType) {
  const buttonLabels = ["Shift", "Day", "Week", "Month", "Quarter", "Year"];
  return buttonLabels.map((label, index) => (
    <Button
      key={index}
      variant="contained"
      style={{
        margin: "0px 2px",
        backgroundColor:
          currentDataType === label.toLowerCase()
            ? "rgba(3, 3, 62, 0.9)"
            : "gray",
      }}
      onClick={() => handleButtonClick(label.toLowerCase())}
    >
      {label}
    </Button>
  ));
}
