import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@mui/material';

const BackButton = () => {
  const goBack = () => {
    // Handle go back logic here
    window.history.back(); // Simulate browser back button behavior
  };

  return (
    <button onClick={goBack} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' ,padding:'0px',border:'0px'}}>
      <FontAwesomeIcon icon={faCircleArrowLeft} style={{ marginRight: '8px' ,fontSize:'24px',marginBottom:'10px',}} />
     
    </button>
  );
};

export default BackButton;
