import React, { useEffect, useState } from "react";
import { Button, Typography, CircularProgress, Box } from "@mui/material";
import { LineChart, BarChart } from "@mui/x-charts";
import { apiGetEnergyData } from "../api/api.getenergydata";
import ChartWithSlider from "./chartslider";
import { useLocation } from "react-router-dom";

export default function EnlargedChartPage() {
  const location = useLocation();
  const { locationId, chartType, dataType, chartName, clickedChart } = location.state || {};
  const [chartData, setChartData] = useState({ xAxis: [], data: [], type: chartType });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  console.log("locid,charttype,datatype,chartname:",locationId,chartType,dataType,chartName)
  const fetchData = async (locationId, dataType, append = false, chartType) => {
    try {
      setIsLoading(true);
      const result = await apiGetEnergyData(locationId, dataType);
      const fetchedData = result.data.data;
      
      // Filter the fetched data based on the chart name
      const filteredChartData = fetchedData.map(item => ({
        dateTimeRecvd: item.dateTimeRecvd,
        pf: parseFloat(item.pf) || 0 // Ensure pf is a valid number
      }));
      console.log("filtered data:",filteredChartData)
      const xAxisDates = filteredChartData.map((item) => {
        const [datePart, timePart] = item.dateTimeRecvd.split(" ");
        const [day, month, year] = datePart.split("-");
        const [hours, minutes, seconds] = timePart.split(":");
        return new Date(year, month - 1, day, hours, minutes, seconds);
      });

      const newChartData = {
        xAxis: xAxisDates,
        type: chartType,
        data: filteredChartData.map((item) => item.pf)
      };

      setChartData(newChartData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching energy data:", error);
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    if (locationId && chartType) {
      fetchData(locationId, dataType, false, chartType);
      const intervalId = setInterval(() => fetchData(locationId, dataType, false, chartType), 100000);
      return () => clearInterval(intervalId);
    }
  }, [locationId, dataType, chartType]);

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
    fetchData(locationId, dataType, true, chartType);
  };

  const renderChart = (chartData) => {
    if (chartData.type === "bar") {
      return (
        <BarChart
          xAxis={[{ scaleType: "band", data: chartData.xAxis }]}
          series={[{ data: chartData.data }]}
          width={900}
          height={400}
        />
      );
    } else {
      return (
        <LineChart
          xAxis={[{ scaleType: "time", data: chartData.xAxis }]}
          series={[{ data: chartData.data }]}
          width={900}
          height={400}
        />
      );
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        {chartName} Data
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '20px',
          marginBottom: '20px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          backgroundColor:'rgba(3, 3, 62, 0.9)'
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          renderChart(chartData)
        )}
      </Box>
      <Button variant="contained" onClick={handleLoadMore} disabled={isLoading}>
        Load More
      </Button>
    </div>
  );
}