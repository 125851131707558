import * as React from 'react';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Gauge } from '@mui/x-charts/Gauge';

export default function BasicGauges() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Determine gauge width and height based on screen size
  const gaugeWidth = isSmallScreen ? 100 : 150;
  const gaugeHeight = isSmallScreen ? 100 : 150;

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 3 }}>
      <Gauge width={gaugeWidth} height={gaugeHeight} value={60} />
      <Gauge
        width={gaugeWidth}
        height={gaugeHeight}
        value={60}
        startAngle={-90}
        endAngle={90}
      />
    </Stack>
  );
}
