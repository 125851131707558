// import {
//   Box,
//   Button,
//   FormControl,
//   InputLabel,
//   IconButton,
//   MenuItem,
//   Modal,
//   Select,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
//   TextField,
//   tableCellClasses,
//   styled,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus } from "@fortawesome/free-solid-svg-icons";
// import { apiLineMaster } from "../api/api.addline";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import { apigetLines } from "../api/api.getline";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
// import { apiUpdateLine } from "../api/api.updateline";
// import { apiDeleteLine } from "../api/api.deleteline";
// import { apiGetPlant } from "../api/api.getplant";

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: "#1FAEC5",
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));

// export default function LineMaster() {
//   const [linedata, setLinedata] = useState([]);

//   const [addOpen, setAddOpen] = useState(false);
//   const [updateOpen, setUpdateOpen] = useState(false);

//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [severity, setSeverity] = useState("success");
//   const [refreshData, setRefreshData] = useState(false);
//   const [updatedLineData, setUpdatedLineData] = useState({
//     lineName: "",
//     plantNo: "",
//     segment: "",
//   });
//   const [error, setError] = useState(null);
//   const [plantData, setPlantData] = useState([]);

//   // const [updatedPlantData,setUpdatedPlantData]=useState("")

//   const handleAddSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       const result = await apiLineMaster(
//         updatedLineData.plantNo,
//         updatedLineData.lineName,
//         updatedLineData.segment
//       );
//       handleSnackbarOpen("Line added successfully!", "success");
//       console.log("response", result.data);
//       setUpdatedLineData({ lineName: "", plantNo: "", segment: "" });
//       setAddOpen(false);
//       setRefreshData((prev) => !prev);
//     } catch (error) {
//       console.error("Error adding Line:", error);
//       handleSnackbarOpen("Error adding Line. Please try again.", "error");
//     }
//   };
//   const handleEditSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       const result = await apiUpdateLine(
//         updatedLineData
//       );
//       console.log("response", result.data);
//       handleSnackbarOpen("Line Updated successfully!", "success");
//       setRefreshData((prev) => !prev);
//       setUpdateOpen(false);
//     } catch (error) {
//       console.error("Error updating Line:", error);
//       handleSnackbarOpen("Error updating Line. Please try again.", "error");
//     }
//   };

//   useEffect(() => {
//     const getlines = async () => {
//       try {
//         const result = await apigetLines();
//         console.log(result.data.data);
//         setLinedata(result.data.data);
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getlines();
//   }, [refreshData]);

//   useEffect(() => {
//     const getPlant = async () => {
//       try {
//         const result = await apiGetPlant();
//         console.log("Result data plant:", result.data.data); // Log the data property
//         setPlantData(result.data.data); // Set plantData to result.data
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getPlant();
//   }, [refreshData]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     console.log(e.target.value);
//     setUpdatedLineData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };
//   const handleEditClick = (row) => {
//     setUpdatedLineData(row);
//     setUpdateOpen(true);
//   };
//   const handleDelete = async (id) => {
//     console.log("val:", id);
//     try {
//       const result = await apiDeleteLine(id.lineNo);
//       console.log("response", result.data);
//       handleSnackbarOpen("Plant Deleted successfully!", "success");
//       setRefreshData((prev) => !prev);
//     } catch (error) {
//       console.error("Error deleting Plant:", error);
//       handleSnackbarOpen("Error deleting Plant. Please try again.", "error");
//     }
//   };
//   const handleSnackbarOpen = (message, severity) => {
//     setSnackbarMessage(message);
//     setSeverity(severity);
//     setOpenSnackbar(true);
//   };
//   return (
//     <div style={{ padding: "100px 20px" }}>
//       <div
//         style={{
//           display: "flex",
//           width: "100%",
//           alignItems: "center",
//           justifyContent: "space-between",
//           paddingTop: "5px",
//           paddingBottom: "5px",
//         }}
//       >
//         <h2>Line Master</h2>
//         <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
//           <Button
//             onClick={() => setAddOpen(true)}
//             style={{
//               fontWeight: "500",
//               borderRadius: "4px",
//               color: "gray",
//               border: "2px solid gray",
//               padding: "5px",
//               marginBottom: "5px",
//             }}
//           >
//             {" "}
//             Add New &nbsp;{" "}
//             <FontAwesomeIcon
//               style={{ fontSize: "18px", color: "gray" }}
//               icon={faPlus}
//             />
//           </Button>
//         </div>
//       </div>
//       <Box>

//         <Table
//           size="small"
//           style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
//         >
//           <TableHead>
//             <TableRow>
//               <StyledTableCell className="table-cell ">
//                 Line Name
//               </StyledTableCell>
//               <StyledTableCell className="table-cell">
//                 Plant Name
//               </StyledTableCell>
//               <StyledTableCell className="table-cell">
//                 Created Date
//               </StyledTableCell>
//               <StyledTableCell className="table-cell">Segment</StyledTableCell>
//               <StyledTableCell className="table-cell">Action</StyledTableCell>

//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {linedata.map((row, index) => (
//               <StyledTableRow key={index}>
//                 <StyledTableCell className="table-cell">
//                   {row.lineName}
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   {row.plantName}
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   {row.createdAt}
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   {row.segment}
//                 </StyledTableCell>
//                 <StyledTableCell  style={{ display: "flex", gap: "10px",alignItems:"center" , justifyContent:"space-evenly" }}  className="table-cell">
//                   <IconButton onClick={() => handleEditClick(row)}>
//                     <EditIcon />
//                   </IconButton>
//                   <div className="divider" style={{ height: "20px", width: "2px", backgroundColor: "#0003" }} ></div>
//                   <IconButton
//                     style={{ color: "#FF3131" }}
//                     onClick={() => handleDelete(row)}
//                   >
//                     <DeleteIcon />
//                   </IconButton>
//                 </StyledTableCell>
//               </StyledTableRow>
//             ))}
//           </TableBody>
//         </Table>
//         <Modal open={addOpen} onClose={() => setAddOpen(false)}>
//           <div
//             style={{
//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               backgroundColor: "white",
//               padding: "20px",
//               minWidth: "500px",
//             }}
//           >
//              <button
//       onClick={() => setAddOpen(false)}
//       style={{
//         position: "absolute",
//         top: "10px",
//         right: "10px",
//         cursor: "pointer",
//         backgroundColor: "transparent",
//         border: "none",
//         fontSize: "30px",
//       }}
//     >
//       &times;
//     </button>
//             <h2>Add New Line</h2>
//             <hr />
//             <br />
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <FormControl sx={{ width: "52ch" }}>
//                 <InputLabel>Plant Name</InputLabel>
//                 <Select
//                   name="plantNo"
//                   value={updatedLineData.plantNo}
//                   onChange={handleInputChange}
//                 >
//                   {plantData.map((plant) => (
//                     <MenuItem key={plant.id} value={plant.plantNo}>
//                       {plant.plantName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </div>
//             <div>
//               <TextField
//                 label="Line Name "
//                 name="lineName"
//                 value={updatedLineData.lineName}
//                 onChange={handleInputChange}
//                 style={{
//                   marginRight: "10px",
//                   width: "28rem",
//                   marginBottom: "10px",
//                 }}
//               />
//             </div>
//             <div>
//               <TextField
//                 value={updatedLineData.segment}
//                 name="segment"
//                 onChange={handleInputChange}
//                 label="Segment "
//                 style={{ width: "28rem" }}
//               />
//             </div>

//             <Button
//               onClick={handleAddSubmit}
//               variant="contained"
//               color="primary"
//               style={{ marginTop: "20px" }}
//             >
//               Add
//             </Button>
//           </div>
//         </Modal>
//         <Modal open={updateOpen} onClose={handleModalClose}>
//           <div
//             style={{
//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               backgroundColor: "white",
//               padding: "20px",
//               minWidth: "500px",
//             }}
//           >
//               <button
//       onClick={() => setUpdateOpen(false)}
//       style={{
//         position: "absolute",
//         top: "10px",
//         right: "10px",
//         cursor: "pointer",
//         backgroundColor: "transparent",
//         border: "none",
//         fontSize: "30px",
//       }}
//     >
//       &times;
//     </button>
//             <h2>Update Line</h2>
//             <hr />
//             <br />
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <FormControl sx={{ width: "52ch" }}>
//                 <InputLabel>Plant Name</InputLabel>
//                 <Select
//                   name="plantNo"
//                   value={updatedLineData.plantNo}
//                   onChange={handleInputChange}
//                 >
//                   {plantData.map((plant) => (
//                     <MenuItem key={plant.id} value={plant.plantNo}>
//                       {plant.plantName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </div>
//             <div>
//               <TextField
//                 label="Line Name "
//                 name="lineName"
//                 value={updatedLineData.lineName}
//                 onChange={handleInputChange}
//                 style={{
//                   marginRight: "10px",
//                   width: "28rem",
//                   marginBottom: "10px",
//                 }}
//               />
//             </div>
//             <div>
//               <TextField
//                 value={updatedLineData.segment}
//                 name="segment"
//                 onChange={handleInputChange}
//                 label="Segment "
//                 style={{ width: "28rem" }}
//               />
//             </div>
//             <Button
//               onClick={handleEditSubmit}
//               variant="contained"
//               color="primary"
//               style={{ marginTop: "20px" }}
//             >
//               Update
//             </Button>
//           </div>
//         </Modal>
//       </Box>
//       <Snackbar
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={() => setOpenSnackbar(false)}
//       >
//         <MuiAlert
//           onClose={() => setOpenSnackbar(false)}
//           severity={severity}
//           sx={{ width: "100%" }}
//         >
//           {snackbarMessage}
//         </MuiAlert>
//       </Snackbar>
//     </div>
//   );
// }

//Above working//

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  tableCellClasses,
  styled,
  TablePagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { apiLineMaster } from "../api/api.addline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { apigetLines } from "../api/api.getline";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { apiUpdateLine } from "../api/api.updateline";
import { apiDeleteLine } from "../api/api.deleteline";
import { apiGetPlant } from "../api/api.getplant";
import DeleteConfirmationModal from "./deletemodal";
import { Skeleton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function LineMaster() {
  const [linedata, setLinedata] = useState([]);

  const [addOpen, setAddOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [refreshData, setRefreshData] = useState(false);
  const [updatedLineData, setUpdatedLineData] = useState({
    lineName: "",
    plantNo: "",
    segment: "",
  });
  const [error, setError] = useState(null);
  const [plantData, setPlantData] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteLineId, setDeleteLineId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // const [updatedPlantData,setUpdatedPlantData]=useState("")

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await apiLineMaster(
        updatedLineData.plantNo,
        updatedLineData.lineName,
        updatedLineData.segment
      );
      handleSnackbarOpen("Line added successfully!", "success");
      console.log("response", result.data);
      setUpdatedLineData({ lineName: "", plantNo: "", segment: "" });
      setAddOpen(false);
      setRefreshData((prev) => !prev);
    } catch (error) {
      console.error("Error adding Line:", error);
      handleSnackbarOpen("Error adding Line. Please try again.", "error");
    }
  };
  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await apiUpdateLine(updatedLineData);
      console.log("response", result.data);
      handleSnackbarOpen("Line Updated successfully!", "success");
      setRefreshData((prev) => !prev);
      setUpdateOpen(false);
    } catch (error) {
      console.error("Error updating Line:", error);
      handleSnackbarOpen("Error updating Line. Please try again.", "error");
    }
  };

  useEffect(() => {
    const getlines = async () => {
      try {
        const result = await apigetLines();
        console.log(result.data.data);
        setLinedata(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getlines();
  }, [refreshData]);

  useEffect(() => {
    const getPlant = async () => {
      try {
        const result = await apiGetPlant();
        console.log("Result data plant:", result.data.data); // Log the data property
        setPlantData(result.data.data); // Set plantData to result.data
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getPlant();
  }, [refreshData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target.value);
    setUpdatedLineData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleEditClick = (row) => {
    setUpdatedLineData(row);
    setUpdateOpen(true);
  };
  const handleDelete = async (id) => {
    console.log("val:", id);
    try {
      const result = await apiDeleteLine(id.lineNo);
      console.log("response", result.data);
      handleSnackbarOpen("Plant Deleted successfully!", "success");
      setRefreshData((prev) => !prev);
    } catch (error) {
      console.error("Error deleting Plant:", error);
      handleSnackbarOpen("Error deleting Plant. Please try again.", "error");
    }
  };
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleDeleteClick = (row) => {
    setDeleteLineId(row.lineNo);
    setDeleteModalOpen(true);
  };
  const handleConfirmDelete = async () => {
    try {
      await apiDeleteLine(deleteLineId);
      handleSnackbarOpen("Plant Deleted successfully!", "success");
      setRefreshData((prev) => !prev);
    } catch (error) {
      console.error("Error deleting Plant:", error);
      handleSnackbarOpen("Error deleting Plant. Please try again.", "error");
    } finally {
      setDeleteModalOpen(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, linedata.length - page * rowsPerPage);
  const handleModalClose = () => {
    // Reset the form data
    setUpdatedLineData({
      lineName: "",
      plantNo: "",
      segment: "",
    });

    setAddOpen(false);
    setUpdateOpen(false);
  };

  return (
    <div style={{ padding: "0px 20px" }}>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
      >
        <h2>Line Master</h2>
        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          <Button
            onClick={() => setAddOpen(true)}
            style={{
              fontWeight: "500",
              borderRadius: "4px",
              color: "gray",
              border: "2px solid gray",
              padding: "5px",
              marginBottom: "5px",
            }}
          >
            {" "}
            Add New &nbsp;{" "}
            <FontAwesomeIcon
              style={{ fontSize: "18px", color: "gray" }}
              icon={faPlus}
            />
          </Button>
        </div>
      </div>
      <Box style={{ width: "100%", overflowX: "auto" }}>
        <TableContainer component={Paper}>
          <Table
            size="small"
            style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell className="table-cell ">
                  Plant Name
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Line Name
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Segment
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Create Date
                </StyledTableCell>
                <StyledTableCell className="table-cell">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {linedata.length === 0
                ? // Render skeleton loaders when data is still loading
                  Array.from(Array(5).keys()).map((index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        <Skeleton animation="wave" />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Skeleton animation="wave" />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                : linedata
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell className="table-cell">
                          {row.plantName}
                        </StyledTableCell>
                        <StyledTableCell className="table-cell">
                          {row.lineName}
                        </StyledTableCell>
                        <StyledTableCell className="table-cell">
                          {row.segment}
                        </StyledTableCell>
                        <StyledTableCell className="table-cell">
                          {row.createdAt}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                          className="table-cell"
                        >
                          <IconButton onClick={() => handleEditClick(row)}>
                            <EditIcon />
                          </IconButton>
                          <div
                            className="divider"
                            style={{
                              height: "20px",
                              width: "2px",
                              backgroundColor: "#0003",
                            }}
                          ></div>
                          <IconButton
                            style={{ color: "#FF3131" }}
                            onClick={() => handleDeleteClick(row)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 53 }}>
                  <StyledTableCell colSpan={8} style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                      }}
                    >
                      {`No further data available`}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={linedata.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Modal open={addOpen} onClose={() => setAddOpen(false)}>
          <div
            style={{
              borderRadius: "10px",

              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              minWidth: "500px",
            }}
          >
            <button
              onClick={handleModalClose}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "30px",
              }}
            >
              &times;
            </button>
            <h2>Add New Line</h2>
            <hr />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "52ch" }}>
                <InputLabel>Plant Name</InputLabel>
                <Select
                  name="plantNo"
                  value={updatedLineData.plantNo}
                  onChange={handleInputChange}
                >
                  {plantData.map((plant) => (
                    <MenuItem key={plant.id} value={plant.plantNo}>
                      {plant.plantName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <TextField
                label="Line Name "
                name="lineName"
                value={updatedLineData.lineName}
                onChange={handleInputChange}
                style={{
                  marginRight: "10px",
                  width: "28rem",
                  marginBottom: "10px",
                }}
              />
            </div>
            <div>
              <TextField
                value={updatedLineData.segment}
                name="segment"
                onChange={handleInputChange}
                label="Segment "
                style={{ width: "28rem" }}
              />
            </div>

            <Button
              onClick={handleAddSubmit}
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              Add
            </Button>
          </div>
        </Modal>
        <DeleteConfirmationModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleConfirmDelete}
        />
        <Modal open={updateOpen} onClose={handleModalClose}>
          <div
            style={{
              borderRadius: "10px",

              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              minWidth: "500px",
            }}
          >
            <button
              onClick={handleModalClose}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "30px",
              }}
            >
              &times;
            </button>
            <h2>Update Line</h2>
            <hr />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "52ch" }}>
                <InputLabel>Plant Name</InputLabel>
                <Select
                  name="plantNo"
                  value={updatedLineData.plantNo}
                  onChange={handleInputChange}
                >
                  {plantData.map((plant) => (
                    <MenuItem key={plant.id} value={plant.plantNo}>
                      {plant.plantName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <TextField
                label="Line Name "
                name="lineName"
                value={updatedLineData.lineName}
                onChange={handleInputChange}
                style={{
                  marginRight: "10px",
                  width: "28rem",
                  marginBottom: "10px",
                }}
              />
            </div>
            <div>
              <TextField
                value={updatedLineData.segment}
                name="segment"
                onChange={handleInputChange}
                label="Segment "
                style={{ width: "28rem" }}
              />
            </div>
            <Button
              onClick={handleEditSubmit}
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              Update
            </Button>
          </div>
        </Modal>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
