import React from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import '../assets/css/emanagement.css';
import BackButton from './backbutton';
import { useNavigate } from 'react-router-dom';

const chartData = [
  // Combined Voltage (3 phases)
  { 
    type: 'line', 
    xAxis: [1, 2, 3, 5, 8, 10], 
    data: [
      { name: 'Voltage Phase 1', data: [2, 5.5, 2, 8.5, 1.5, 5] },
      { name: 'Voltage Phase 2', data: [8, 2.5, 6, 4.5, 9.5, 6] },
      { name: 'Voltage Phase 3', data: [4, 7.5, 9, 10.5, 2.5, 2] }
    ], 
    label: 'Voltage (V)' 
  },
  
  // Combined Current (3 phases)
  { 
    type: 'line', 
    xAxis: [1, 2, 3, 4, 5, 6], 
    data: [
      { name: 'Current Phase 1', data: [3, 4, 9, 2, 9, 7] },
      { name: 'Current Phase 2', data: [8, 1, 7, 4, 3, 8] },
      { name: 'Current Phase 3', data: [5, 9, 2, 10, 6, 9] }
    ], 
    label: 'Current (AMP)' 
  },
  
  // Bar chart data
  { 
    type: 'bar', 
    xAxis: ['group A', 'group B', 'group C'], 
    data: [0,0,0], 
    label: 'Power Factor' 
  },
  
  // PF
  { 
    type: 'line', 
    xAxis: [1, 2, 3, 4, 5, 6], 
    data: [{ name: 'PF', data: [0,0,0,0,0,0] }], 
    label: 'Power Factor' 
  }
];

export default function JSPLEManagement() {
  const navigate=useNavigate()
  const handleVibrationClick = () => {
    navigate('/jsplvibration');
  };

  return (
    <div style={{ padding: '20px' }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5} display={'flex'}>
          <BackButton />
          <Typography variant="h5" gutterBottom>
          Energy Managmenet
          </Typography>
        </Grid>
        <Grid item xs={7} container justifyContent="flex-end">
          {renderAdditionalButtons(handleVibrationClick)}
        </Grid>

        {/* <Grid item xs={12} container justifyContent="flex-start" style={{ marginTop: 10 }}>
          {renderAdditionalButtons()}
        </Grid> */}

        {chartData.map((chart, index) => (
          <Grid item xs={4} key={index}>
            <Paper elevation={3} style={{ padding: 20, backgroundColor: 'rgba(3, 3, 62, 0.9)' }}>
              <Typography variant="h6" gutterBottom style={getChartTitleStyle(chart, index)}>
                {chart.label}
              </Typography>
              {renderChart(chart)}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

function getChartTitleStyle(chart, index) {
  const defaultStyle = {
    color: 'white',
    fontFamily: 'Arial, sans-serif',
    fontWeight: 'bold'
  };

  return defaultStyle;
}

function renderChart(chart) {
  const colors = ['blue', 'red', 'yellow'];
  console.log("chart data;",chartData)
  if (chart.type === 'bar') {
    return (
      <BarChart
        xAxis={[{ scaleType: 'band', data: chart.xAxis }]}
        series={[{ data: chart.data }]}
        width={450}
        height={200}
      />
    );
  } else {
    return (
      <LineChart
        xAxis={[{ data: chart.xAxis }]}
        series={chart.data.map((series, index) => ({
          ...series,
          color: colors[index % colors.length]
        }))}
        width={450}
        height={200}
      />
    );
  }
}

function renderButtons() {
  const buttonLabels = ['Shift', 'Day', 'Week', 'Month', 'Quarter', 'Year'];

  return buttonLabels.map((label, index) => (
    <Button key={index} variant="contained" style={{ margin: '0 5px' }}>
      {label}
    </Button>
  ));
}

function renderAdditionalButtons(handleVibrationClick) {
  const additionalButtonLabels = ['Machine Name :1', 'Sensor:1', 'Vibration'];

  return additionalButtonLabels.map((label, index) => (
    <Button 
    key={index} 
    variant="contained" 
    style={{ margin: '0 5px', backgroundColor: label === 'Vibration' ? 'blue' : 'gray', color: label === 'Vibration' ? 'white' : 'black' }} 
    disabled={label !== 'Vibration'} 
    onClick={label === 'Vibration' ? handleVibrationClick : undefined}
  >
      {label}
    </Button>
  ));
}
