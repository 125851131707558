// import React from "react";
// import { Grid, Paper, Typography } from "@mui/material";
// import Chart from "react-apexcharts";
// import solarimg from "../assets/images/sunenergy.jpg";
// import evimg from "../assets/images/ev2.jpg";
// import wmimg from "../assets/images/watermanagement.jpg";
// import stp from "../assets/images/stp.jpg";
// import fooddecomp from "../assets/images/fooddecom.jpg";
// // import { BarChart } from "@mui/x-charts/BarChart";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableRow,
// } from "@mui/material";
// import "../assets/css/facilitysus.css";
// import {
//   GaugeContainer,
//   GaugeValueArc,
//   GaugeReferenceArc,
//   useGaugeState,
// } from "@mui/x-charts/Gauge";
// import GaugeWithPointer from "./gaugewithpointer";
// import Stack from "@mui/material/Stack";
// import { styled, useTheme } from "@mui/material/styles";
// import { useMediaQuery } from "@mui/material";
// import BackButton from "./backbutton";
// // import { Bar } from "react-chartjs-2";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";

// const StyledBarChart = styled(BarChart)(({ theme }) => ({
//   "& .MuiBar-root rect": {
//     x: 0,
//     y: -0,
//     width: 20,
//     height: 20,
//     fill: "rgb(46, 150, 255)",
//   },
//   "& .MuiBarLabel-root": {
//     fill: "white",
//     color: "white",
//   },
// }));
// export default function FacilitySustainability() {
//   const location = useLocation();
//   const { place, locationData, locationId } = location.state || {};
//   // console.log(place, "from prev", location.state,);

//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
//   const navigate = useNavigate();
//   // Determine gauge width and height based on screen size
//   const gaugeWidth = isSmallScreen ? 100 : 150;
//   const gaugeHeight = isSmallScreen ? 100 : 150;

//   function GaugePointer() {
//     const { valueAngle, outerRadius, cx, cy } = useGaugeState();

//     if (valueAngle === null) {
//       // No value to display
//       return null;
//     }

//     const target = {
//       x: cx + outerRadius * Math.sin(valueAngle),
//       y: cy - outerRadius * Math.cos(valueAngle),
//     };
//     return (
//       <g>
//         <circle cx={cx} cy={cy} r={5} fill="red" />
//         <path
//           d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
//           stroke="red"
//           strokeWidth={3}
//         />
//       </g>
//     );
//   }
//   const {
//     waterFlow_1,
//     temperature_1,
//     humidity_1,
//     co2_1,
//     voltageR_1,
//     currentR_1,
//     fuelLevel_1,
//     fuelLevel_2,
//     dg_1,
//     dg_2,
//     pf_1,
//     waterFlow_2,
//     temperature_2,
//     humidity_2,
//     co2_2,
//     voltageR_2,
//     currentR_2,
//     gKwh_2,
//     gKwh_1,
//     kwh_1,
//     kwh_2,
//     pf_2,
//   } = locationData[0];
//   const fl1 = 50;
//   const fl2 = 90;
//   // const data = {
//   //   labels: ["F L 1", "F L 2"],
//   //   datasets: [
//   //     {
//   //       label: "Fuel Level 1",
//   //       data: [fl1], // Make sure fuelLevel1 is defined
//   //       backgroundColor: "rgba(255, 99, 132, 0.2)", // Customize the background color
//   //       borderColor: "rgba(255, 99, 132, 1)", // Customize the border color
//   //       borderWidth: 1,
//   //     },
//   //     {
//   //       label: "Fuel Level 2",
//   //       data: [fl2], // Make sure fuelLevel2 is defined
//   //       backgroundColor: "rgba(54, 162, 235, 0.2)", // Customize the background color
//   //       borderColor: "rgba(54, 162, 235, 1)", // Customize the border color
//   //       borderWidth: 1,
//   //     },

//   //   ],
//   // };

//   const data = [
//     { name: "FL 1", FL1: fuelLevel_1 },
//     { name: "FL 2", FL2: fuelLevel_2 },
//   ];

//   const options = {
//     margin: { top: 5, right: 30, left: 20, bottom: 5 },
//   };

//   const handleEnegyManagementClick = () => {
//     navigate("/energymanagement", { state: { locationId } });
//   };
//   const handleGensetClick = () => {
//     navigate("/dieselgenset", { state: { locationId } });
//   };
//   const handleAirQaulityClick = () => {
//     navigate("/airquality", { state: { locationId } });
//   };
//   const handleWaterManagementClick = () => {
//     navigate("/watermanagement", { state: { locationId } });
//   };

//   return (
//     <div
//       style={{
//         padding: "0px 20px",
//         display: "flex",
//         flexDirection: "column",
//         width: "100%",
//         backgroundColor: "#E8E8E8",
//       }}
//     >
//       <Grid container>
//         <Grid item xs={5} display={"flex"} alignItems="center" mt={2}>
//           <BackButton />
//           <Typography variant="h5" gutterBottom>
//             {location?.state.place} Facility
//           </Typography>
//         </Grid>
//       </Grid>

//       <div style={{ marginTop: "20px" }}>
//         <Grid container spacing={3}>
//           <Grid item md={4} sm={4}>
//             <button
//               onClick={handleEnegyManagementClick}
//               style={{ cursor: "pointer", border: "0px", width: "100%" }}
//             >
//               <Paper sx={{ p: 2 }}>
//                 <Typography
//                   variant="h6"
//                   align="center"
//                   style={{
//                     fontSize: "16px",
//                     fontWeight: "600",
//                     color: "white",
//                   }}
//                 >
//                   Energy Management
//                 </Typography>
//                 <hr />
//                 <Grid container spacing={2}>
//                   <Grid item xs={6}>
//                     <GaugeContainer
//                       width={180}
//                       height={150}
//                       startAngle={-110}
//                       endAngle={110}
//                       value={kwh_1}
//                       aria-valuenow={kwh_1}
//                       valueMin={1000}
//                       valueMax={10000}
//                       aria-valuetext="current "
//                       innerRadius="65%"
//                       outerRadius="100%"
//                     >
//                       <GaugeReferenceArc />
//                       <GaugeValueArc />
//                       <GaugePointer />
//                     </GaugeContainer>
//                     <Typography
//                       variant="body2"
//                       align="center"
//                       style={{
//                         fontWeight: "600",
//                         color: "white",
//                         fontSize: "16px",
//                       }}
//                       className="engeryCurrentLabel"
//                     >
//                       {kwh_1} KWH
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={6}>
//                     <GaugeContainer
//                       width={180}
//                       height={150}
//                       startAngle={-110}
//                       endAngle={110}
//                       value={kwh_2}
//                       valueMin={0}
//                       valueMax={400}
//                       innerRadius="65%"
//                       outerRadius="100%"
//                     >
//                       <GaugeReferenceArc />
//                       <GaugeValueArc />
//                       <GaugePointer />
//                     </GaugeContainer>
//                     <Typography
//                       variant="body2"
//                       align="center"
//                       style={{
//                         fontWeight: "600",
//                         color: "white",
//                         fontSize: "16px",
//                       }}
//                     >
//                       {kwh_2} kWH
//                     </Typography>
//                   </Grid>

//                   <div
//                     style={{
//                       marginTop: "12px",
//                       display: "flex",
//                       // justifyContent: "center",
//                       alignItems: "center",
//                       width: "100%",
//                       gap: "5px",
//                       marginLeft: "8px",
//                     }}
//                   >
//                     {/* <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: "lightblue",
//                             padding: "0px 1px",
//                           }}
//                         >
//                           Current 1
//                         </Typography>
//                         <hr />
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: "white",
//                             color: "red",
//                           }}
//                         >
//                           {currentR_1} A
//                         </Typography>
//                       </Paper>
//                       <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: "lightblue",
//                             padding: "0px 1px",
//                           }}
//                         >
//                           Current 2
//                         </Typography>
//                         <hr />
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: "white",
//                             color: "red",
//                           }}
//                         >
//                           {currentR_1} A
//                         </Typography>
//                       </Paper>
//                       <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: "lightblue",
//                             padding: "0px 1px",
//                           }}
//                         >
//                           Voltage 1
//                         </Typography>
//                         <hr />
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: "white",
//                             color: "red",
//                             padding: "0px 1px",
//                           }}
//                         >
//                           {voltageR_1} V
//                         </Typography>
//                       </Paper>
//                       <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: "lightblue",
//                             padding: "0px 1px",
//                           }}
//                         >
//                           Voltage 2
//                         </Typography>
//                         <hr />
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: "white",
//                             color: "red",
//                             padding: "0px 1px",
//                           }}
//                         >
//                           {voltageR_2} V
//                         </Typography>
//                       </Paper>
//                       <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: "lightblue",
//                             padding: "0px 1px",
//                           }}
//                         >
//                           PF 1
//                         </Typography>
//                         <hr />
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: "white",
//                             color: "red",
//                             padding: "0px 1px",
//                           }}
//                         >
//                           {pf_1}
//                         </Typography>
//                       </Paper>{" "}
//                       <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: "lightblue",
//                             padding: "0px 1px",
//                           }}
//                         >
//                           PF 2
//                         </Typography>
//                         <hr />
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: "white",
//                             color: "red",
//                             padding: "0px 1px",
//                           }}
//                         >
//                           {pf_2}
//                         </Typography>
//                       </Paper> */}
//                     <Paper
//                       sx={{
//                         p: 2,
//                         backgroundColor: "#0001",
//                         width: "100%",
//                         marginTop: "20px",
//                         padding: "0",
//                       }}
//                     >
//                       <TableContainer component={Paper}>
//                         <Table>
//                           <TableBody>
//                             <TableRow>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   padding="none"
//                                   style={{
//                                     fontSize: "1.1rem",
//                                     fontWeight: "600",
//                                     background: "lightblue",
//                                     padding: "none",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   Current 1
//                                 </Typography>
//                               </TableCell>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   padding="none"
//                                   style={{
//                                     fontSize: "1.1rem",
//                                     fontWeight: "600",
//                                     background: "lightblue",
//                                     padding: "none",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   Current 2
//                                 </Typography>
//                               </TableCell>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   padding="none"
//                                   style={{
//                                     fontSize: "1.1rem",
//                                     fontWeight: "600",
//                                     background: "lightblue",
//                                     padding: "none",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   Voltage 1
//                                 </Typography>
//                               </TableCell>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   style={{
//                                     fontSize: "1.1rem",
//                                     fontWeight: "600",
//                                     background: "lightblue",
//                                     padding: "none",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   Voltage 2
//                                 </Typography>
//                               </TableCell>
//                               <TableCell align="center " padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   style={{
//                                     fontSize: "1.1rem",
//                                     fontWeight: "600",
//                                     background: "lightblue",
//                                     padding: "none",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   PF 1
//                                 </Typography>
//                               </TableCell>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   style={{
//                                     fontSize: "1.1rem",
//                                     fontWeight: "600",
//                                     background: "lightblue",
//                                     padding: "none",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   PF 2
//                                 </Typography>
//                               </TableCell>
//                             </TableRow>
//                             <TableRow>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   style={{
//                                     fontSize: "1.5rem",
//                                     fontWeight: "600",
//                                     background: "white",
//                                     color: "maroon",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   {currentR_1} A
//                                 </Typography>
//                               </TableCell>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   style={{
//                                     fontSize: "1.5rem",
//                                     fontWeight: "600",
//                                     background: "white",
//                                     color: "maroon",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   {currentR_2} A
//                                 </Typography>
//                               </TableCell>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   style={{
//                                     fontSize: "1.5rem",
//                                     fontWeight: "600",
//                                     background: "white",
//                                     color: "maroon",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   {voltageR_1} V
//                                 </Typography>
//                               </TableCell>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   style={{
//                                     fontSize: "1.5rem",
//                                     fontWeight: "600",
//                                     background: "white",
//                                     color: "maroon",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   {voltageR_2} V
//                                 </Typography>
//                               </TableCell>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   style={{
//                                     fontSize: "1.5rem",
//                                     fontWeight: "600",
//                                     background: "white",
//                                     color: "maroon",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   {pf_1}
//                                 </Typography>
//                               </TableCell>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   style={{
//                                     fontSize: "1.5rem",
//                                     fontWeight: "600",
//                                     background: "white",
//                                     color: "maroon",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   {pf_2}
//                                 </Typography>
//                               </TableCell>
//                             </TableRow>
//                           </TableBody>
//                         </Table>
//                       </TableContainer>
//                     </Paper>
//                   </div>
//                 </Grid>
//               </Paper>
//             </button>
//           </Grid>

//           <Grid item md={4.5} sm={4.5}>
//             <button
//               onClick={handleGensetClick}
//               style={{ border: "0px", cursor: "pointer", width: "100%" }}
//             >
//               <Paper sx={{ p: 2 }}>
//                 <Typography
//                   variant="h6"
//                   align="center"
//                   style={{
//                     fontSize: "16px",
//                     fontWeight: "600",
//                     color: "white",
//                   }}
//                 >
//                   Diesel Genset
//                 </Typography>
//                 <hr />
//                 <Grid container spacing={2}>
//                   <Grid item xs={6}>
//                     <GaugeContainer
//                       width={180}
//                       height={150}
//                       value={gKwh_1}
//                       startAngle={-110}
//                       endAngle={110}
//                       innerRadius="65%"
//                       outerRadius="100%"
//                       fill="#FF0000"
//                       valueMin={0}
//                       valueMax={2000}
//                     >
//                       <GaugeReferenceArc />
//                       <GaugeValueArc />
//                       <GaugePointer />
//                     </GaugeContainer>
//                     <Typography
//                       variant="body2"
//                       align="center"
//                       style={{
//                         fontWeight: "600",
//                         color: "white",
//                         fontSize: "16px",
//                       }}
//                       className="gensetEnergyLabel"
//                     >
//                       {gKwh_1} Energy(kwh1)
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={6}>
//                     <ResponsiveContainer width="100%" height={185}>
//                       <BarChart data={data} margin={options.margin}>
//                         <XAxis dataKey="name" tick={{ fill: "white" }} />
//                         <YAxis tick={{ fill: "white" }} />
//                         <Tooltip />
//                         <Legend />
//                         <Bar dataKey="FL1" fill="#8884d8" />
//                         <Bar dataKey="FL2" fill="#82ca9d" />
//                       </BarChart>
//                     </ResponsiveContainer>
//                     {/* <StyledBarChart
//                      className="custom-bar-chart"
//                       xAxis={[
//                         {
//                           scaleType: "band",
//                           data: ["F L 1", "F L 2"],
//                         },
//                       ]}
//                       series={[
//                         {
//                           data: [fuelLevel1],
//                           label: "Fuel Level ",
//                           id: "pvId",
//                         },
//                         {
//                           data: [fuelLevel2],
//                           // label: 'Fuel Level 2',
//                           id: "uvId",
//                         },
//                       ]}
//                       renderCustomXAxisLabels={(x, y, index) => (
//                         <text
//                           x={x}
//                           y={y}
//                           fill="red" // Change the color to your desired color
//                           fontSize={12} // Adjust font size if needed
//                           textAnchor="middle"
//                         >
//                           {["Fuel Level", "Fuel Level 2"][index]}
//                         </text>
//                       )}
//                       width={200}
//                       height={185}
//                     /> */}
//                   </Grid>
//                   <Grid
//                     container
//                     marginTop={2}
//                     justifyContent="center"
//                     alignItems="center"
//                     spacing={2}
//                   >
//                     <div
//                       style={{
//                         marginTop: "4px",
//                         display: "flex",
//                         justifyContent: "space-around",
//                         alignItems: "center",
//                         width: "100%",
//                       }}
//                     >
//                       {/* <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: "lightblue",
//                             padding:"none"
//                           }}
//                         >
//                           kwh1
//                         </Typography>
//                         <hr />
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: "white",
//                             color: "red",
//                             padding:"none"
//                           }}
//                         >
//                           {gKwh_1}
//                         </Typography>
//                       </Paper>
//                       <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: "lightblue",
//                             padding:"none"
//                           }}
//                         >
//                           kwh2
//                         </Typography>
//                         <hr />
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: "white",
//                             color: "red",
//                             padding:"none"
//                           }}
//                         >
//                           {gKwh_2}
//                         </Typography>
//                       </Paper>
//                       <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: "lightblue",
//                             padding: "0px 2px 0px 2px",
//                           }}
//                         >
//                           DG1
//                         </Typography>
//                         <hr />
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: dg_1 === "ON" ? "darkgreen" : "red",
//                             color: "white",
//                           }}
//                         >
//                           {dg_1}
//                         </Typography>
//                       </Paper>

//                       <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: "lightblue",
//                             padding: "0px 2px 0px 2px",
//                           }}
//                         >
//                           DG2
//                         </Typography>
//                         <hr />
//                         <Typography
//                           variant="body2"
//                           align="center"
//                           style={{
//                             fontSize: "0.9375rem",
//                             fontWeight: "600",
//                             background: dg_2 === "ON" ? "darkgreen" : "red",
//                             color: "white",
//                           }}
//                         >
//                           {dg_2}
//                         </Typography>
//                       </Paper> */}
//                       <Paper
//                         sx={{
//                           p: 2,
//                           backgroundColor: "#0001",
//                           width: "80%",
//                           // marginTop: "12px",
//                           padding: "0",
//                         }}
//                       >
//                         <TableContainer component={Paper}>
//                           <Table>
//                             <TableBody>
//                               <TableRow>
//                                 <TableCell align="center" padding="none">
//                                   <Typography
//                                     variant="body2"
//                                     padding="none"
//                                     style={{
//                                       fontSize: "1.1rem",
//                                       fontWeight: "600",
//                                       background: "lightblue",
//                                       padding: "none",
//                                       borderRight: "1px solid black",
//                                     }}
//                                   >
//                                     kwh 1
//                                   </Typography>
//                                 </TableCell>
//                                 <TableCell align="center" padding="none">
//                                   <Typography
//                                     variant="body2"
//                                     padding="none"
//                                     style={{
//                                       fontSize: "1.1rem",
//                                       fontWeight: "600",
//                                       background: "lightblue",
//                                       padding: "none",
//                                       borderRight: "1px solid black",
//                                     }}
//                                   >
//                                     kwh 2
//                                   </Typography>
//                                 </TableCell>
//                                 <TableCell align="center" padding="none">
//                                   <Typography
//                                     variant="body2"
//                                     padding="none"
//                                     style={{
//                                       fontSize: "1.1rem",
//                                       fontWeight: "600",
//                                       background: "lightblue",
//                                       padding: "none",
//                                       borderRight: "1px solid black",
//                                     }}
//                                   >
//                                     DG 1
//                                   </Typography>
//                                 </TableCell>
//                                 <TableCell align="center" padding="none">
//                                   <Typography
//                                     variant="body2"
//                                     style={{
//                                       fontSize: "1.1rem",
//                                       fontWeight: "600",
//                                       background: "lightblue",
//                                       padding: "none",
//                                       borderRight: "1px solid black",
//                                     }}
//                                   >
//                                     DG 2
//                                   </Typography>
//                                 </TableCell>
//                               </TableRow>
//                               <TableRow>
//                                 <TableCell align="center" padding="none">
//                                   <Typography
//                                     variant="body2"
//                                     style={{
//                                       fontSize: "1.5rem",
//                                       fontWeight: "600",
//                                       background: "white",
//                                       color: "maroon",
//                                       borderRight: "1px solid black",
//                                     }}
//                                   >
//                                     {gKwh_1}
//                                   </Typography>
//                                 </TableCell>
//                                 <TableCell align="center" padding="none">
//                                   <Typography
//                                     variant="body2"
//                                     style={{
//                                       fontSize: "1.5rem",
//                                       fontWeight: "600",
//                                       background: "white",
//                                       color: "maroon",
//                                       borderRight: "1px solid black",
//                                     }}
//                                   >
//                                     {gKwh_1}
//                                   </Typography>
//                                 </TableCell>
//                                 <TableCell
//                                   align="center"
//                                   padding="none"
//                                   style={{ borderRight: "1px solid black" }}
//                                 >
//                                   <Typography
//                                     variant="body2"
//                                     style={{
//                                       fontSize: "1.5rem",
//                                       fontWeight: "600",
//                                       background:
//                                         dg_1 === "ON" ? "darkgreen" : "red",
//                                       color: "maroon",
//                                       borderRight: "1px solid black",
//                                     }}
//                                   >
//                                     {dg_1}
//                                   </Typography>
//                                 </TableCell>
//                                 <TableCell align="center" padding="none">
//                                   <Typography
//                                     variant="body2"
//                                     style={{
//                                       fontSize: "1.5rem",
//                                       fontWeight: "600",
//                                       background:
//                                         dg_2 === "ON" ? "darkgreen" : "red",
//                                       color: "maroon",
//                                       borderRight: "1px solid black",
//                                     }}
//                                   >
//                                     {dg_2}
//                                   </Typography>
//                                 </TableCell>
//                               </TableRow>
//                             </TableBody>
//                           </Table>
//                         </TableContainer>
//                       </Paper>
//                     </div>
//                   </Grid>
//                 </Grid>
//               </Paper>
//             </button>
//           </Grid>

//           {/* Box 3: Air Quality */}
//           <Grid item md={3.5} sm={3.5}>
//             <button
//               onClick={handleAirQaulityClick}
//               style={{ cursor: "pointer", border: "0px" }}
//               className="AQButton"
//             >
//               <Paper
//                 style={{ width: "400px" }}
//                 sx={{ p: 2 }}
//                 className="airQualityBox"
//               >
//                 <Typography
//                   variant="h6"
//                   align="center"
//                   style={{
//                     fontSize: "16px",
//                     fontWeight: "600",
//                     color: "white",
//                   }}
//                 >
//                   Air Quality(CO2)
//                 </Typography>
//                 <hr />

//                 {/* Air Quality Chart */}
//                 <Grid
//                   display="flex"
//                   flexDirection="row"
//                   justifyContent="center"
//                   alignItems="center"
//                 >
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       alignItems: "center",
//                       margin: "0 10px",
//                     }}
//                   >
//                     <GaugeContainer
//                       width={180}
//                       height={150}
//                       startAngle={-110}
//                       endAngle={110}
//                       valueMin={0}
//                       valueMax={999}
//                       value={co2_1}
//                       innerRadius="65%"
//                       outerRadius="100%"
//                     >
//                       <GaugeReferenceArc
//                         style={{ color: "red", backgroundColor: "#FF0000" }}
//                       />
//                       <GaugeValueArc
//                         style={{ color: "red", backgroundColor: "#FF0000" }}
//                       />
//                       <GaugePointer />
//                     </GaugeContainer>
//                     <Typography
//                       variant="body2"
//                       align="center"
//                       style={{
//                         fontWeight: "600",
//                         color: "white",
//                         fontSize: "16px",
//                       }}
//                     >
//                       {co2_1} PPM
//                     </Typography>
//                   </div>

//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       alignItems: "center",
//                       margin: "0 10px",
//                     }}
//                   >
//                     <GaugeContainer
//                       width={180}
//                       height={150}
//                       startAngle={-110}
//                       endAngle={110}
//                       valueMin={0}
//                       valueMax={999}
//                       value={co2_2}
//                       innerRadius="65%"
//                       outerRadius="100%"
//                     >
//                       <GaugeReferenceArc
//                         style={{ color: "red", backgroundColor: "#FF0000" }}
//                       />
//                       <GaugeValueArc
//                         style={{ color: "red", backgroundColor: "#FF0000" }}
//                       />
//                       <GaugePointer />
//                     </GaugeContainer>
//                     <Typography
//                       variant="body2"
//                       align="center"
//                       style={{
//                         fontWeight: "600",
//                         color: "white",
//                         fontSize: "16px",
//                       }}
//                     >
//                       {co2_2} PPM
//                     </Typography>
//                   </div>
//                 </Grid>

//                 {/* Temperature and Humidity Boxes */}
//                 <Grid
//                   container
//                   marginTop={2}
//                   justifyContent="center"
//                   alignItems="center"
//                   spacing={2}
//                 >
//                   {/* <Grid item xs={3.5}> */}
//                   <div
//                     style={{
//                       marginTop: "0.9375rem",
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       width: "100%",
//                       gap: "10px",
//                     }}
//                   >
//                     {/* <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
//                       <Typography
//                         variant="body2"
//                         align="center"
//                         style={{
//                           fontSize: "0.9375rem", // Adjust font size based on screen size
//                           fontWeight: "600",
//                           background: "lightblue",
//                           padding: "0px 2px 0px 2px",
//                         }}
//                       >
//                         Temp 1
//                       </Typography>
//                       <hr />

//                       <Typography
//                         variant="body2"
//                         align="center"
//                         style={{
//                           fontSize: "0.9375rem",
//                           fontWeight: "600",
//                           background: "white",
//                           color: "red",
//                         }}
//                       >
//                         {temperature_1}°C
//                       </Typography>
//                     </Paper>
//                     <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
//                       <Typography
//                         variant="body2"
//                         align="center"
//                         style={{
//                           fontSize: "0.9375rem", // Adjust font size based on screen size
//                           fontWeight: "600",
//                           background: "lightblue",
//                           padding: "0px 2px 0px 2px",
//                         }}
//                       >
//                         Temp 2
//                       </Typography>
//                       <hr />

//                       <Typography
//                         variant="body2"
//                         align="center"
//                         style={{
//                           fontSize: "0.9375rem",
//                           fontWeight: "600",
//                           background: "white",
//                           color: "red",
//                         }}
//                       >
//                         {temperature_2}°C
//                       </Typography>
//                     </Paper>

//                     <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
//                       <Typography
//                         variant="body2"
//                         align="center"
//                         style={{
//                           fontSize: "0.9375rem",
//                           fontWeight: "600",
//                           background: "lightblue",
//                           padding: "0px 2px 0px 2px",
//                         }}
//                       >
//                         Humidity 1
//                       </Typography>
//                       <hr />

//                       <Typography
//                         variant="body2"
//                         align="center"
//                         style={{
//                           fontSize: "0.9375rem",
//                           fontWeight: "600",
//                           background: "white",
//                           color: "red",
//                         }}
//                       >
//                         {humidity_1}%
//                       </Typography>
//                     </Paper>
//                     <Paper sx={{ p: 1, backgroundColor: "#0001" }}>
//                       <Typography
//                         variant="body2"
//                         align="center"
//                         style={{
//                           fontSize: "0.9375rem",
//                           fontWeight: "600",
//                           background: "lightblue",
//                           padding: "0px 2px 0px 2px",
//                         }}
//                       >
//                         Humidity 2
//                       </Typography>
//                       <hr />

//                       <Typography
//                         variant="body2"
//                         align="center"
//                         style={{
//                           fontSize: "0.9375rem",
//                           fontWeight: "600",
//                           background: "white",
//                           color: "red",
//                         }}
//                       >
//                         {humidity_2}%
//                       </Typography>
//                     </Paper> */}
//                     <Paper
//                       sx={{
//                         p: 2,
//                         backgroundColor: "#0001",
//                         width: "90%",
//                         // marginTop: "12px",
//                         padding: "0",
//                       }}
//                     >
//                       <TableContainer component={Paper}>
//                         <Table>
//                           <TableBody>
//                             <TableRow>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   padding="none"
//                                   style={{
//                                     fontSize: "1.1rem",
//                                     fontWeight: "600",
//                                     background: "lightblue",
//                                     padding: "none",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   Temp 1
//                                 </Typography>
//                               </TableCell>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   padding="none"
//                                   style={{
//                                     fontSize: "1.1rem",
//                                     fontWeight: "600",
//                                     background: "lightblue",
//                                     padding: "none",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   Temp 2
//                                 </Typography>
//                               </TableCell>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   padding="none"
//                                   style={{
//                                     fontSize: "1.1rem",
//                                     fontWeight: "600",
//                                     background: "lightblue",
//                                     padding: "none",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   Humidity 1
//                                 </Typography>
//                               </TableCell>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   style={{
//                                     fontSize: "1.1rem",
//                                     fontWeight: "600",
//                                     background: "lightblue",
//                                     padding: "none",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   Humidity 2
//                                 </Typography>
//                               </TableCell>
//                             </TableRow>
//                             <TableRow>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   style={{
//                                     fontSize: "1.5rem",
//                                     fontWeight: "600",
//                                     background: "white",
//                                     color: "maroon",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   {temperature_1}°C
//                                 </Typography>
//                               </TableCell>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   style={{
//                                     fontSize: "1.5rem",
//                                     fontWeight: "600",
//                                     background: "white",
//                                     color: "maroon",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   {temperature_2}°C
//                                 </Typography>
//                               </TableCell>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   style={{
//                                     fontSize: "1.5rem",
//                                     fontWeight: "600",
//                                     background: "white",
//                                     color: "maroon",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   {humidity_1}%
//                                 </Typography>
//                               </TableCell>
//                               <TableCell align="center" padding="none">
//                                 <Typography
//                                   variant="body2"
//                                   style={{
//                                     fontSize: "1.5rem",
//                                     fontWeight: "600",
//                                     background: "white",
//                                     color: "maroon",
//                                     borderRight: "1px solid black",
//                                   }}
//                                 >
//                                   {humidity_2}%
//                                 </Typography>
//                               </TableCell>
//                             </TableRow>
//                           </TableBody>
//                         </Table>
//                       </TableContainer>
//                     </Paper>
//                   </div>
//                   {/* </Grid> */}
//                 </Grid>
//               </Paper>
//             </button>
//           </Grid>
//         </Grid>
//         <Grid
//           marginTop={2}
//           container
//           display={"flex"}
//           justifyContent={"space-between"}
//           alignItems={"center"}
//         >
//           {/* Replace '/your-path' with your desired URL */}
//           <Grid
//             xs={2.3}
//             backgroundColor="rgba(3, 3, 62, 0.9 )"
//             p={1}
//             borderRadius={"5px"}
//           >
//             <Link to="https://monitoring.solaredge.com/solaredge-web/p/login?locale=en_GB-path">
//               <Paper>
//                 {/* Image inside Paper component */}
//                 <Typography
//                   variant="body2"
//                   align="center"
//                   style={{
//                     fontSize: "16px",
//                     fontWeight: "600",
//                     color: "white",
//                     background: "rgba(3, 3, 62, 0.9 )",
//                   }}
//                 >
//                   Solar
//                 </Typography>

//                 <img
//                   src={solarimg} // Use the imported image
//                   alt="Local Image"
//                   style={{ width: "180px", height: "180px", display: "block" }}
//                 />
//               </Paper>
//             </Link>
//           </Grid>
//           {/* Empty Box 5 */}
//           <Grid
//             xs={2.3}
//             backgroundColor="rgba(3, 3, 62, 0.9 )"
//             p={1}
//             borderRadius={"5px"}
//           >
//             <Link to="http://cms.kazam.in/auth/login">
//               <Paper>
//                 {/* Image inside Paper component */}
//                 <Typography
//                   variant="body2"
//                   align="center"
//                   style={{
//                     fontSize: "16px",
//                     fontWeight: "600",
//                     color: "white",
//                     background: "rgba(3, 3, 62, 0.9 )",
//                   }}
//                 >
//                   EV
//                 </Typography>

//                 <img
//                   src={evimg} // Use the imported image
//                   alt="Local Image"
//                   style={{ width: "100%", height: "180px", display: "block" }}
//                 />
//               </Paper>
//             </Link>
//           </Grid>

//           <Grid
//             xs={2.3}
//             backgroundColor="rgba(3, 3, 62, 0.9 )"
//             p={1}
//             borderRadius={"5px"}
//             style={{ cursor: "pointer" }}
//             onClick={handleWaterManagementClick}
//           >
//             <Paper>
//               {/* Image inside Paper component */}
//               <Typography
//                 variant="body2"
//                 align="center"
//                 style={{
//                   fontSize: "16px",
//                   fontWeight: "600",
//                   color: "white",
//                   background: "rgba(3, 3, 62, 0.9 )",
//                 }}
//               >
//                 Water Management
//               </Typography>

//               <img
//                 src={wmimg} // Use the imported image
//                 alt="Local Image"
//                 style={{ width: "100%", height: "180px", display: "block" }}
//                 // onClick={handleWaterManagementClick}
//               />
//             </Paper>
//           </Grid>

//           <Grid
//             xs={2.3}
//             backgroundColor="rgba(3, 3, 62, 0.9 )"
//             p={1}
//             borderRadius={"5px"}
//           >
//             <Link to="/stpmanagement">
//               <Paper>
//                 {/* Image inside Paper component */}
//                 <Typography
//                   variant="body2"
//                   align="center"
//                   style={{
//                     fontSize: "16px",
//                     fontWeight: "600",
//                     color: "white",
//                     background: "rgba(3, 3, 62, 0.9 )",
//                   }}
//                 >
//                   STP
//                 </Typography>

//                 <img
//                   src={stp} // Use the imported image
//                   alt="Local Image"
//                   style={{ width: "100%", height: "180px", display: "block" }}
//                 />
//               </Paper>
//             </Link>
//           </Grid>
//           <Grid
//             xs={2.3}
//             backgroundColor="rgba(3, 3, 62, 0.9 )"
//             p={1}
//             borderRadius={"5px"}
//           >
//             <Link to="https://flowlinc.io">
//               <Paper>
//                 {/* Image inside Paper component */}
//                 <Typography
//                   variant="body2"
//                   align="center"
//                   style={{
//                     fontSize: "16px",
//                     fontWeight: "600",
//                     color: "white",
//                     background: "rgba(3, 3, 62, 0.9 )",
//                   }}
//                 >
//                   Food Decomposer
//                 </Typography>

//                 <img
//                   src={fooddecomp} // Use the imported image
//                   alt="Local Image"
//                   style={{ width: "100%", height: "180px", display: "block" }}
//                 />
//               </Paper>
//             </Link>
//           </Grid>
//         </Grid>
//       </div>
//     </div>
//   );
// }

import React,{useState} from "react";
import { Grid, Paper, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import solarimg from "../assets/images/sunenergy.jpg";
import evimg from "../assets/images/ev2.jpg";
import wmimg from "../assets/images/watermanagement.jpg";
import stp from "../assets/images/stp.jpg";
import fooddecomp from "../assets/images/fooddecom.jpg";
import ReactSpeedometer from "react-d3-speedometer";

// import { BarChart } from "@mui/x-charts/BarChart";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import "../assets/css/facilitysus.css";
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from "@mui/x-charts/Gauge";
import GaugeWithPointer from "./gaugewithpointer";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import BackButton from "./backbutton";
// import { Bar } from "react-chartjs-2";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";

const StyledBarChart = styled(BarChart)(({ theme }) => ({
  "& .MuiBar-root rect": {
    x: 0,
    y: -0,
    width: 20,
    height: 20,
    fill: "rgb(46, 150, 255)",
  },
  "& .MuiBarLabel-root": {
    fill: "white",
    color: "white",
  },
}));

export default function FacilitySustainability() {
  const location = useLocation()
  const navigate = useNavigate();
  const facilityData = location.state;

const locationId=facilityData.locationId
const locationName=facilityData.lineName
  const data = [
    { name: "FL 1", FL1: facilityData.fuelLevel_1 },
    { name: "FL 2", FL2: facilityData.fuelLevel_2 },
  ];

  const options = {
    margin: { top: 5, right: 30, left: 20, bottom: 5 },
  };
  const handleBack = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userID");
    localStorage.removeItem("tokenExpiredAt");

    navigate("/login");
  };
  const handleEnegyManagementClick = () => {
    navigate("/energymanagement", { state: { locationId , locationName} });
  };
  const handleGensetClick = () => {
    navigate("/dieselgenset", { state: { locationId,locationName } });
  };
  const handleAirQaulityClick = () => {
    navigate("/airquality", { state: { locationId,locationName } });
  };
  const handleWaterManagementClick = () => {
    navigate("/watermanagement", { state: { locationId,locationName } });
  };

  return (
    <div
      style={{
        padding: "0px 20px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: "#E8E8E8",
      }}
    >
      <Grid container>
        <Grid item xs={5} display={"flex"} alignItems="center" mt={2}>
          <button
            onClick={handleBack}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              padding: "0px",
              border: "0px",
            }}
          >
            <FontAwesomeIcon
              icon={faCircleArrowLeft}
              style={{
                marginRight: "8px",
                fontSize: "24px",
                marginBottom: "10px",
              }}
            />
          </button>
          <Typography variant="h5" gutterBottom>
            {facilityData.lineName} Facility
          </Typography>
        </Grid>
      </Grid>

      <div style={{ marginTop: "20px" }}>
        <Grid container spacing={3}>
          <Grid item md={4} sm={4}>
            <button
              onClick={handleEnegyManagementClick}
              style={{ cursor: "pointer", border: "0px", width: "100%" }}
            >
              <Paper sx={{ p: 2 }}>
                <Typography
                  variant="h6"
                  align="center"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "white",
                  }}
                >
                  Energy Management
                </Typography>
                <hr />
                <Grid container spacing={2}>
                  <Grid item xs={6} mt={6.2}>
                    <ReactSpeedometer
                      maxValue={15000}
                      minValue={0}
                      height={150}
                      width={180}
                      value={facilityData.kwh_1}
                      needleTransition="easeQuadIn"
                      needleTransitionDuration={1000}
                      needleColor="red"
                      startColor="red"
                      segments={5}
                      endColor="green"
                      ringWidth={20}
                      textColor="white"
                      // fontSize="10px"
                      currentValueText={`${facilityData.kwh_1} kWh 1`}
                      valueTextFontSize="15px"
                    />
                  </Grid>
                  <Grid item xs={6} mt={6}>
                    <ReactSpeedometer
                      maxValue={10000}
                      minValue={0}
                      height={150}
                      width={180}
                      value={facilityData.kwh_2}
                      kwh
                      needleTransition="easeQuadIn"
                      needleTransitionDuration={1000}
                      needleColor="red"
                      startColor="red"
                      segments={5}
                      endColor="green"
                      ringWidth={20}
                      textColor="white"
                      currentValueText={`${facilityData.kwh_2} kWh 2`}
                      valueTextFontSize="15px"
                    />
                  
                  </Grid>

                  <div
                    style={{
                      // marginTop: "12px",
                      display: "flex",
                      // justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      gap: "5px",
                      marginLeft: "8px",
                    }}
                  >
                    <Paper
                      sx={{
                        p: 2,
                        backgroundColor: "#0001",
                        width: "100%",
                      
                        padding: "0",
                      }}
                    >
                      <TableContainer component={Paper}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  padding="none"
                                  style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "600",
                                    background: "lightblue",
                                    padding: "none",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  Current 1
                                </Typography>
                              </TableCell>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  padding="none"
                                  style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "600",
                                    background: "lightblue",
                                    padding: "none",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  Current 2
                                </Typography>
                              </TableCell>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  padding="none"
                                  style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "600",
                                    background: "lightblue",
                                    padding: "none",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  Voltage 1
                                </Typography>
                              </TableCell>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "600",
                                    background: "lightblue",
                                    padding: "none",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  Voltage 2
                                </Typography>
                              </TableCell>
                              <TableCell align="center " padding="none">
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "600",
                                    background: "lightblue",
                                    padding: "none",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  PF 1
                                </Typography>
                              </TableCell>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "600",
                                    background: "lightblue",
                                    padding: "none",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  PF 2
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "600",
                                    background: "white",
                                    color: "maroon",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  {facilityData.currentR_1} A
                                </Typography>
                              </TableCell>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "600",
                                    background: "white",
                                    color: "maroon",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  {facilityData.currentR_2} A
                                </Typography>
                              </TableCell>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "600",
                                    background: "white",
                                    color: "maroon",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  {facilityData.voltageR_1} V
                                </Typography>
                              </TableCell>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "600",
                                    background: "white",
                                    color: "maroon",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  {facilityData.voltageR_2} V
                                </Typography>
                              </TableCell>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "600",
                                    background: "white",
                                    color: "maroon",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  {facilityData.pf_1}
                                </Typography>
                              </TableCell>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "600",
                                    background: "white",
                                    color: "maroon",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  {facilityData.pf_2}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                </Grid>
              </Paper>
            </button>
          </Grid>

          <Grid item md={4.5} sm={4.5}>
            <button
              onClick={handleGensetClick}
              style={{ border: "0px", cursor: "pointer", width: "100%" }}
            >
              <Paper sx={{ p: 2 }}>
                <Typography
                  variant="h6"
                  align="center"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "white",
                  }}
                >
                  Diesel Genset
                </Typography>
                <hr />
                <Grid container spacing={2}>
                  <Grid item xs={6} mt={5}>
                    <ReactSpeedometer
                      maxValue={10000}
                      minValue={0}
                      height={150}
                      width={180}
                      value={facilityData.gKwh_1}
                      kwh
                      needleTransition="easeQuadIn"
                      needleTransitionDuration={1000}
                      needleColor="red"
                      startColor="red"
                      segments={5}
                      endColor="green"
                      ringWidth={20}
                      textColor="white"
                      currentValueText={`${facilityData.gKwh_1} kWh`}
                      valueTextFontSize="15px"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ResponsiveContainer width="100%" height={185}>
                      <BarChart data={data} margin={options.margin}>
                        <XAxis dataKey="name" tick={{ fill: "white" }} />
                        <YAxis tick={{ fill: "white" }} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="FL1" fill="#8884d8" />
                        <Bar dataKey="FL2" fill="#82ca9d" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Grid>
                  <Grid
                    container
                    marginTop={1}
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <div
                      style={{
                        marginTop: "2px",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Paper
                        sx={{
                          p: 2,
                          backgroundColor: "#0001",
                          width: "80%",
                          // marginTop: "12px",
                          padding: "0",
                        }}
                      >
                        <TableContainer component={Paper}>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell align="center" padding="none">
                                  <Typography
                                    variant="body2"
                                    padding="none"
                                    style={{
                                      fontSize: "1.1rem",
                                      fontWeight: "600",
                                      background: "lightblue",
                                      padding: "none",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    kwh 1
                                  </Typography>
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  <Typography
                                    variant="body2"
                                    padding="none"
                                    style={{
                                      fontSize: "1.1rem",
                                      fontWeight: "600",
                                      background: "lightblue",
                                      padding: "none",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    kwh 2
                                  </Typography>
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  <Typography
                                    variant="body2"
                                    padding="none"
                                    style={{
                                      fontSize: "1.1rem",
                                      fontWeight: "600",
                                      background: "lightblue",
                                      padding: "none",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    DG 1
                                  </Typography>
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  <Typography
                                    variant="body2"
                                    style={{
                                      fontSize: "1.1rem",
                                      fontWeight: "600",
                                      background: "lightblue",
                                      padding: "none",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    DG 2
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="center" padding="none">
                                  <Typography
                                    variant="body2"
                                    style={{
                                      fontSize: "1.5rem",
                                      fontWeight: "600",
                                      background: "white",
                                      color: "maroon",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    {facilityData.gKwh_1}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  <Typography
                                    variant="body2"
                                    style={{
                                      fontSize: "1.5rem",
                                      fontWeight: "600",
                                      background: "white",
                                      color: "maroon",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    {facilityData.gKwh_1}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  padding="none"
                                  style={{ borderRight: "1px solid black" }}
                                >
                                  <Typography
                                    variant="body2"
                                    style={{
                                      fontSize: "1.5rem",
                                      fontWeight: "600",
                                      background:
                                        facilityData.dg_1 === "ON" ? "darkgreen" : "red",
                                      color: "white",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    {facilityData.dg_1}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  <Typography
                                    variant="body2"
                                    style={{
                                      fontSize: "1.5rem",
                                      fontWeight: "600",
                                      background:
                                        facilityData.dg_2 === "ON" ? "darkgreen" : "red",
                                      color: "white",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    {facilityData.dg_2}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </button>
          </Grid>

         
          <Grid item md={3} sm={3}>
            <button
              onClick={handleAirQaulityClick}
              style={{ cursor: "pointer", border: "0px" }}
              className="AQButton"
            >
              <Paper
                style={{ width: "400px" }}
                sx={{ p: 2 }}
                className="airQualityBox"
              >
                <Typography
                  variant="h6"
                  align="center"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "white",
                  }}
                >
                  Air Quality
                </Typography>
                <hr />

                {/* Air Quality Chart */}
                <Grid
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      margin: "34px 10px 0px 10px",
                    }}
                  >
                    <ReactSpeedometer
                      maxValue={500}
                      minValue={0}
                      height={150}
                      width={180}
                      value={facilityData.co2_1}
                      needleTransition="easeQuadIn"
                      needleTransitionDuration={1000}
                      needleColor="red"
                      startColor="green"
                      segments={5}
                      endColor="red"
                      ringWidth={20}
                      textColor="white"
                      currentValueText={`${facilityData.co2_1} Co2 1`}
                      valueTextFontSize="15px"
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      margin: "34px 10px 0px 10px",
                    }}
                  >
                    <ReactSpeedometer
                      maxValue={500}
                      minValue={0}
                      height={150}
                      width={180}
                      value={facilityData.co2_2}
                      needleTransition="easeQuadIn"
                      needleTransitionDuration={1000}
                      needleColor="red"
                      startColor="green"
                      segments={5}
                      endColor="red"
                      ringWidth={20}
                      textColor="white"
                      currentValueText={`${facilityData.  co2_2} Co2 2`}
                      valueTextFontSize="15px"
                    />
                  </div>
                </Grid>

                
                <Grid
                  container
                  marginTop={2}
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                
                  <div
                    style={{
                    
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      gap: "10px",
                    }}
                  >
                    <Paper
                      sx={{
                        // p: 2,
                        backgroundColor: "#0001",
                        width: "90%",
                        
                        padding: "0",
                      }}
                    >
                      <TableContainer component={Paper}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  padding="none"
                                  style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "600",
                                    background: "lightblue",
                                    padding: "none",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  Temp 1
                                </Typography>
                              </TableCell>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  padding="none"
                                  style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "600",
                                    background: "lightblue",
                                    padding: "none",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  Temp 2
                                </Typography>
                              </TableCell>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  padding="none"
                                  style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "600",
                                    background: "lightblue",
                                    padding: "none",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  Humidity 1
                                </Typography>
                              </TableCell>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "600",
                                    background: "lightblue",
                                    padding: "none",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  Humidity 2
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "600",
                                    background: "white",
                                    color: "maroon",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  {facilityData.temperature_1}°C
                                </Typography>
                              </TableCell>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "600",
                                    background: "white",
                                    color: "maroon",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  {facilityData.temperature_2}°C
                                </Typography>
                              </TableCell>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "600",
                                    background: "white",
                                    color: "maroon",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  {facilityData.humidity_1}%
                                </Typography>
                              </TableCell>
                              <TableCell align="center" padding="none">
                                <Typography
                                  variant="body2"
                                  style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "600",
                                    background: "white",
                                    color: "maroon",
                                    borderRight: "1px solid black",
                                  }}
                                >
                                  {facilityData.humidity_2}%
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                 
                </Grid>
              </Paper>
            </button>
          </Grid>
        </Grid>
        <Grid
          marginTop={2}
          container
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
       
          <Grid
            xs={2.3}
            backgroundColor="rgba(3, 3, 62, 0.9 )"
            p={1}
            borderRadius={"5px"}
          >
            <Link to="https://monitoring.solaredge.com/solaredge-web/p/login?locale=en_GB-path">
              <Paper>
             
                <Typography
                  variant="body2"
                  align="center"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "white",
                    background: "rgba(3, 3, 62, 0.9 )",
                  }}
                >
                  Solar
                </Typography>

                <img
                  src={solarimg} 
                  alt="Local Image"
                  style={{ width: "180px", height: "180px", display: "block" }}
                />
              </Paper>
            </Link>
          </Grid>
        
          <Grid
            xs={2.3}
            backgroundColor="rgba(3, 3, 62, 0.9 )"
            p={1}
            borderRadius={"5px"}
          >
            <Link to="http://cms.kazam.in/auth/login">
              <Paper>
                <Typography
                  variant="body2"
                  align="center"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "white",
                    background: "rgba(3, 3, 62, 0.9 )",
                  }}
                >
                  EV
                </Typography>

                <img
                  src={evimg} 
                  alt="Local Image"
                  style={{ width: "100%", height: "180px", display: "block" }}
                />
              </Paper>
            </Link>
          </Grid>

          <Grid
            xs={2.3}
            backgroundColor="rgba(3, 3, 62, 0.9 )"
            p={1}
            borderRadius={"5px"}
            style={{ cursor: "pointer" }}
            onClick={handleWaterManagementClick}
          >
            <Paper>
          
              <Typography
                variant="body2"
                align="center"
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "white",
                  background: "rgba(3, 3, 62, 0.9 )",
                }}
              >
                Water Management
              </Typography>

              <img
                src={wmimg} 
                alt="Local Image"
                style={{ width: "100%", height: "180px", display: "block" }}
              
              />
            </Paper>
          </Grid>

          <Grid
            xs={2.3}
            backgroundColor="rgba(3, 3, 62, 0.9 )"
            p={1}
            borderRadius={"5px"}
          >
            <Link to="/stpmanagement">
              <Paper>
              
                <Typography
                  variant="body2"
                  align="center"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "white",
                    background: "rgba(3, 3, 62, 0.9 )",
                  }}
                >
                  STP
                </Typography>

                <img
                  src={stp} 
                  alt="Local Image"
                  style={{ width: "100%", height: "180px", display: "block" }}
                />
              </Paper>
            </Link>
          </Grid>
          <Grid
            xs={2.3}
            backgroundColor="rgba(3, 3, 62, 0.9 )"
            p={1}
            borderRadius={"5px"}
          >
            <Link to="https://flowlinc.io">
              <Paper>
             
                <Typography
                  variant="body2"
                  align="center"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "white",
                    background: "rgba(3, 3, 62, 0.9 )",
                  }}
                >
                  Food Decomposer
                </Typography>

                <img
                  src={fooddecomp} 
                  alt="Local Image"
                  style={{ width: "100%", height: "180px", display: "block" }}
                />
              </Paper>
            </Link>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
