import React from "react";
import { Box, TextField } from "@mui/material";

export default function UATTesting() {
  return (
    <div style={{padding:'0px 20px'}}>
        <div style={{display:'flex',justifyContent:'flex-start',paddingTop: "5px", paddingBottom: "10px"}}>
          <h2>UAT Testing</h2>
        </div>
      <Box>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // padding: "20px",
          }}
        >
          <TextField
            // fullWidth
            label="Time Stamp"
            type="datetime-local"
            defaultValue="2024-03-20 09:00:00"
            id="fullWidth"
          />
        </div>
      </Box>
    </div>
  );
}
