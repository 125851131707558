import React from 'react';
import { Button, Grid, Paper, styled } from '@mui/material';
import Chart from 'react-apexcharts';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import {Link} from 'react-router-dom'
const StyledSidebar = styled(Paper)({
  height: '100%',
  backgroundColor: '#f0f0f0',
  padding: '16px',
});

const FacilityManagement = () => {
  return (
    <div style={{ padding: "0px 20px" ,}}>
    <Grid container spacing={3}>
      <Grid item xs={2}>
        {/* Sidebar */}
        <StyledSidebar style={{height:'100vh',width: '240px'}}>
          {/* Your sidebar content/menu here */}
          {/* <div>Sidebar Menu</div> */}
          <ul style={{padding:"15px"}}>
            <Button style={{backgroundColor:'#1FAEC5',fontSize:'13px',fontWeight:"600", color:'black',width:'100%'}}>Asset Management</Button>
            <br/>
            <Button style={{backgroundColor:'#1FAEC5',fontSize:'13px', fontWeight:"600", color:'black',marginTop:'15px',width:'100%'}}>Security </Button>
            <br/>
            <Button style={{backgroundColor:'#1FAEC5',fontSize:'13px', fontWeight:"600", color:'black',marginTop:'15px',width:'100%'}}>Man Power</Button>

            <Link to="/map"><Button style={{backgroundColor:'#1FAEC5',fontSize:'13px', fontWeight:"600", color:'black',marginTop:'15px',width:'100%'}}>Map View</Button></Link>
           
      
          </ul>
        </StyledSidebar>
      </Grid>
         </Grid>
   
    </div>
  );
};

export default FacilityManagement;
