import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  TextField,
  IconButton,
  Box,
  tableCellClasses,
  styled,
} from "@mui/material";

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function UATReport() {

  const dummyData = [
    {
      dateTime: '2024-03-20 09:00:00',
      machineId: 'M0012',
      cycleTime: '4 hours',
      adData: 'Some ad data',
      adDiffData: 'Some ad diff data',
      dctPercent: '10%',
      lossTypeForOpe: 'Type B',
      valueAddingOperatingTime: '3 hours',
      partA: 'Part A',
      partB: 'Part B',
      partC: 'Part C',
      partD: 'Part D',
      partE: 'Part E',
      test: 'Test',
      frcdCycleTime: '1.5 hours',
      minTime: '10 mins',
      labelEncodes: 'Encoded label',
      fPartName: 'F Part',
      fCycleTime: '1 hours',
      category: 'Category 1',
      pLoss: '15%',
      aLoss: '12%'
    },
    {
      dateTime: '2024-03-20 10:00:00',
      machineId: 'M001',
      cycleTime: '2 hours',
      adData: 'Some ad data',
      adDiffData: 'Some ad diff data',
      dctPercent: '10%',
      lossTypeForOpe: 'Type A',
      valueAddingOperatingTime: '3 hours',
      partA: 'Part A',
      partB: 'Part B',
      partC: 'Part C',
      partD: 'Part D',
      partE: 'Part E',
      test: 'Test',
      frcdCycleTime: '1.5 hours',
      minTime: '30 mins',
      labelEncodes: 'Encoded label',
      fPartName: 'F Part',
      fCycleTime: '2 hours',
      category: 'Category 1',
      pLoss: '5%',
      aLoss: '2%'
    },
  
  ];

  return (
    <div style={{padding:'0px 20px'}}>
        <div style={{display:'flex',justifyContent:'flex-start',paddingTop: "5px", paddingBottom: "20px"}}>
          <h2>UAT Report</h2>
        </div>
      <Box sx={{ overflow: 'scroll' }}>
      <div style={{ display: "flex", marginTop: "20px", justifyContent: "space-between" }}>
          <div style={{ justifyContent: "center", alignItems: "center" }}>
            <TextField
              label="Search"
              variant="outlined"
              style={{ marginBottom: "20px"  }}
              // value={searchTerm}
              // onChange={(e) => setSearchTerm(e.target.value)}
            />
            {"   "}
            <FontAwesomeIcon
              style={{ fontSize: "30px", padding: "15px" }}
              icon={faSearch}
            />
          </div>
          </div>
          <Table size="small" style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }} >
          <TableHead>
          <TableRow style={{ backgroundColor: '#0002' }}>
              <StyledTableCell   style={{fontSize: '16px'  }} >Date Time</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >Machine ID</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >Cycle Time</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >Ad Data</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >Ad Diff Data</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >DCT Percent</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >Loss Type For Ope</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >Value Adding Operating Time</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >Part A</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >Part B</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >Part C</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >Part D</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >Part E</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >Test</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >Frcd Cycle Time</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >Min Time</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >Lable - Encodes</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >F Partname</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >F Cycletime</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >Category</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >P Loss</StyledTableCell >
              <StyledTableCell   style={{fontSize: '16px' }} >A Loss</StyledTableCell >
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Mapping dummy data to table rows */}
            {dummyData.map((row, index) => (
              <StyledTableRow key={index}>
               <StyledTableCell  className="table-cell">{row.dateTime}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.machineId}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.cycleTime}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.adData}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.adDiffData}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.dctPercent}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.lossTypeForOpe}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.valueAddingOperatingTime}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.partA}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.partB}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.partC}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.partD}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.partE}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.test}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.frcdCycleTime}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.minTime}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.labelEncodes}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.fPartName}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.fCycleTime}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.category}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.pLoss}</StyledTableCell >
               <StyledTableCell  className="table-cell">{row.aLoss}</StyledTableCell >
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      {/* <Link to="/">Back</Link> */}
    </div>
  );
}
