import axios from "axios";
import { baseUrl } from "./baseUrl";

export const apiDeleteLine = async (plantName) => {
    const url = baseUrl + `/line/removeLine/${plantName}`;
    try {
      const token=localStorage.getItem("token")
      const data = await axios.delete(url, {headers:{
          Authorization:`Bearer ${token}`
      }});
      return data;
    } catch (error) {
      console.error("Error during delete plant:", error);
      throw error;
    }
  };