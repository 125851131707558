import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  styled,
  tableCellClasses,
  TablePagination,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { apiMachineMaster } from "../api/api.addmachine";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { apigetMachine } from "../api/apigetmachine";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import DeleteConfirmationModal from "./deletemodal";
import { apiGetShift } from "../api/api.getshift";
import { apiGetPlant } from "../api/api.getplant";
import { apiGetQualityRejection } from "../api/api.getqualityrejection";
import { apigetLines } from "../api/api.getline";
import { apiQualityRejection } from "../api/api.addqualityrejection";
import { parseISO, format } from "date-fns";
import { apiUpdateQualityRejection } from "../api/api.updateqrejection";
import { apiDeleteQualityRejection } from "../api/api.deleteqrejection";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const staticData = [
  {
    Plant_Name: "Ghaziabad",
    Machine_Name: "GZB EVM YRA 308 WS",
    Rejection_Number: 20,
    Createdate: "3/3/2024",
    Datetime: "3/2/2024",
    Shift_Name: "C",
  },
  {
    Plant_Name: "Ghaziabad",
    Machine_Name: "GZB EVM YRA 308 WS",
    Rejection_Number: 30,
    Createdate: "3/27/2024",
    Datetime: "3/21/2024",
    Shift_Name: "C",
  },
  {
    Plant_Name: "Ghaziabad",
    Machine_Name: "GZB EVM YRA 308 WS",
    Rejection_Number: 25,
    Createdate: "3/27/2024",
    Datetime: "3/21/2024",
    Shift_Name: "A",
  },
  {
    Plant_Name: "Ghaziabad",
    Machine_Name: "GZB EVM YRA 308 WS",
    Rejection_Number: 35,
    Createdate: "3/27/2024",
    Datetime: "3/21/2024",
    Shift_Name: "B",
  },
  {
    Plant_Name: "Ghaziabad",
    Machine_Name: "GZB EVM YRA 308 WS",
    Rejection_Number: 21,
    Createdate: "3/28/2024",
    Datetime: "3/27/2024",
    Shift_Name: "A",
  },
  {
    Plant_Name: "Head office",
    Machine_Name: "FUN WITH GAME",
    Rejection_Number: 20,
    Createdate: "3/29/2024",
    Datetime: "3/28/2024",
    Shift_Name: "B",
  },
];
export default function QualityRejection() {
  const [machinedata, setMachinedata] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [addOpen, setAddOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [machineID, setMachineID] = useState("");
  const [machineName, setMachineName] = useState("");
  const [machineCode, setMachineCOde] = useState("");
  const [lineProductionCount, setLineProductionCount] = useState("");
  const [lineName, setLineName] = useState([]);
  const [severity, setSeverity] = useState("success");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [shiftData, setShiftData] = useState([]);
  const [qRejectionData, setQRejectionData] = useState([]);
  const [plantData, setPlantData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedPlant, setSelectedPlant] = useState("");
  const [selectedLine, setSelectedLine] = useState("");
  const [lineData, setLineData] = useState([]);
  const[deleteQRejectionId,setDeleteQRejectionId]=useState(null);
  const [filteredLineData, setFilteredLineData] = useState([]);
  const [filteredMachineData, setFilteredMachineData] = useState([]);
  const [updatedQRejection, setUpdatedQRejection] = useState({
    plantNo: "",
    machineNo: "",
    shiftNo: "",
    lineNo: "",
    rejectionNo: "",
    date: "23:01:1997",
  });
  const handleInputChange = (e) => {
    console.log(e.target.name, e.target.value);
    const { name, value } = e.target;
    setUpdatedQRejection((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "lineNo") {
      setSelectedLine(value);
    }
  };
  useEffect(() => {
    const getmachine = async () => {
      try {
        const result = await apigetMachine();
        console.log("machine data:", result.data.data);
        setMachinedata(result?.data.data);
        console.log("machine", machinedata);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    return () => {
      getmachine();
    };
  }, [refreshData]);

  useEffect(() => {
    const getPlant = async () => {
      try {
        const result = await apiGetPlant();
        console.log("Result data plant:", result.data.data);
        setPlantData(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getPlant();
  }, [refreshData]);

  useEffect(() => {
    const getShift = async () => {
      try {
        const result = await apiGetShift();
        console.log("shiftdata", result.data.data);
        setShiftData(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getShift();
  }, [refreshData]);

  useEffect(() => {
    const getQualities = async () => {
      try {
        const result = await apiGetQualityRejection();
        console.log("qrejectiondata:", result.data.data);
        setQRejectionData(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getQualities();
  }, [refreshData]);
  useEffect(() => {
    const getline = async () => {
      try {
        const result = await apigetLines();
        console.log("Result data line:", result.data.data); // Log the data property
        setLineData(result.data.data); // Set plantData to result.data
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getline();
  }, [refreshData]);
  useEffect(() => {
    if (updatedQRejection.lineNo) {
      const selectedLine = lineData.find(
        (line) => line.lineNo === updatedQRejection.lineNo
      );
      if (selectedLine) {
        setUpdatedQRejection((prevData) => ({
          ...prevData,
          plantNo: selectedLine.plantNo,
        }));
      }
    }
    if (updatedQRejection.lineNo) {
      const filteredMachines = machinedata.filter(
        (machine) => machine.lineNo === updatedQRejection.lineNo
      );
      setFilteredMachineData(filteredMachines);
    }
  }, [
    updatedQRejection.plantNo,
    updatedQRejection.lineNo,
    lineData,
    machinedata,
  ]);
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();

    try {
      const formattedDate = format(
        parseISO(updatedQRejection.date),
        "dd-MMM-yyyy"
      );
      const formattedQRData = {
        ...updatedQRejection,
        date: formattedDate,
      };
      const result = await apiQualityRejection(formattedQRData);
      setAddOpen(false);
      setRefreshData((prev) => !prev);

      handleSnackbarOpen("Quality rejection added successfully!", "success"); // Pass severity as "success"
      console.log("response", result.data);
    } catch (error) {
      console.error("Error adding machine:", error);
      handleSnackbarOpen("Error quality rejection. Please try again.", "error"); // Pass severity as "error"
    }
  };
  const handleUpdateSubmit = async (event) => {
    event.preventDefault();

    try {
      const formattedDate = format(
        parseISO(updatedQRejection.date),
        "dd-MMM-yyyy"
      );
      const formattedQRData = {
        ...updatedQRejection,
        date: formattedDate,
      };
      const result = await apiUpdateQualityRejection(formattedQRData);
      setUpdateOpen(false)
      setRefreshData((prev) => !prev);

      handleSnackbarOpen("Quality reason updated successfully!", "success"); // Pass severity as "success"
      console.log("response", result.data);
    } catch (error) {
      console.error("Error adding machine:", error);
      handleSnackbarOpen("Error updating quality rejection. Please try again.", "error"); // Pass severity as "error"
    }
  };
  const handleEditClick = (row) => {
    setUpdatedQRejection(row);
    setUpdateOpen(true);
  };

  const handleDeleteClick = (row) => {
    console.log("deleting qr:", row.id);

    setDeleteQRejectionId(row.id)
    setDeleteModalOpen(true);
  };
  const handleConfirmDelete = async () => {
    try {
      await apiDeleteQualityRejection(deleteQRejectionId);
      handleSnackbarOpen("Quality rejection deleted successfully!", "success");
      setRefreshData((prev) => !prev);
    } catch (error) {
      console.error("Error deleting Plant:", error);
      handleSnackbarOpen("Error deleting quality rejection. Please try again.", "error");
    } finally {
      setDeleteModalOpen(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const filteredMachines = machinedata.filter(
    (machine) => machine.lineNo === selectedLine
  );

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const handleModalClose = () => {
    // Reset the form data
    setUpdatedQRejection({
      plantNo: "",
      machineNo: "",
      shiftNo: "",
      rejectionNo: "",
      date: "23:01:1997",
    });

    setAddOpen(false);
    setUpdateOpen(false);
  };
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, qRejectionData.length - page * rowsPerPage);
  return (
    <div style={{ padding: "0px 20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
      >
        <h2>Quality Rejection</h2>

        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          <Button
            onClick={() => setAddOpen(true)}
            style={{
              fontWeight: "500",
              borderRadius: "4px",
              color: "gray",
              border: "2px solid gray",
              padding: "5px",
              marginBottom: "5px",
            }}
          >
            {" "}
            Add New &nbsp;{" "}
            <FontAwesomeIcon
              style={{ fontSize: "18px", color: "gray" }}
              icon={faPlus}
            />
          </Button>
        </div>
      </div>
      <Box>
        <Table
          size="small"
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell className="table-cell">
                Plant Name
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Machine Name{" "}
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Rejection Number
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Create Date{" "}
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Date Time
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Shift Name{" "}
              </StyledTableCell>
              {/* <StyledTableCell  className="table-cell">Line Name</StyledTableCell > */}
              <StyledTableCell className="table-cell">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {qRejectionData.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell className="table-cell">
                  {row.lineName}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  {row.machineName}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  {row.rejectionNo}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  {row.createdAt}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  {row.processDate}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  {row.shiftName}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                  className="table-cell"
                >
                  <IconButton onClick={() => handleEditClick(row)}>
                    <EditIcon />
                  </IconButton>
                  <div
                    className="divider"
                    style={{
                      height: "20px",
                      width: "2px",
                      backgroundColor: "#0003",
                    }}
                  ></div>
                  <IconButton onClick={() => handleDeleteClick(row)}>
                    <DeleteIcon />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 }}>
                <StyledTableCell colSpan={8} style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "-400px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {`No further data available`}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>{" "}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={qRejectionData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
       
        <Modal open={updateOpen} onClose={handleModalClose}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              minWidth: "500px",
              borderRadius: "10px",
            }}
          >
            <button
              onClick={handleModalClose}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "30px",
              }}
            >
              &times;
            </button>
            <h2>Update Reason</h2>
            <hr />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Plant Name</InputLabel>
                <Select
                  name="lineNo"
                  value={updatedQRejection?.lineNo}
                  onChange={handleInputChange}
                >
                  {lineData.map((line) => (
                    <MenuItem key={line.id} value={line.lineNo}>
                      {line.lineName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Machine Name</InputLabel>
                <Select
                  name="machineNo"
                  value={updatedQRejection?.machineNo}
                  onChange={handleInputChange}
                >
                  {filteredMachines.map((machine) => (
                    <MenuItem key={machine.id} value={machine.machineId}>
                      {machine.displayMachineName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Shift Name</InputLabel>
                <Select
                  name="shiftNo"
                  value={updatedQRejection?.shiftNo}
                  onChange={handleInputChange}
                >
                  {shiftData.map((shift, index) => (
                    <MenuItem key={shift.shiftId} value={shift?.shiftId}>
                      {shift?.shiftName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <TextField
                name="rejectionNo"
                label="Rejection Number "
                value={updatedQRejection?.rejectionNo}
                onChange={handleInputChange}
                style={{ marginRight: "10px" }}
              />
              <FormControl sx={{ minWidth: 225 }}>
                <TextField
                  name="date"
                  label="Start Date"
                  type="datetime-local"
                  // defaultValue="2024-03-20T09:00"
                  value={updatedQRejection?.date}
                  onChange={handleInputChange}
                />
              </FormControl>
            </div>

            <Button
              onClick={handleUpdateSubmit}
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              Update
            </Button>
          </div>
        </Modal>
        <Modal open={addOpen} onClose={handleModalClose}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              minWidth: "500px",
              borderRadius: "10px",
            }}
          >
            <button
              onClick={handleModalClose}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "30px",
              }}
            >
              &times;
            </button>
            <h2>Add Reason</h2>
            <hr />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Plant Name</InputLabel>
                <Select
                  name="lineNo"
                  value={updatedQRejection?.lineNo}
                  onChange={handleInputChange}
                >
                  {lineData.map((line) => (
                    <MenuItem key={line.id} value={line.lineNo}>
                      {line.lineName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Machine Name</InputLabel>
                <Select
                  name="machineNo"
                  value={updatedQRejection?.machineNo}
                  onChange={handleInputChange}
                >
                  {filteredMachines.map((machine) => (
                    <MenuItem key={machine.id} value={machine.machineId}>
                      {machine.displayMachineName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Shift Name</InputLabel>
                <Select
                  name="shiftNo"
                  value={updatedQRejection?.shiftNo}
                  onChange={handleInputChange}
                >
                  {shiftData.map((shift, index) => (
                    <MenuItem key={shift.shiftId} value={shift?.shiftId}>
                      {shift?.shiftName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <TextField
                name="rejectionNo"
                label="Rejection Number "
                value={updatedQRejection.rejectionNo}
                onChange={handleInputChange}
                style={{ marginRight: "10px" }}
              />
              <FormControl sx={{ minWidth: 225 }}>
                <TextField
                  name="date"
                  label="Start Date"
                  type="datetime-local"
                  // defaultValue="2024-03-20T09:00"
                  value={updatedQRejection.date}
                  onChange={handleInputChange}
                />
              </FormControl>
            </div>

            <Button
              onClick={handleAddSubmit}
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              Add
            </Button>
          </div>
        </Modal>
        <DeleteConfirmationModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleConfirmDelete}
        />
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
