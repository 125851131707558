import React, { useState, useEffect } from "react";
import "../assets/css/navbar.css";
import {
  faBars,
  faClose,
  faAngleDown,
  faAngleUp,
  faGear,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Button, SwipeableDrawer } from "@mui/material";
import brandlogo from '../assets/images/logo.png'

function NavBar() {
  const location = useLocation();
  const [clicked, setClicked] = useState(false);
  const [hideMenu, setHideMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [plantArchitectureOpen, setPlantArchitectureOpen] = useState(false);

  const [UATOpen, setUATOpen] = useState(false);
  const [rawDataOpen, setRawDataOpen] = useState(false);
  const [productionOpen, setProductionOpen] = useState(false);
  const [method2Open, setMethod2Open] = useState(false);
  const [method1Open, setMethod1Open] = useState(false);
  const [state, setState] = React.useState({
    right: false,
  });
  const navigate=useNavigate()
  const handlePlantArchitectureClick = () => {
    setUATOpen(false);
    setMethod2Open(false);
    setMethod1Open(false);
    setProductionOpen(false);
    setRawDataOpen(false);
    setPlantArchitectureOpen(!plantArchitectureOpen);
  };

  const handleUATClick = () => {
    setUATOpen(!UATOpen);

    setMethod2Open(false);
    setMethod1Open(false);
    setProductionOpen(false);
    setRawDataOpen(false);
    setPlantArchitectureOpen(false);
  };

  const handleMethod2CLick = () => {
    setMethod2Open(!method2Open);
    setUATOpen(false);
    setMethod1Open(false);
    setProductionOpen(false);
    setRawDataOpen(false);
    setPlantArchitectureOpen(false);
  };
  const handleMethod1CLick = () => {
    setMethod1Open(!method1Open);
    setUATOpen(false);
    setMethod2Open(false);
    setProductionOpen(false);
    setRawDataOpen(false);
    setPlantArchitectureOpen(false);
  };

  const handleProductionLick = () => {
    setUATOpen(false);
    setMethod2Open(false);
    setMethod1Open(false);

    setRawDataOpen(false);
    setPlantArchitectureOpen(false);
    setProductionOpen(!productionOpen);
  };

  const handleRawDataClick = () => {
    setRawDataOpen(!rawDataOpen);
    setUATOpen(false);
    setMethod2Open(false);
    setMethod1Open(false);
    setProductionOpen(false);

    setPlantArchitectureOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = () => {
    setClicked(!clicked);
  };
  const handlelogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userID")
    localStorage.removeItem("tokenExpiredAt")

    navigate("/login"); 
  };
 

  const handleHideMenu = () => {
    setHideMenu(!hideMenu);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleSettingsClick = () => {
    setOpen(!open);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ right: open });
  };

  return (
    <nav>
      <div>
        <Link className="clogo" to="#">
        <img 
  src={brandlogo} 
  style={{ height: '35px', width: '180px', objectFit: 'contain' }} 
  alt="logo" 
/>

        </Link>
      </div>
      <div className="mylinks">
        <ul id="navbar" className={clicked ? "active" : ""}>
          {/* <li>
            {" "}
            <Link
              to="/operations"
              className={location.pathname === "/operations" ? "active" : ""}
            >
              Operations
            </Link>
          </li> */}
{/* 
          <li>
            {" "}
            <Link
              to="/cbm"
              className={location.pathname === "/cbm" ? "active" : ""}
            >
              CBM
            </Link>
          </li> */}
          <li>
            {" "}
            <Link
              to="/facilitymanagement"
              className={
                location.pathname === "/facilitymanagement" ? "active" : ""
              }
            >
              Facility Management
            </Link>
          </li>
          {/* <li>
            {" "}
            <Link
              to="/jsplemanagement"
              className={
                location.pathname === "/jsplemanagement" ? "active" : ""
              }
            >
              JSPL
            </Link>
          </li> */}
        </ul>
      </div>
      <div>
        <Button onClick={handlelogout} style={{color:'black'}}><FontAwesomeIcon icon={faSignOut}>Logout</FontAwesomeIcon></Button>
      </div>
      {/* <div className="seticon">
        <FontAwesomeIcon
          style={{ fontSize: "25px", padding: "0px 40px", cursor: "pointer" }}
          icon={faGear}
          // onClick={toggleSidebar}
          onClick={toggleDrawer(true)}
        />
      </div> */}
      {isMobile && (
        <div id="mobile">
          <FontAwesomeIcon
            id="bar"
            icon={clicked ? faClose : faBars}
            onClick={handleClick}
            style={{ cursor: "pointer", fontSize: "26px" }}
          />
        </div>
      )}

      {/* <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
       

        <div className={`sidebar ${toggleDrawer(true) ? "open" : ""}`}>
          <List>
            <ListItem
              onClick={toggleDrawer(false)}
              Button
              component={Link}
              to="/raw_data"
              className={
                location.pathname === "/raw_data" ? "activeListItem" : ""
              }
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Raw Data" />
            </ListItem>

            <ListItem
              onClick={toggleDrawer(false)}
              Button
              component={Link}
              to="/hour_bucket_m1"
              className={
                location.pathname === "/hour_bucket_m1" ? "activeListItem" : ""
              }
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Hour Bucket M1" />
            </ListItem>
            <ListItem
              Button
              onClick={toggleDrawer(false)}
              component={Link}
              to="/hour_bucket_m2"
              className={
                location.pathname === "/hour_bucket_m2" ? "activeListItem" : ""
              }
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Hour Bucket M2" />
            </ListItem>

            <ListItem
              style={{ cursor: "pointer" }}
              Button
              onClick={handleMethod1CLick}
              
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Method 1" />

              {method1Open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={method1Open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
              
                <ListItem
                  Button
                  component={Link}
                  to="/method1/ghaziabad_ope_1"
                  className={
                    location.pathname === "/method1/ghaziabad_ope_1"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Ghaziabad OPE" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/method1/pathredi_ope_1"
                  className={
                    location.pathname === "/method1/pathredi_ope_1"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Pathredi OPE" />
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              style={{ cursor: "pointer" }}
              Button
              onClick={handleMethod2CLick}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Method 2" />

              {method2Open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={method2Open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  Button
                  component={Link}
                  to="/method2/machine_input"
                  className={
                    location.pathname === "/method2/machine_input"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Machine Input" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/method2/ghaziabad_ope_2"
                  className={
                    location.pathname === "/method2/ghaziabad_ope_2"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Ghaziabad OPE(2) " />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/method2/pathredi_ope_2"
                  className={
                    location.pathname === "/method2/pathredi_ope_2"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Pathredi OPE(2)" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem
              style={{ cursor: "pointer" }}
              Button
              onClick={handleProductionLick}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Production Plan" />

              {productionOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={productionOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  Button
                  component={Link}
                  to="/production_plan/shift_master"
                  className={
                    location.pathname === "/production_plan/shift_master"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Shift Master" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/production_plan/quality_rejection"
                  className={
                    location.pathname === "/production_plan/quality_rejection"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Quality Rejection " />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/production_plan/machine_downtime"
                  className={
                    location.pathname === "/production_plan/machine_downtime"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Machine Downtime" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/production_plan/holiday_list"
                  className={
                    location.pathname === "/production_plan/holiday_list"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Holiday List" />
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              style={{ cursor: "pointer" }}
              Button
              onClick={handleSettingsClick}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  style={{ cursor: "pointer" }}
                  Button
                  onClick={handlePlantArchitectureClick}
                >
                  <ListItemText primary="Plant Architecture" />
                  {plantArchitectureOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={plantArchitectureOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem
                      Button
                      component={Link}
                      to="/plant_architecture/plant_master"
                      className={
                        location.pathname === "/plant_architecture/plant_master"
                          ? "activeListItem"
                          : ""
                      }
                    >
                      <ListItemText primary="Plant Master" />
                    </ListItem>
                    <ListItem
                      Button
                      component={Link}
                      to="/plant_architecture/line_master"
                      className={
                        location.pathname === "/plant_architecture/line_master"
                          ? "activeListItem"
                          : ""
                      }
                    >
                      <ListItemText primary="Line Master" />
                    </ListItem>
                    <ListItem
                      Button
                      component={Link}
                      to="/plant_architecture/machine_master"
                      className={
                        location.pathname ===
                        "/plant_architecture/machine_master"
                          ? "activeListItem"
                          : ""
                      }
                    >
                      <ListItemText primary="Machine Master" />
                    </ListItem>
                    <ListItem
                      Button
                      component={Link}
                      to="/plant_architecture/part_master"
                      className={
                        location.pathname === "/plant_architecture/part_master"
                          ? "activeListItem"
                          : ""
                      }
                    >
                      <ListItemText primary="Part Master" />
                    </ListItem>
                    <ListItem
                      style={{ marginBottom: "120px" }}
                      Button
                      component={Link}
                      to="/plant_architecture/frdc_master"
                      className={
                        location.pathname === "/plant_architecture/frdc_master"
                          ? "activeListItem"
                          : ""
                      }
                    >
                      <ListItemText primary="Change Overtime Master" />
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </Collapse>
          </List>
        </div>
      </SwipeableDrawer> */}
    </nav>
  );
}

export default NavBar;
