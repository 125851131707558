import axios from "axios";
import { baseUrl } from "./baseUrl";

export const apiAddDownTime = async (data) => {
  console.log(data);
  const url = baseUrl + "/downtime/addDownTime";
  try {
    const token = localStorage.getItem("token");

    const datas = await axios.post(
      url,
      {
        plantNo: data.plantNo,
        lineNo: data.lineNo,
        machineNo: data.machineNo,
        shiftId: '1',
        machineDownDate: data.machineDownDate,
        totalDownTime: data.totalDownTime,
        startTime: data.startTime,
        endTime: data.endTime,
        reason: data.reason,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return datas;
  } catch (error) {
    console.error("Error during adding part:", error);
    throw error;
  }
};
