// import React, { useState, useRef, useEffect } from "react";
// import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from "react-leaflet";
// import L from "leaflet";
// import indiaGeoJson from "../assets/states_india(1).json";
// import { apiGetLocation } from "../api/api.getLocation";

// import "leaflet/dist/leaflet.css";

// const factoryPoints = [
//   { name: "Pune, Maharashtra", coordinates: [18.5204, 73.8567], state: "Maharashtra" },
//   { name: "Luhari Block A and B, Haryana", coordinates: [28.1732, 76.4156], state: "Haryana" },
//   { name: "Bhiwandi, Maharashtra", coordinates: [19.2813, 73.3483], state: "Maharashtra" },
//   { name: "Nashik, Maharashtra", coordinates: [19.9975, 73.7898], state: "Maharashtra" },
//   { name: "Chennai, Tamil Nadu", coordinates: [13.0827, 79.9707], state: "Tamil Nadu" },
//   { name: "Hyderabad, Telangana", coordinates: [17.4065, 78.4772], state: "Telangana" },
// ];

// delete L.Icon.Default.prototype._getIconUrl;
// L.Icon.Default.mergeOptions({
//   iconRetinaUrl:
//     "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
//   iconUrl:
//     "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
//   shadowUrl:
//     "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
// });

// // Define India's boundaries
// const indiaBounds = L.latLngBounds(
//   L.latLng(6.4626999, 68.1097), // Southwest corner
//   L.latLng(35.6745457, 97.395561) // Northeast corner
// );

// export default function IndiaMap() {
//   const [selectedState, setSelectedState] = useState(null);
//   const [selectedPoint, setSelectedPoint] = useState(null);
//   const [error, setError] = useState(null);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [refreshData, setRefreshData] = useState(false);
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [location,setLocation]=useState([])
//   const [severity, setSeverity] = useState("success");

//   const mapRef = useRef(null);
//   const svgRef = useRef(null);

//   const [viewport, setViewport] = useState({
//     center: [20.5937, 78.9629],
//     zoom: 4.5,
//   });
//   const handleSnackbarOpen = (message, severity) => {
//     setSnackbarMessage(message);
//     setSeverity(severity);
//     setOpenSnackbar(true);
//   };

//   useEffect(() => {
//     const getShift = async () => {
//       try {
//         const result = await apiGetLocation();
//         console.log("factory points data:", result.data.data);
//         setLocation(result.data.data);
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getShift();
//   }, [refreshData]);
//   const handleFactoryPointClick = (point) => {
//     setSelectedState(point.state);
//     setSelectedPoint(point);
//     if (mapRef.current) {
//       mapRef.current.setView(point.coordinates, 7);
//     }
//   };

//   const resetView = () => {
//     setSelectedState(null);
//     setSelectedPoint(null);
//     if (mapRef.current) {
//       mapRef.current.setView(viewport.center, viewport.zoom);
//     }
//   };

//   const resetHighlight = () => {
//     setSelectedState(null);
//     setSelectedPoint(null);
//   };

//   const onEachFeature = (feature, layer) => {
//     layer.on({
//       click: resetHighlight,
//     });
//   };

//   const getStateStyle = (feature) => {
//     const isSelected = selectedState === feature.properties.st_nm;
//     return {
//       color: "#4a83ec",
//       weight: 4,
//       fillColor: isSelected ? "#f00" : "#1a1d62",
//       fillOpacity: isSelected ? 10 : 0.3,
//       filter: isSelected ? "url(#drop-shadow)" : "none",
//       transform: isSelected ? "translate(0px, -5px)" : "none",
//       transition: "all 0.3s ease-out",
//     };
//   };

//   useEffect(() => {
//     if (mapRef.current && svgRef.current) {
//       mapRef.current.getPanes().overlayPane.appendChild(svgRef.current);
//     }
//   }, []);

//   useEffect(() => {
//     if (mapRef.current) {
//       mapRef.current.eachLayer((layer) => {
//         if (layer instanceof L.GeoJSON) {
//           layer.setStyle(getStateStyle);
//         }
//       });
//     }
//   }, [selectedState]);

//   return (
//     <>
//       <div style={{ position: 'relative', height: '100vh', width: '100%' }}>
//         <MapContainer
//           ref={mapRef}
//           center={viewport.center}
//           zoom={viewport.zoom}
//           style={{ height: '100%', width: '100%' }}
//           maxBounds={indiaBounds}
//           minZoom={4}
//         >
//           <TileLayer
//             url="https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}.jpg"
//             attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>.'
//             maxZoom={18}
//           />
//           {factoryPoints.map((point, index) => (
//             (!selectedPoint || selectedPoint === point) && (
//               <Marker
//                 key={index}
//                 position={[point.coordinates[0], point.coordinates[1]]}
//                 eventHandlers={{
//                   click: () => handleFactoryPointClick(point),
//                 }}
//               >
//                 <Popup>{point.name}</Popup>
//               </Marker>
//             )
//           ))}
//           {indiaGeoJson && (
//             <GeoJSON
//               data={indiaGeoJson}
//               style={getStateStyle}
//               onEachFeature={onEachFeature}
//               zIndexOffset={selectedState ? 1000 : 0}
//             />
//           )}
//         </MapContainer>
//         {selectedPoint && (
//           <button
//             onClick={resetView}
//             style={{
//               position: 'absolute',
//               top: '20px',
//               right: '20px',
//               zIndex: 1000,
//               padding: '10px',
//             }}
//           >
//             Reset View
//           </button>
//         )}
//       </div>
//       <svg ref={svgRef} height="0" width="0" style={{ position: 'absolute' }}>
//         <defs>
//           <filter id="drop-shadow">
//             <feDropShadow dx="0" dy="0" stdDeviation="2" floodOpacity="0.5" />
//           </filter>
//         </defs>
//       </svg>
//       <style jsx global>{`
//         .leaflet-tile-pane {
//           z-index: 20;
//         }
//         .leaflet-overlay-pane {
//           z-index: 50;
//         }
//       `}</style>
//     </>
//   );
// }

// import React, { useState, useRef, useEffect } from "react";
// import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from "react-leaflet";
// import L from "leaflet";
// import indiaGeoJson from "../assets/states_india(1).json";
// import { apiGetLocation } from "../api/api.getLocation";
// import { apiGetLocationMiniDashboard } from "../api/api.getlocationminidashboard";

// import "leaflet/dist/leaflet.css";

// // Setup Leaflet default icon
// delete L.Icon.Default.prototype._getIconUrl;
// L.Icon.Default.mergeOptions({
//   iconRetinaUrl:
//     "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
//   iconUrl:
//     "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
//   shadowUrl:
//     "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
// });

// // Define India's boundaries
// const indiaBounds = L.latLngBounds(
//   L.latLng(6.4626999, 68.1097), // Southwest corner
//   L.latLng(35.6745457, 97.395561) // Northeast corner
// );

// export default function IndiaMap() {
//   const [selectedState, setSelectedState] = useState(null);
//   const [selectedPoint, setSelectedPoint] = useState(null);
//   const [error, setError] = useState(null);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [refreshData, setRefreshData] = useState(false);
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [factoryPoints, setFactoryPoints] = useState([]);
//   const [locationId,setLocationId]=useState("")
//   const [miniDashboardData, setMiniDashBoardData] = useState([]);

//   const [severity, setSeverity] = useState("success");

//   const mapRef = useRef(null);
//   const svgRef = useRef(null);

//   const [viewport, setViewport] = useState({
//     center: [20.5937, 78.9629],
//     zoom: 4.5,
//   });

//   const handleSnackbarOpen = (message, severity) => {
//     setSnackbarMessage(message);
//     setSeverity(severity);
//     setOpenSnackbar(true);
//   };

//   useEffect(() => {
//     const getFactoryPoints = async () => {
//       try {
//         const result = await apiGetLocation();
//         console.log("API Response:", result.data.data);
//         const transformedPoints = result.data.data.map((item) => ({
//           name: item.lineName,
//           coordinates: [item.lat, item.long],
//           state: item.stateName,
//           locationId: item.locationId,
//         }));

//         setFactoryPoints(transformedPoints);
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getFactoryPoints();
//   }, [refreshData]);
//   useEffect(() => {
//     const getMiniDashBorad = async () => {
//       try {
//         const result = await apiGetLocationMiniDashboard();
//         console.log("shiftdata", result.data.data);
//         miniDashboardData(result.data.data);
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getMiniDashBorad();
//   }, [refreshData]);
//   const handleFactoryPointClick = (point) => {
//     setSelectedState(point.state);
//     setLocationId(point.locationId)
//     console.log(locationId)
//     setSelectedPoint(point);
//     if (mapRef.current) {
//       mapRef.current.setView(point.coordinates, 7);
//     }
//   };

//   const resetView = () => {
//     setSelectedState(null);
//     setSelectedPoint(null);
//     if (mapRef.current) {
//       mapRef.current.setView(viewport.center, viewport.zoom);
//     }
//   };

//   const resetHighlight = () => {
//     setSelectedState(null);
//     setSelectedPoint(null);
//   };

//   const onEachFeature = (feature, layer) => {
//     layer.on({
//       click: resetHighlight,
//     });
//   };

//   const getStateStyle = (feature) => {
//     const isSelected = selectedState === feature.properties.st_nm;
//     return {
//       color: "#4a83ec",
//       weight: 4,
//       fillColor: isSelected ? "#f00" : "#1a1d62",
//       fillOpacity: isSelected ? 10 : 0.3,
//       filter: isSelected ? "url(#drop-shadow)" : "none",
//       transform: isSelected ? "translate(0px, -5px)" : "none",
//       transition: "all 0.3s ease-out",
//     };
//   };

//   useEffect(() => {
//     if (mapRef.current && svgRef.current) {
//       mapRef.current.getPanes().overlayPane.appendChild(svgRef.current);
//     }
//   }, []);

//   useEffect(() => {
//     if (mapRef.current) {
//       mapRef.current.eachLayer((layer) => {
//         if (layer instanceof L.GeoJSON) {
//           layer.setStyle(getStateStyle);
//         }
//       });
//     }
//   }, [selectedState]);

//   return (
//     <>
//       <div style={{ position: 'relative', height: '100vh', width: '100%' }}>
//         <MapContainer
//           ref={mapRef}
//           center={viewport.center}
//           zoom={viewport.zoom}
//           style={{ height: '100%', width: '100%' }}
//           maxBounds={indiaBounds}
//           minZoom={4}
//         >
//           <TileLayer
//             url="https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}.jpg"
//             attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>.'
//             maxZoom={18}
//           />
//           {factoryPoints.length > 0 && factoryPoints.map((point, index) => (
//             (!selectedPoint || selectedPoint === point) && (
//               <Marker
//                 key={index}
//                 position={[point.coordinates[0], point.coordinates[1]]}
//                 eventHandlers={{
//                   click: () => handleFactoryPointClick(point),
//                 }}
//               >
//                 <Popup>{point.name}</Popup>
//               </Marker>
//             )
//           ))}
//           {indiaGeoJson && (
//             <GeoJSON
//               data={indiaGeoJson}
//               style={getStateStyle}
//               onEachFeature={onEachFeature}
//               zIndexOffset={selectedState ? 1000 : 0}
//             />
//           )}
//         </MapContainer>
//         {selectedPoint && (
//           <button
//             onClick={resetView}
//             style={{
//               position: 'absolute',
//               top: '20px',
//               right: '20px',
//               zIndex: 1000,
//               padding: '10px',
//             }}
//           >
//             Reset View
//           </button>
//         )}
//       </div>
//       <svg ref={svgRef} height="0" width="0" style={{ position: 'absolute' }}>
//         <defs>
//           <filter id="drop-shadow">
//             <feDropShadow dx="0" dy="0" stdDeviation="2" floodOpacity="0.5" />
//           </filter>
//         </defs>
//       </svg>
//       <style jsx global>{`
//         .leaflet-tile-pane {
//           z-index: 20;
//         }
//         .leaflet-overlay-pane {
//           z-index: 50;
//         }
//       `}</style>
//     </>
//   );
// }

// import React, { useState, useRef, useEffect } from "react";
// import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from "react-leaflet";
// import L from "leaflet";
// import indiaGeoJson from "../assets/states_india(1).json";
// import { apiGetLocation } from "../api/api.getLocation";

// import "leaflet/dist/leaflet.css";

// const factoryPoints = [
//   { name: "Pune, Maharashtra", coordinates: [18.5204, 73.8567], state: "Maharashtra" },
//   { name: "Luhari Block A and B, Haryana", coordinates: [28.1732, 76.4156], state: "Haryana" },
//   { name: "Bhiwandi, Maharashtra", coordinates: [19.2813, 73.3483], state: "Maharashtra" },
//   { name: "Nashik, Maharashtra", coordinates: [19.9975, 73.7898], state: "Maharashtra" },
//   { name: "Chennai, Tamil Nadu", coordinates: [13.0827, 79.9707], state: "Tamil Nadu" },
//   { name: "Hyderabad, Telangana", coordinates: [17.4065, 78.4772], state: "Telangana" },
// ];

// delete L.Icon.Default.prototype._getIconUrl;
// L.Icon.Default.mergeOptions({
//   iconRetinaUrl:
//     "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
//   iconUrl:
//     "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
//   shadowUrl:
//     "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
// });

// // Define India's boundaries
// const indiaBounds = L.latLngBounds(
//   L.latLng(6.4626999, 68.1097), // Southwest corner
//   L.latLng(35.6745457, 97.395561) // Northeast corner
// );

// export default function IndiaMap() {
//   const [selectedState, setSelectedState] = useState(null);
//   const [selectedPoint, setSelectedPoint] = useState(null);
//   const [error, setError] = useState(null);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [refreshData, setRefreshData] = useState(false);
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [location,setLocation]=useState([])
//   const [severity, setSeverity] = useState("success");

//   const mapRef = useRef(null);
//   const svgRef = useRef(null);

//   const [viewport, setViewport] = useState({
//     center: [20.5937, 78.9629],
//     zoom: 4.5,
//   });
//   const handleSnackbarOpen = (message, severity) => {
//     setSnackbarMessage(message);
//     setSeverity(severity);
//     setOpenSnackbar(true);
//   };

//   useEffect(() => {
//     const getShift = async () => {
//       try {
//         const result = await apiGetLocation();
//         console.log("factory points data:", result.data.data);
//         setLocation(result.data.data);
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getShift();
//   }, [refreshData]);
//   const handleFactoryPointClick = (point) => {
//     setSelectedState(point.state);
//     setSelectedPoint(point);
//     if (mapRef.current) {
//       mapRef.current.setView(point.coordinates, 7);
//     }
//   };

//   const resetView = () => {
//     setSelectedState(null);
//     setSelectedPoint(null);
//     if (mapRef.current) {
//       mapRef.current.setView(viewport.center, viewport.zoom);
//     }
//   };

//   const resetHighlight = () => {
//     setSelectedState(null);
//     setSelectedPoint(null);
//   };

//   const onEachFeature = (feature, layer) => {
//     layer.on({
//       click: resetHighlight,
//     });
//   };

//   const getStateStyle = (feature) => {
//     const isSelected = selectedState === feature.properties.st_nm;
//     return {
//       color: "#4a83ec",
//       weight: 4,
//       fillColor: isSelected ? "#f00" : "#1a1d62",
//       fillOpacity: isSelected ? 10 : 0.3,
//       filter: isSelected ? "url(#drop-shadow)" : "none",
//       transform: isSelected ? "translate(0px, -5px)" : "none",
//       transition: "all 0.3s ease-out",
//     };
//   };

//   useEffect(() => {
//     if (mapRef.current && svgRef.current) {
//       mapRef.current.getPanes().overlayPane.appendChild(svgRef.current);
//     }
//   }, []);

//   useEffect(() => {
//     if (mapRef.current) {
//       mapRef.current.eachLayer((layer) => {
//         if (layer instanceof L.GeoJSON) {
//           layer.setStyle(getStateStyle);
//         }
//       });
//     }
//   }, [selectedState]);

//   return (
//     <>
//       <div style={{ position: 'relative', height: '100vh', width: '100%' }}>
//         <MapContainer
//           ref={mapRef}
//           center={viewport.center}
//           zoom={viewport.zoom}
//           style={{ height: '100%', width: '100%' }}
//           maxBounds={indiaBounds}
//           minZoom={4}
//         >
//           <TileLayer
//             url="https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}.jpg"
//             attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>.'
//             maxZoom={18}
//           />
//           {factoryPoints.map((point, index) => (
//             (!selectedPoint || selectedPoint === point) && (
//               <Marker
//                 key={index}
//                 position={[point.coordinates[0], point.coordinates[1]]}
//                 eventHandlers={{
//                   click: () => handleFactoryPointClick(point),
//                 }}
//               >
//                 <Popup>{point.name}</Popup>
//               </Marker>
//             )
//           ))}
//           {indiaGeoJson && (
//             <GeoJSON
//               data={indiaGeoJson}
//               style={getStateStyle}
//               onEachFeature={onEachFeature}
//               zIndexOffset={selectedState ? 1000 : 0}
//             />
//           )}
//         </MapContainer>
//         {selectedPoint && (
//           <button
//             onClick={resetView}
//             style={{
//               position: 'absolute',
//               top: '20px',
//               right: '20px',
//               zIndex: 1000,
//               padding: '10px',
//             }}
//           >
//             Reset View
//           </button>
//         )}
//       </div>
//       <svg ref={svgRef} height="0" width="0" style={{ position: 'absolute' }}>
//         <defs>
//           <filter id="drop-shadow">
//             <feDropShadow dx="0" dy="0" stdDeviation="2" floodOpacity="0.5" />
//           </filter>
//         </defs>
//       </svg>
//       <style jsx global>{`
//         .leaflet-tile-pane {
//           z-index: 20;
//         }
//         .leaflet-overlay-pane {
//           z-index: 50;
//         }
//       `}</style>
//     </>
//   );
// }


//-----------------------------------------------best working--------------------------------------//

import React, { useState, useRef, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from "react-leaflet";
import L from "leaflet";
import indiaGeoJson from "../assets/states_india(1).json";
import { apiGetLocation } from "../api/api.getLocation";
import { apiGetLocationMiniDashboard } from "../api/api.getlocationminidashboard";
import {
  faBolt,
  faGasPump,
  faWind,
  faTint,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Button,
  Modal,
  Paper,
  Box,
  Typography,
  Grid,
  ThemeProvider,
  createTheme,
} from "@mui/material";

import "leaflet/dist/leaflet.css";

// Setup Leaflet default icon
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#bb86fc",
    },
    secondary: {
      main: "#03dac6",
    },
    background: {
      paper: "rgba(18, 18, 18, 0.8)", // Semi-transparent dark background
    },
    text: {
      primary: "#ffffff",
      secondary: "#bb86fc",
    },
  },
});

const DataItem = ({ label, value, unit }) => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="body2" color="text.secondary">
      {label}
    </Typography>
    <Typography variant="h6" component="span" color="primary">
      {value}
    </Typography>
    <Typography
      variant="body1"
      component="span"
      sx={{ ml: 1 }}
      color="text.primary"
    >
      {unit}
    </Typography>
  </Box>
);

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});
const redIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});
// Define India's boundaries
const indiaBounds = L.latLngBounds(
  L.latLng(6.4626999, 68.1097), // Southwest corner
  L.latLng(35.6745457, 97.395561) // Northeast corner
);

export default function IndiaMap() {
  const [selectedState, setSelectedState] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [error, setError] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [factoryPoints, setFactoryPoints] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [miniDashboardData, setMiniDashboardData] = useState({});
  const [severity, setSeverity] = useState("success");
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const mapRef = useRef(null);
  const svgRef = useRef(null);

  const [viewport, setViewport] = useState({
    center: [20.5937, 78.9629],
    zoom: 4.5,
  });

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  useEffect(() => {
    const getFactoryPoints = async () => {
      try {
        const result = await apiGetLocation();
        console.log("API Response:", result.data.data);
        const transformedPoints = result.data.data.map((item) => ({
          name: item.lineName,
          coordinates: [item.lat, item.long],
          state: item.stateName,
          locationId: item.locationId, // Assuming locationId is available in the response
        }));

        setFactoryPoints(transformedPoints);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getFactoryPoints();
  }, [refreshData]);

  const getMiniDashboardData = async (id) => {
    try {
      const result = await apiGetLocationMiniDashboard(id);
      console.log("Mini Dashboard Data:", result.data.data);
      setMiniDashboardData(result.data.data[0]);
    } catch (error) {
      setError(error.message);
      handleSnackbarOpen(error.message, "error");
    }
  };

  const handleFactoryPointClick = (point) => {
    setSelectedState(point.state);
    setLocationId(point.locationId);
    setSelectedPoint(point);
    console.log("Selected Location ID:", point.locationId);
    getMiniDashboardData(point.locationId); // Fetch additional data using locationId
    if (mapRef.current) {
      mapRef.current.setView(point.coordinates, 7);
    }
  };

  const resetView = () => {
    setSelectedState(null);
    setSelectedPoint(null);
    setMiniDashboardData({});
    if (mapRef.current) {
      mapRef.current.setView(viewport.center, viewport.zoom);
    }
  };

  const resetHighlight = () => {
    setSelectedState(null);
    setSelectedPoint(null);
    setMiniDashboardData({});
  };

  const onEachFeature = (feature, layer) => {
    layer.on({
      click: resetHighlight,
    });
  };

  const getStateStyle = (feature) => {
    const isSelected = selectedState === feature.properties.st_nm;
    return {
      color: "#4a83ec",
      weight: 4,
      fillColor: isSelected ? "#f00" : "#1a1d62",
      fillOpacity: isSelected ? 20 : 0.3,
      filter: isSelected ? "url(#drop-shadow)" : "none",
      transform: isSelected ? "translate(0px, -5px)" : "none",
      transition: "all 0.3s ease-out",
    };
  };

  useEffect(() => {
    if (mapRef.current && svgRef.current) {
      mapRef.current.getPanes().overlayPane.appendChild(svgRef.current);
    }
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.GeoJSON) {
          layer.setStyle(getStateStyle);
        }
      });
    }
  }, [selectedState]);
  const handleFacilityDetails = () => {
    if (selectedPoint && miniDashboardData) {
      const facilityData = {
        ...miniDashboardData,  // Spread all the API data
        lineName: selectedPoint.name,  // Add the lineName
        locationId: selectedPoint.locationId,  // Add the locationId if needed
      };
  
      navigate('/facilitysustainability', { state: facilityData });
    } else {
      console.error('Selected point or miniDashboardData is missing');
      // Optionally, show an error message to the user
    }
  };
  return (
    <ThemeProvider theme={darkTheme}>
      <div style={{ position: "relative", height: "100vh", width: "100%" }}>
        <MapContainer
          ref={mapRef}
          center={viewport.center}
          zoom={viewport.zoom}
          style={{ height: "100%", width: "100%" }}
          maxBounds={indiaBounds}
          minZoom={4}
        >
          <TileLayer
            url="https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}.jpg"
            attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>.'
            maxZoom={18}
          />
          {factoryPoints.length > 0 &&
            factoryPoints.map(
              (point, index) =>
                (!selectedPoint || selectedPoint === point) && (
                  <Marker
                    key={index}
                    position={[point.coordinates[0], point.coordinates[1]]}
                    eventHandlers={{
                      click: () => handleFactoryPointClick(point),
                    }}
                    icon={redIcon}
                  >
                    <Popup>{point.name}</Popup>
                  </Marker>
                )
            )}
          {indiaGeoJson && (
            <GeoJSON
              data={indiaGeoJson}
              style={getStateStyle}
              onEachFeature={onEachFeature}
              zIndexOffset={selectedState ? 1000 : 0}
            />
          )}
        </MapContainer>
        {selectedPoint && (
          <Box
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              zIndex: 1000,
            }}
          >
            <Button
              onClick={resetView}
              variant="contained"
              sx={{ mb: 1, width: "100%" }}
            >
              Reset View
            </Button>
            <Button
              onClick={handleOpenModal}
              variant="contained"
              color="secondary"
              sx={{ width: "100%" }}
            >
              Fetch Details
            </Button>
          </Box>
        )}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {/* <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              maxHeight: "90vh",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "auto",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{ mb: 3, color: "secondary.main" }}
            >
           
              {selectedPoint?.name || 'Selected Point'}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mb: 2, color: "primary.main" }}>
                  <FontAwesomeIcon icon={faBolt} /> Energy Management
                </Typography>
                <Grid container spacing={2}>
                  {["1", "2"].map((level) => (
                    <Grid item xs={6} key={level}>
                      <Paper elevation={3} sx={{ p: 2 }}>
                        <Typography variant="subtitle1">
                          Level {level}
                        </Typography>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <DataItem
                              label="Energy"
                              value={level === "1" ? "170908" : "0"}
                              unit="KWH"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DataItem
                              label="Power Factor"
                              value={level === "1" ? "-1" : "0"}
                              unit="KVA"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DataItem
                              label="Current"
                              value={level === "1" ? "15" : "0"}
                              unit="A"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DataItem
                              label="Voltage"
                              value={level === "1" ? "228" : "0"}
                              unit="V"
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mb: 2, color: "primary.main" }}>
                  <FontAwesomeIcon icon={faGasPump} /> Diesel Genset
                </Typography>
                <Grid container spacing={2}>
                  {["1", "2"].map((level) => (
                    <Grid item xs={6} key={level}>
                      <Paper elevation={3} sx={{ p: 2 }}>
                        <Typography variant="subtitle1">
                          Genset {level}
                        </Typography>
                        <DataItem label="Fuel Level" value="60" unit="%" />
                        <DataItem
                          label="Active Power"
                          value={level === "1" ? "-1" : "0"}
                          unit="KWH"
                        />
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mb: 2, color: "primary.main" }}>
                  <FontAwesomeIcon icon={faWind} /> Air Quality
                </Typography>
                <Grid container spacing={2}>
                  {["1", "2"].map((level) => (
                    <Grid item xs={6} key={level}>
                      <Paper elevation={3} sx={{ p: 2 }}>
                        <Typography variant="subtitle1">
                          Sensor {level}
                        </Typography>
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <DataItem
                              label="Temperature"
                              value="32"
                              unit="°C"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <DataItem label="Humidity" value="40" unit="%" />
                          </Grid>
                          <Grid item xs={4}>
                            <DataItem
                              label="CO2"
                              value={level === "1" ? "47" : "49"}
                              unit="PPM"
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mb: 2, color: "primary.main" }}>
                  <FontAwesomeIcon icon={faTint} /> Water Management
                </Typography>
                <Grid container spacing={2}>
                  {["1", "2"].map((level) => (
                    <Grid item xs={6} key={level}>
                      <Paper elevation={3} sx={{ p: 2 }}>
                        <Typography variant="subtitle1">
                          Flow Meter {level}
                        </Typography>
                        <DataItem
                          label="Water Flow"
                          value={level === "1" ? "1150" : "2215"}
                          unit="Litres"
                        />
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box> */}
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              maxHeight: "90vh",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "auto",
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{ color: "secondary.main" }}
            >
              {selectedPoint?.name || 'Selected Point'}
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleFacilityDetails}
              sx={{ ml: 2 }}
            >
              Facility Details
            </Button>
          </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mb: 2, color: "primary.main" }}>
                  <FontAwesomeIcon icon={faBolt} /> Energy Management
                </Typography>
                <Grid container spacing={2}>
                  {["1", "2"].map((level) => (
                    <Grid item xs={6} key={level}>
                      <Paper elevation={3} sx={{ p: 2 }}>
                        <Typography variant="subtitle1">
                          Sensor {level}
                        </Typography>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <DataItem
                              label="Energy"
                              value={miniDashboardData[`kwh_${level}`] || "0"}
                              unit="KWH"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DataItem
                              label="Power Factor"
                              value={miniDashboardData[`pf_${level}`] || "0"}
                              unit="KVA"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DataItem
                              label="Current"
                              value={
                                miniDashboardData[`currentR_${level}`] || "0"
                              }
                              unit="A"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DataItem
                              label="Voltage"
                              value={
                                miniDashboardData[`voltageR_${level}`] || "0"
                              }
                              unit="V"
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mb: 2, color: "primary.main" }}>
                  <FontAwesomeIcon icon={faGasPump} /> Diesel Genset
                </Typography>
                <Grid container spacing={2}>
                  {["1", "2"].map((level) => (
                    <Grid item xs={6} key={level}>
                      <Paper elevation={3} sx={{ p: 2 }}>
                        <Typography variant="subtitle1">
                          Genset {level}
                        </Typography>
                        <DataItem
                          label="Fuel Level"
                          value={miniDashboardData[`fuelLevel_${level}`] || "0"}
                          unit="%"
                        />
                        <DataItem
                          label="Active Power"
                          value={
                            miniDashboardData[
                              `gensetTotalActivePower_${level}`
                            ] || "0"
                          }
                          unit="KWH"
                        />
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mb: 2, color: "primary.main" }}>
                  <FontAwesomeIcon icon={faWind} /> Air Quality
                </Typography>
                <Grid container spacing={2}>
                  {["1", "2"].map((level) => (
                    <Grid item xs={6} key={level}>
                      <Paper elevation={3} sx={{ p: 2 }}>
                        <Typography variant="subtitle1">
                          Sensor {level}
                        </Typography>
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <DataItem
                              label="Temperature"
                              value={
                                miniDashboardData[`temperature_${level}`] || "0"
                              }
                              unit="°C"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <DataItem
                              label="Humidity"
                              value={
                                miniDashboardData[`humidity_${level}`] || "0"
                              }
                              unit="%"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <DataItem
                              label="CO2"
                              value={miniDashboardData[`co2_${level}`] || "0"}
                              unit="PPM"
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mb: 2, color: "primary.main" }}>
                  <FontAwesomeIcon icon={faTint} /> Water Management
                </Typography>
                <Grid container spacing={2}>
                  {["1", "2"].map((level) => (
                    <Grid item xs={6} key={level}>
                      <Paper elevation={3} sx={{ p: 2 }}>
                        <Typography variant="subtitle1">
                          Flow Meter {level}
                        </Typography>
                        <DataItem
                          label="Water Flow"
                          value={miniDashboardData[`totalFlow_${level}`] || "0"}
                          unit="Litres"
                        />
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
      <svg ref={svgRef} height="0" width="0" style={{ position: "absolute" }}>
        <defs>
          <filter id="drop-shadow">
            <feDropShadow dx="0" dy="0" stdDeviation="2" floodOpacity="0.5" />
          </filter>
        </defs>
      </svg>
      <style jsx global>{`
        .leaflet-tile-pane {
          z-index: 20;
        }
        .leaflet-overlay-pane {
          z-index: 50;
        }
      `}</style>
    </ThemeProvider>
  );
}
