import React from "react";
import { HashRouter as Router, Route, useLocation } from "react-router-dom";
import NavBar from "./components/navbar";
import MyRoutes from "./routes/routes";
import HeadMenu from "./components/headmenu";
import 'core-js/stable';
import 'regenerator-runtime/runtime';


function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  const isLoginPage =
    location.pathname === "/login" || location.pathname === "/";

  return (
    <div>
      {!isLoginPage && <HeadMenu /> && <NavBar />}
      <MyRoutes />
    </div>
  );
}

export default App;
