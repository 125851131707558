// import React from 'react';
// import { Button, Grid, Paper, styled } from '@mui/material';
// import Chart from 'react-apexcharts';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";


// const StyledBox = styled(Paper)({
//   padding: '4px',
//   textAlign: 'center',
//   color: 'inherit',
//   height:'300px'
// });

// const GraphChart = () => {
//   return (
//     <StyledBox>
//       {/* Graph Chart */}
//       <Chart
//         options={{
//           chart: {
//             id: 'basic-area'
//           },
//           xaxis: {
//             categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
//           }
//         }}
//         series={[
//           {
//             name: 'Series 1',
//             data: [30, 40, 45, 50, 49, 60, 70, 91, 125]
//           }
//         ]}
//         type="area"
//         width="100%"
//       />
//     </StyledBox>
//   );
// };

// const BlankBox = () => {
//   return (
//     <StyledBox>
//       {/* Leave this box blank */}
//     </StyledBox>
//   );
// };

// const TableBox = () => {
//   return (
   
//     <StyledBox>
//       <TableContainer component={Paper}>
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>Column 1</TableCell>
//             <TableCell>Column 2</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           <TableRow>
//             <TableCell>Data 1</TableCell>
//             <TableCell>Data 2</TableCell>
//           </TableRow>
//           <TableRow>
//             <TableCell>Data 3</TableCell>
//             <TableCell>Data 4</TableCell>
//           </TableRow>
//           {/* Add more rows as needed */}
//         </TableBody>
//       </Table>
//     </TableContainer>
//     </StyledBox>
//   );
// };

// const CBM = () => {
//   return (
//     <div>
//     <Grid container spacing={3} paddingLeft="100px">
//       {/* <Grid item xs={2}>

       
//       </Grid> */}
//       <Grid item xs={9}paddingTop="40px" paddingRight="30px" paddingLeft="20px">
//         {/* Main Content */}
//         <Grid container spacing={3} paddingTop="40px" paddingLeft="20px"  paddingBottom="40px">
//           {/* Box 1 - Multi-axis chart */}
//           <Grid container spacing={3}>
//           {/* Box 1 - Multi-axis chart */}
//           <Grid item xs={4} padding="0" >
//             <StyledBox>
//               <Chart
//                 options={{
//                   chart: {
//                     id: 'basic-bar'
//                   },
//                   xaxis: {
//                     categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
//                   }
//                 }}
//                 series={[
//                   {
//                     name: 'Series 1',
//                     data: [30, 40, 45, 50, 49, 60, 70, 91, 125]
//                   },
//                   {
//                     name: 'Series 2',
//                     data: [20, 35, 42, 45, 50, 55, 60, 70, 80]
//                   }
//                 ]}
//                 type="line"
//                 width="100%"
//               />
//             </StyledBox>
//           </Grid>
//           {/* Box 2 - Line chart */}
//           <Grid item xs={4}>
//             <StyledBox>
//               <Chart
//                 options={{
//                   chart: {
//                     id: 'basic-line'
//                   },
//                   xaxis: {
//                     categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
//                   }
//                 }}
//                 series={[
//                   {
//                     name: 'Series 1',
//                     data: [30, 40, 45, 50, 49, 60, 70, 91, 125]
//                   }
//                 ]}
//                 type="line"
//                 width="100%"
//               />
//             </StyledBox>
//           </Grid>

//           {/* Box 3 - Radial chart */}
//           <Grid item xs={4}>
//             <StyledBox>
//               <Chart
//                 options={{
//                   labels: ['Team A', 'Team B', 'Team C', 'Team D']
//                 }}
//                 series={[44, 55, 41, 17]}
//                 type="donut"
//                 width="100%"
//               />
//             </StyledBox>
//           </Grid>

//           {/* Box 4 - Column chart */}
//           <Grid item xs={4}>
//             <StyledBox>
//               <Chart
//                 options={{
//                   chart: {
//                     id: 'basic-bar'
//                   },
//                   xaxis: {
//                     categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
//                   }
//                 }}
//                 series={[
//                   {
//                     name: 'Series 1',
//                     data: [30, 40, 45, 50, 49, 60, 70, 91, 125]
//                   }
//                 ]}
//                 type="bar"
//                 width="100%"
//               />
//             </StyledBox>
//           </Grid>

//           {/* Box 5 - Graph chart */}
//           <Grid item xs={4}>
//             <GraphChart />
//           </Grid>

//           {/* Box 6 - Blank */}
//           <Grid item xs={4}>
//             <BlankBox />
//           </Grid>

//           {/* Box 7 - Graph chart */}
//           <Grid item xs={4}>
//             <GraphChart />
//           </Grid>

//           {/* Box 8 - Table */}
//           <Grid item xs={4}>
//             <TableBox />
//           </Grid>
//         </Grid>
//       </Grid>
//     </Grid>
//     </Grid>
//     </div>
//   );
// };

// export default CBM;


import React from 'react';
import { Button, Grid, Paper, styled } from '@mui/material';
import Chart from 'react-apexcharts';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";


const StyledBox = styled(Paper)({
  padding: '4px',
  textAlign: 'center',
  color: 'inherit',
  height: '270px', // Adjust the height as needed
  width: '100%', // Adjust the width as needed
});

const GraphChart = () => {
  return (
    <StyledBox>
      {/* Graph Chart */}
      <Chart
        options={{
          chart: {
            id: 'basic-area'
          },
          xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
          }
        }}
        series={[
          {
            name: 'Series 1',
            data: [30, 40, 45, 50, 49, 60, 70, 91, 125]
          }
        ]}
        type="area"
        width="100%"
      />
    </StyledBox>
  );
};

const BlankBox = () => {
  return (
    <StyledBox>
      {/* Leave this box blank */}
    </StyledBox>
  );
};

const TableBox = () => {
  return (
   
    <StyledBox>
      <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Column 1</TableCell>
            <TableCell>Column 2</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Data 1</TableCell>
            <TableCell>Data 2</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Data 3</TableCell>
            <TableCell>Data 4</TableCell>
          </TableRow>
          {/* Add more rows as needed */}
        </TableBody>
      </Table>
    </TableContainer>
    </StyledBox>
  );
};

const CBM = () => {
  return (
    <div  style={{ padding: "0px 20px", backgroundColor: "#E8E8E8" ,}}>
    <Grid container spacing={3} >
      {/* <Grid item xs={2}>

       
      </Grid> */}
      <Grid item xs={12}>
        {/* Main Content */}
        <div style={{display:'flex',justifyContent:'flex-end' ,padding:'4px 10px'}}>
          <Button style={{color:'black',fontWeight:'600'}}>View Reports</Button>
        </div>
        <Grid container spacing={3} paddingLeft="20px" paddingRight="20px"paddingBottom="40px">
          {/* Box 1 - Multi-axis chart */}
          
          <Grid item xs={3} padding="0" >
            <StyledBox>
              <Chart
                options={{
                  chart: {
                    id: 'basic-bar'
                  },
                  xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                  }
                }}
                series={[
                  {
                    name: 'Series 1',
                    data: [30, 40, 45, 50, 49, 60, 70, 91, 125]
                  },
                  {
                    name: 'Series 2',
                    data: [20, 35, 42, 45, 50, 55, 60, 70, 80]
                  }
                ]}
                type="line"
                width="100%"
              />
            </StyledBox>
          </Grid>
          {/* Box 2 - Line chart */}
          <Grid item xs={3}>
            <StyledBox>
              <Chart
                options={{
                  chart: {
                    id: 'basic-line'
                  },
                  xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                  }
                }}
                series={[
                  {
                    name: 'Series 1',
                    data: [30, 40, 45, 50, 49, 60, 70, 91, 125]
                  }
                ]}
                type="line"
                width="100%"
              />
            </StyledBox>
          </Grid>

          {/* Box 3 - Radial chart */}
          <Grid item xs={3}>
            <StyledBox>
              <Chart
                options={{
                  labels: ['Team A', 'Team B', 'Team C', 'Team D']
                }}
                series={[44, 55, 41, 17]}
                type="donut"
                width="100%"
              />
            </StyledBox>
          </Grid>

          {/* Box 4 - Column chart */}
          <Grid item xs={3}>
            <StyledBox>
              <Chart
                options={{
                  chart: {
                    id: 'basic-bar'
                  },
                  xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                  }
                }}
                series={[
                  {
                    name: 'Series 1',
                    data: [30, 40, 45, 50, 49, 60, 70, 91, 125]
                  }
                ]}
                type="bar"
                width="100%"
              />
            </StyledBox>
          </Grid>

          {/* Box 5 - Graph chart */}
          <Grid item xs={3}>
            <GraphChart />
          </Grid>

          {/* Box 6 - Blank */}
          <Grid item xs={3}>
            <BlankBox />
          </Grid>

          {/* Box 7 - Graph chart */}
          <Grid item xs={3}>
            <GraphChart />
          </Grid>

          {/* Box 8 - Table */}
          <Grid item xs={3}>
            <TableBox />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    
    </div>
  );
};

export default CBM;
