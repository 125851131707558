import axios from "axios";
import { baseUrl } from "./baseUrl";

export const apiUpdateMachineInput = async (updatedMachineInput) => {
//   console.log(updatedDtimeData);
  const url = baseUrl + `/partproduction/updatePartProductionAndCycleTime/`;

  try {
    const token = localStorage.getItem("token");

    const datas = await axios.put(
      url,
      {
        id:updatedMachineInput.id,
        partId: updatedMachineInput.partId,
        cycleTime: updatedMachineInput.cycleTime,
      
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return datas;
  } catch (error) {
    console.error("Error during adding machine downtime:", error);
    throw error;
  }
};
