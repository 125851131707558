import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, tableCellClasses,TablePagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
// import { apiFrdc } from '../api/api.frdc';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import { apiGetFRDC } from '../api/api.getfrdc';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Skeleton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function FrdcMaster() {
    const [addOpen, setAddOpen] = useState(false);
  const [lineName, setLineName] = useState("");
  const [plantName, setPlantName] = useState("");

  const [machineName, setMachineName] = useState("");
  const [machineID, setMachineID] = useState("");
  const [changeOT, setChangeOT] = useState("");
  const [modeFrequency, setModeFrequency] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState("success"); 
  const [frdcData, setFRDCData] = useState([]);

  const [error,setError]=useState(null)

  const handlePlaneNameChange = (event) => {
    setPlantName(event.target.value);
  };
  const handleLineNameChange = (event) => {
    setLineName(event.target.value);
  };
  const handleMachineNameChange = (event) => {
    setMachineName(event.target.value);
  };
  const handleMachineIDChange = (event) => {
    setMachineID(event.target.value);
  };
  const handleChangeOTChange = (event) => {
    setChangeOT(event.target.value);
  };
  const handleModeFrequencyChange = (event) => {
    setModeFrequency(event.target.value);
  };
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity); 
    setOpenSnackbar(true);
  };
  const handleEditClick = () => {
    alert("edit");
  };
  const handleDeleteClick = () => {
    alert("delete");
  };
  const getFRDC = async () => {
    try {
      // const result = await apiGetFRDC();
      // console.log(result?.data.data);
      // setFRDCData(result?.data.data);
    } catch (error) {
      console.error("Error fetching FRDC data:", error);
      setError(error.message);
      handleSnackbarOpen(error.message, "error");
      
    }
  };
  useEffect(() => {
    getFRDC(); 
  }, []);

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    try {
      // const result =await apiFrdc(plantName,lineName,machineID,machineName,changeOT,modeFrequency)
      // console.log("response",result.data)
      handleSnackbarOpen("FRDC added successfully!", "success"); 
      getFRDC();
      setLineName("")
      setAddOpen(false)
      setMachineName("")
      setPlantName("")
      setChangeOT("")
      setModeFrequency("")
    } catch (error) {
      console.error("Error adding FRDC:", error);
    handleSnackbarOpen("Error adding FRDC. Please try again.", "error"); // Pass severity as "error"

    }
  }

  return (
 

    <div style={{ padding:  "0px 20px", }}>
        <div style={{display:'flex',justifyContent:'flex-start',paddingTop: "5px", paddingBottom: "5px"}}>
          <h2>FRDC Master</h2>
        </div>
        <Box>
        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          <Button onClick={() => setAddOpen(true)}
              style={{
                fontWeight: "500",
                borderRadius: "4px",
                color: "gray",
                border: "2px solid gray",
                padding: "5px",
                marginBottom: "5px",
              }}
          >
            {" "}
            Add New &nbsp;{" "}
            <FontAwesomeIcon style={{ fontSize: "18px",color:"gray" }} icon={faPlus} />
          </Button>
        </div>
        <Table size="small" style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }} >
                <TableHead>
                    <TableRow>
                        <StyledTableCell  className="table-cell">Plant Name</StyledTableCell >
                        {/* <StyledTableCell  className="table-cell">Machine Code</StyledTableCell > */}
                        <StyledTableCell  className="table-cell">Line Name</StyledTableCell >
                        {/* <StyledTableCell  className="table-cell">Plant Name</StyledTableCell > */}
                        <StyledTableCell  className="table-cell">Machine Name</StyledTableCell >
                        {/* <StyledTableCell  className="table-cell">Machine ID</StyledTableCell > */}

                        <StyledTableCell  className="table-cell">Changes Overtime </StyledTableCell >
                        <StyledTableCell  className="table-cell">Mode Frequency </StyledTableCell >
                        <StyledTableCell  className="table-cell">Create Date</StyledTableCell >

                        <StyledTableCell  className="table-cell">Edit</StyledTableCell >
                        <StyledTableCell  className="table-cell">Delete</StyledTableCell >
                    </TableRow>
                </TableHead>
                <TableBody>
                {frdcData.length === 0 ? (
    // Render skeleton loaders when data is still loading
    Array.from(Array(5).keys()).map((index) => (
      <StyledTableRow key={index}>
        <StyledTableCell>
          <Skeleton animation="wave" />
        </StyledTableCell>
        <StyledTableCell>
          <Skeleton animation="wave" />
        </StyledTableCell>
        <StyledTableCell>
          <Skeleton animation="wave" />
        </StyledTableCell>
        <StyledTableCell>
          <Skeleton animation="wave" />
        </StyledTableCell>
        <StyledTableCell>
          <Skeleton animation="wave" />
        </StyledTableCell>
        <StyledTableCell>
          <Skeleton animation="wave" />
        </StyledTableCell>
        <StyledTableCell>
          <Skeleton animation="wave" />
        </StyledTableCell>
        <StyledTableCell>
          <Skeleton animation="wave" />
        </StyledTableCell>
      </StyledTableRow>
    ))
  ) :(
                frdcData.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell  className="table-cell">{row.plantNo}</StyledTableCell >
                <StyledTableCell  className="table-cell">{row.lineNo}</StyledTableCell >
                <StyledTableCell  className="table-cell">{row.machineNo}</StyledTableCell >
                <StyledTableCell  className="table-cell">{row.changeOverTime}</StyledTableCell >
                {/* <StyledTableCell  className="table-cell">{row.plantNo}</StyledTableCell > */}
                <StyledTableCell  className="table-cell">{row.changeOverTime}</StyledTableCell >
                <StyledTableCell  className="table-cell">{row.modeFrequency}</StyledTableCell >
                {/* <StyledTableCell  className="table-cell">{row.lineNo}</StyledTableCell > */}
                <StyledTableCell  className="table-cell">
                  <IconButton onClick={() => handleEditClick(row)}>
                    <EditIcon />
                  </IconButton>
                </StyledTableCell >
                <StyledTableCell  className="table-cell">
                  <IconButton onClick={() => handleDeleteClick(row.id)}>
                    <DeleteIcon />
                  </IconButton>
                </StyledTableCell >
              </StyledTableRow>
            )))}
                </TableBody>
            </Table>
            <Modal open={addOpen} onClose={() => setAddOpen(false)}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              minWidth: "500px",
            }}
          >
            <h2>Add FRDC </h2>
            <hr />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "52ch" }}>
                <InputLabel>Plant Name</InputLabel>
                <Select value={plantName} onChange={handlePlaneNameChange}>
                  <MenuItem value="1">SPRL</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
            <FormControl sx={{ width: "52ch" ,marginBottom:'10px'}}>
                <InputLabel>Line Name</InputLabel>
                <Select value={lineName} onChange={handleLineNameChange}>
                  <MenuItem value="1">Pathredi</MenuItem>
                  <MenuItem value="2">Ghaziabad</MenuItem>
                  <MenuItem value="3">Head Office</MenuItem>

                </Select>
              </FormControl>
            </div>
            <div>
            <FormControl sx={{ width: "52ch" ,marginBottom:'10px'}}>
                <InputLabel>Machine Name</InputLabel>
                <Select value={machineName} onChange={handleMachineNameChange}>
                  <MenuItem value="1">PTH PM L3 FBM</MenuItem>
                  <MenuItem value="2">PTH EVM EX1</MenuItem>
                  <MenuItem value="3">PTH EVM EX2</MenuItem>
                  <MenuItem value="4">PTH PM L3 FBM1</MenuItem>
                  <MenuItem value="5">PTH EVM EX3</MenuItem>

                </Select>
              </FormControl>
            </div>
            {/* <div>
              <TextField
                value={machineID}
                onChange={handleMachineIDChange}
                label="Machine ID  "
                style={{ width: "28rem",marginBottom: "10px", }}
              />
            </div> */}
            <div>
              <TextField
                value={changeOT}
                onChange={handleChangeOTChange}
                label="Change Overtime  "
                style={{ width: "28rem",marginBottom: "10px", }}
              />
            </div>
            <div>
              <TextField
                value={modeFrequency}
                onChange={handleModeFrequencyChange}
                label="Mode Frequency  "
                style={{ width: "28rem",marginBottom: "10px", }}
              />
            </div>
            <Button
              onClick={handleAddSubmit}
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              Add
            </Button>
          </div>
        </Modal>
        </Box>
        <Snackbar
  open={openSnackbar}
  autoHideDuration={6000}
  onClose={() => setOpenSnackbar(false)}
>
  <MuiAlert
    onClose={() => setOpenSnackbar(false)}
    severity={severity} 
    sx={{ width: '100%' }}
  >
    {snackbarMessage}
  </MuiAlert>
</Snackbar>
    </div>
  )
}
